import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {Button, TextField, Typography, Alert, Box, Stack, Autocomplete, InputBase, useTheme} from "@mui/material";

import {apiGetAllUsers} from "react_ct/requests";

import {useAuth} from "contexts/AuthContext";
import {useQuery} from "@tanstack/react-query";
import {getErrorMessage} from "helpers/utils";
import {UserRole} from "react_ct/types";
import {Footer} from "components/footer/Footer";
import {userKeys} from "queries/queries";

const Home: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const auth = useAuth();

    const {loginError, setLoginError} = auth;

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showSpoof, setShowSpoof] = useState(false);
    const [spoofUser, setSpoofUser] = useState<number | string>("");

    const {data: allUsers, error: usersError} = useQuery({
        queryKey: userKeys.users,
        queryFn: async () => await apiGetAllUsers(),

        enabled: showSpoof,
    });

    const userOptions = useMemo(() => {
        if (!allUsers) {
            return [];
        }

        return allUsers
            .filter(user => user.role !== UserRole.admin && user.role !== UserRole.annotator)
            .sort((a, b) => {
                if (a.email < b.email) {
                    return -1;
                } else if (a.email > b.email) {
                    return 1;
                } else {
                    return 0;
                }
            });
    }, [allUsers]);

    // https://github.com/remix-run/react-router/issues/8503
    const from = useMemo(() => {
        const state = location.state as {from?: Location};

        if (state?.from != null) {
            return state.from.pathname;
        }

        return null;
    }, [location]);

    useEffect(() => {
        if (usersError) {
            console.error("error retrieving users", usersError);
        }
    }, [usersError]);

    useEffect(() => {
        if (auth.user && auth.user.role !== "ADMIN" && navigate) {
            if (from) {
                // navigate(from, {replace: true});
            } else {
                navigate("/portal", {replace: true});
            }
        }
    }, [auth.user, navigate]);

    const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        setUsername(event.target.value);
    };

    const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        setPassword(event.target.value);
    };

    const handleSpoofing: React.FormEventHandler = (event): void => {
        event.preventDefault();
        setLoginError?.(undefined);
        if (!auth.spoofedSignin) {
            console.error("AuthContext does not have a spoofedSignin function");
            return;
        } else if (!spoofUser || !Number(spoofUser)) {
            console.error("No user was selected");
            return;
        }

        try {
            auth.spoofedSignin(spoofUser as number, () => {
                if (auth.user) {
                    setUsername("");
                    setPassword("");

                    navigate("/portal");

                    // if (from) {
                    //     navigate(from, {replace: true});
                    // } else {
                    //     navigate("/portal");
                    // }
                }
            });
        } catch (error: unknown) {
            console.error("error spoofing in", getErrorMessage(error));
        }
    };

    const handleSignIn: React.FormEventHandler = (event): void => {
        event.preventDefault();
        setLoginError?.(undefined);
        if (!auth.signin) {
            console.error("AuthContext does not have a signin function");
            return;
        }

        try {
            auth.signin(username, password, (role: string) => {
                if (role.toLowerCase() === "admin") {
                    setShowSpoof(true);
                } else {
                    // Clear input fields after sign-in
                    setUsername("");
                    setPassword("");
                    // Send them back to the page they tried to visit when they were
                    // redirected to the login page. Use { replace: true } so we don't create
                    // another entry in the history stack for the login page.  This means that
                    // when they get to the protected page and click the back button, they
                    // won't end up back on the login page, which is also really nice for the
                    // user experience.
                    navigate("/portal");
                }
            });
        } catch (error: unknown) {
            console.error("error signing in", getErrorMessage(error));
        }
    };

    const handlePortal: React.FormEventHandler = (event): void => {
        event.preventDefault();
        navigate("/portal");
        // if (from) {
        //     navigate(from);
        // } else {
        //     navigate("/portal");
        // }
    };

    const inputStyle = {
        width: "100%",
        padding: 8,
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.shape.borderRadius,
    };

    return (
        <Stack height="100%" gap={8} alignItems="center" justifyContent="center">
            <form onSubmit={auth.user ? handlePortal : handleSignIn}>
                <Box sx={{padding: "1rem"}}>
                    {Boolean(loginError) && (
                        <Alert severity="error" sx={{mb: 2}}>
                            {loginError}
                        </Alert>
                    )}
                    <Stack gap={2} alignItems="center" direction="column">
                        <Typography variant="h3" component="h2" align="center">
                            Sign In
                        </Typography>
                        <InputBase
                            id="username"
                            placeholder="Username"
                            inputProps={{
                                "aria-label": "username",
                                style: inputStyle,
                            }}
                            onChange={handleUsernameChange}
                            value={username}
                        />
                        <InputBase
                            id="password"
                            type="password"
                            placeholder="Password"
                            inputProps={{
                                "aria-label": "password",
                                style: inputStyle,
                            }}
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                        {Boolean(showSpoof && userOptions) && (
                            <Autocomplete
                                id="spoof-user-autocomplete"
                                options={userOptions}
                                getOptionLabel={option => option.email}
                                style={{width: 300}}
                                renderInput={params => <TextField {...params} label="Spoof User" variant="outlined" />}
                                onChange={(event, newValue) => {
                                    if (newValue?.id) {
                                        setSpoofUser(newValue.id);
                                    }
                                }}
                            />
                        )}
                        {Boolean(auth.user) && (
                            <Button
                                type="submit"
                                onSubmit={auth.user?.role === "ADMIN" && spoofUser ? handleSpoofing : handlePortal}
                                onClick={auth.user?.role === "ADMIN" && spoofUser ? handleSpoofing : handlePortal}
                                color="info"
                                variant="contained"
                                disableElevation>
                                {auth.user?.role === "ADMIN" ? "Spoof Sign In" : "Proceed to Portal"}
                            </Button>
                        )}
                        {Boolean(!auth.user) && (
                            <>
                                <Button
                                    type="submit"
                                    onSubmit={handleSignIn}
                                    onClick={handleSignIn}
                                    color="info"
                                    variant="contained"
                                    disableElevation>
                                    Sign In
                                </Button>
                            </>
                        )}
                    </Stack>
                </Box>
            </form>
            <Footer />
        </Stack>
    );
};

export {Home};
