import React from "react";
import {Box, Button, CircularProgress, IconButton, Paper, Stack, Tooltip, Typography} from "@mui/material";
import {ArcGISIdentityManager} from "@esri/arcgis-rest-request";
import ArcGISMap from "./components/ArcGISMap";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {createWebmap, deleteWebmap, getWebmaps} from "react_ct/requests";
import {useProject} from "contexts/ProjectContext";
import type {Webmap} from "react_ct/types";
import {Check, ChevronRight, Delete, Map, PriorityHigh, Refresh, ReportProblem} from "@mui/icons-material";

export default function ArcGISPage(): React.ReactElement {
    const {currentProject, projectUser} = useProject();
    const qc = useQueryClient();

    const [userAuthed, setUserAuthed] = React.useState(false);
    const [authToken, setAuthToken] = React.useState("");
    const [webmapId, setWebmapId] = React.useState<string>();

    const [retrievedWebmaps, setRetrievedWebmaps] = React.useState<Webmap[]>();

    const {data: projectWebmaps} = useQuery({
        queryKey: ["projectWebmaps", currentProject?.id],
        queryFn: async () => {
            if (currentProject) {
                return await getWebmaps(currentProject?.id);
            } else {
                throw new Error("Could not retrieve webmaps for this project");
            }
        },
        enabled: !!currentProject?.id,
        refetchInterval: retrievedWebmaps?.find((map: Webmap) => map.progress === "IN_PROGRESS") ? 15000 : false,
    });

    React.useEffect(() => {
        setRetrievedWebmaps(projectWebmaps);
    }, [projectWebmaps]);

    const {mutate: createWebmapMutation} = useMutation({
        mutationFn: async () => {
            if (currentProject?.id && projectUser?.userId && authToken) {
                await createWebmap(currentProject.id, projectUser.userId, authToken);
            } else {
                throw new Error("Missing project, project user, or auth token");
            }
        },
        onError: (error: Error) => {
            console.error("Error creating webmap:", error);
        },
        onSuccess: () => {
            void qc.invalidateQueries({
                queryKey: ["projectWebmaps", currentProject?.id],
            });
        },
    });

    const {mutate: deleteWebmapMutation} = useMutation({
        mutationFn: async (webmapId: number) => {
            if (currentProject?.id && projectUser?.userId && authToken) {
                await deleteWebmap(webmapId, currentProject?.id);
            } else {
                throw new Error("Missing project, project user, or auth token");
            }
            setWebmapId(undefined);
        },
        onError: (error: Error) => {
            console.error("Error deleting webmap: ", error);
        },
        onSuccess: () => {
            void qc.invalidateQueries({
                queryKey: ["projectWebmaps", currentProject?.id],
            });
        },
    });

    const handleAuthButtonClick = async (): Promise<void> => {
        try {
            const response = await ArcGISIdentityManager.beginOAuth2({
                clientId: "P8vpoXNEHn3PzO7M",
                redirectUri: `${window.location.protocol}//${window.location.hostname}${
                    window.location.port ? ":" + window.location.port : ""
                }/oauth`,
                pkce: false,
            });

            if (response) {
                setAuthToken(response.token);
                setUserAuthed(true);
            }
        } catch (error) {
            console.error("OAuth error:", error);
        }
    };

    return (
        <Box id="page-container" width="100%" height="100vh">
            <Stack direction="row">
                <Paper sx={{flexBasis: "calc(220px + 8%)", flexShrink: 1, height: "100vh", borderRadius: 0}}>
                    <Stack alignItems="center">
                        {projectUser && (
                            <Paper sx={{width: "100%", borderRadius: 0, px: 2, py: 1}}>
                                <Typography textAlign="center" variant="h6">
                                    Welcome, {projectUser.userEmail}
                                </Typography>
                            </Paper>
                        )}
                        {userAuthed && projectWebmaps?.length ? (
                            projectWebmaps.map((webmap: Webmap, index) => (
                                <Paper
                                    key={index}
                                    elevation={0}
                                    sx={{
                                        mt: 1,
                                        px: 2,
                                        py: 1,
                                        width: "100%",
                                        borderBottom: theme => `1px solid ${theme.palette.grey[100]}`,
                                    }}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                        <Stack direction="row" alignItems="center" gap={2}>
                                            <Tooltip
                                                title={
                                                    webmap.progress === "FAILED"
                                                        ? "Failed to create webmap"
                                                        : webmap.progress === "CREATED"
                                                          ? "Webmap has been created"
                                                          : "Processing webmap"
                                                }>
                                                {webmap.progress === "FAILED" ? (
                                                    <ReportProblem color="error" />
                                                ) : webmap.progress === "CREATED" ? (
                                                    <Check color="success" />
                                                ) : (
                                                    <PriorityHigh color="info" />
                                                )}
                                            </Tooltip>
                                            <Stack sx={{flexGrow: 1}}>
                                                <Typography
                                                    color={theme =>
                                                        webmap.progress === "FAILED"
                                                            ? theme.palette.error.main
                                                            : theme.palette.text.primary
                                                    }>
                                                    ID: {webmap.id}
                                                </Typography>
                                                <Typography
                                                    color={theme =>
                                                        webmap.progress === "FAILED"
                                                            ? theme.palette.error.main
                                                            : theme.palette.text.primary
                                                    }>
                                                    ArcGIS ID: {webmap.arcgisWebmapId ?? "N/A"}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Box>
                                            {webmap.progress === "CREATED" && webmap.arcgisWebmapId ? (
                                                <Stack direction="row">
                                                    <Tooltip title="Delete webmap">
                                                        <IconButton onClick={() => deleteWebmapMutation(webmap.id)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <IconButton onClick={() => setWebmapId(webmap.arcgisWebmapId)}>
                                                        <ChevronRight color="primary" />
                                                    </IconButton>
                                                </Stack>
                                            ) : webmap.progress === "CREATED" && !webmap.arcgisWebmapId ? (
                                                <Tooltip title="Could not find ArcGIS ID">
                                                    <PriorityHigh color="info" />
                                                </Tooltip>
                                            ) : webmap.progress === "FAILED" ? (
                                                <Stack direction="row" alignItems="center">
                                                    <Tooltip
                                                        title="Recreate webmap"
                                                        onClick={() => createWebmapMutation()}>
                                                        <IconButton>
                                                            <Refresh />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete webmap">
                                                        <IconButton onClick={() => deleteWebmapMutation(webmap.id)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            ) : (
                                                <Stack direction="row" alignItems="center">
                                                    <CircularProgress />
                                                    <IconButton onClick={() => deleteWebmapMutation(webmap.id)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Stack>
                                            )}
                                        </Box>
                                    </Stack>
                                </Paper>
                            ))
                        ) : userAuthed && !projectWebmaps?.length ? (
                            <Stack alignItems="center" gap={2} sx={{mt: 1}}>
                                <Typography textAlign="center">No maps exist for this user</Typography>
                                <Button variant="contained" onClick={() => createWebmapMutation()}>
                                    Create ArcGIS Map
                                </Button>
                            </Stack>
                        ) : (
                            <Stack alignItems="center" gap={2} sx={{mt: 1, p: 2}}>
                                <Typography>Login with ArcGIS to view maps for this project</Typography>
                                <Button onClick={handleAuthButtonClick} variant="contained" color="info" sx={{mt: 1}}>
                                    Authenticate with ArcGIS
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                </Paper>
                <Box flexGrow={1}>
                    {userAuthed && webmapId ? (
                        <ArcGISMap mapId={webmapId} />
                    ) : (
                        <Stack
                            width="100%"
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                            sx={{opacity: 0.5}}>
                            <Map />
                            <Typography fontSize={30}>Your map here</Typography>
                        </Stack>
                    )}
                </Box>
            </Stack>
        </Box>
    );
}
