import React from "react";
import GeoJSON from "geojson";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import DWMap from "components/Map/DWMap";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {Alert, type AlertProps, Button, IconButton, Snackbar, Tooltip} from "@mui/material";
import {ArrowBack, ArrowForward, Check, ChevronLeft, Error} from "@mui/icons-material";
import AddressList from "./components/AddressList";
import {type WorkOrderGeojsonGeometry, type WorkOrderGeojsonProps} from "../select/components/constants";
import {CSVLink} from "react-csv";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {apiRequest, apiReverseGeocode} from "react_ct/requests";
import {colors} from "react_ct/theme";
import LoadingError from "components/LoadingError";
import {useProject} from "contexts/ProjectContext";
import type {ImplementProgram, ImplementProgramFeature, IndividualFeatureProperties, Obstruction} from "react_ct/types";
import LoadingScreen from "components/LoadingScreen";
import type {FeatureOutput} from "../../types";
import {geojsonKeys, getGeojsonByType, projectKeys} from "queries/queries";
import {FeatureImageContainer, getRawFeature} from "../../components";

export default function ReviewNewProgram(): React.ReactElement {
    const {currentProject} = useProject();
    const [searchParams] = useSearchParams();
    const qc = useQueryClient();
    const programId = searchParams.get("id");
    const {state} = useLocation();
    const {data: programData, error: programError} = useQuery({
        queryKey: projectKeys.projectProgram(currentProject?.id, parseInt(programId as string)),
        queryFn: async () => {
            const response = await apiRequest({
                path: `program/${programId ?? ""}`,
                params: {projectId: currentProject?.id},
            });
            const program: ImplementProgram = response.data;
            for (const feature of program.features) {
                const address = await apiReverseGeocode({x: feature.gpsCoordinate[0], y: feature.gpsCoordinate[1]});
                feature.address = address ?? undefined;
            }
            return program;
        },
        enabled: !!programId && !!currentProject?.id,
        refetchOnWindowFocus: false,
    });
    const {projectManualTags} = useProject();

    const navigate = useNavigate();

    const {data: rawFeaturesData} = useQuery({
        queryKey: [
            ...(programData?.programType === "obstruction"
                ? geojsonKeys.obstructionsGeojson(currentProject?.id)
                : geojsonKeys.individualFeaturesGeojson(currentProject?.id)),
            programData?.programType,
            programData?.id,
        ],
        enabled: !!programData,
        queryFn: async ({queryKey}) => await getGeojsonByType(...(queryKey as [number | undefined, string, string])),
    });

    const rawFeaturesGeojson: GeoJSON.FeatureCollection<GeoJSON.Point, Obstruction | IndividualFeatureProperties> = {
        ...rawFeaturesData?.geojsonData,
        features:
            rawFeaturesData?.geojsonData.features.map((feature, index) => ({
                ...feature,
                properties: {
                    ...feature.properties,
                    id:
                        programData?.programType === "obstruction"
                            ? `${feature.properties?.scan_id}_${index}`
                            : `${feature.properties?.scan_id}_${feature.properties?.feature_name}`,
                },
            })) ?? [],
    } as GeoJSON.FeatureCollection<GeoJSON.Point, Obstruction | IndividualFeatureProperties>;

    const featureSeverityToColor = (severity: string): string =>
        severity === "extreme"
            ? colors.black
            : severity === "severe"
              ? colors.darkRed
              : severity === "moderate"
                ? colors.orange
                : severity === "minor"
                  ? colors.yellow
                  : colors.green;

    const getFeatureMapColor = (feature: ImplementProgramFeature): string => {
        const manualTagData = projectManualTags?.find(
            tag => tag.name.toLowerCase() === feature.featureType.toLowerCase(),
        );

        if (feature.severity) {
            return featureSeverityToColor(feature.severity);
        } else if (manualTagData) {
            return manualTagData.color ?? "#000000";
        } else {
            return colors.orange;
        }
    };

    const data: GeoJSON.FeatureCollection<WorkOrderGeojsonGeometry, WorkOrderGeojsonProps> = React.useMemo(
        () => ({
            type: "FeatureCollection",
            features:
                programData?.features.map((feature: ImplementProgramFeature) => ({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: feature.gpsCoordinate,
                    },
                    properties: {
                        reviewed: feature.reviewed,
                        accepted: feature.accepted,
                        id: feature.id,
                        scan_id: feature.scanId,
                        feature_name: feature.featureType,
                        address: feature.address,
                        type: feature.featureType,
                        program_id: programData.id,
                        program_name: programData.name,
                        max_severity: feature.severity ?? undefined,
                        color: getFeatureMapColor(feature),
                        fpv_media: feature.fpv_media,
                    },
                })) ?? [],
        }),
        [programData],
    );

    const features = programData?.features ?? [];
    const rawFeatures: Array<GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>> =
        state?.selectedFeatures ??
        rawFeaturesGeojson.features.filter(
            feature =>
                !!features.find(
                    f =>
                        f.gpsCoordinate[0] === feature.geometry.coordinates[0] &&
                        f.gpsCoordinate[1] === feature.geometry.coordinates[1],
                ),
        );

    const [selectedFeatureId, setSelectedFeatureId] = React.useState<number | undefined>(
        programData?.features[0]?.id ?? undefined,
    );
    const [, setViewingImageIndex] = React.useState<number>(0);
    const [rejectedFeatureIds, setRejectedFeatureIds] = React.useState<number[]>([]);
    const [acceptedFeatureIds, setAcceptedFeatureIds] = React.useState<number[]>([]);
    const [alert, setAlert] = React.useState<{
        severity: AlertProps["severity"];
        message: string;
    }>();

    const csvDataJson = React.useMemo(
        () =>
            features
                .filter(feature => acceptedFeatureIds.includes(feature.id))
                .map((feature, index) => ({
                    scan_id: feature.scanId,
                    feature_name: feature.featureType + index.toString(),
                    gps_coordinates: feature.gpsCoordinate.join(" "),
                    lng: feature.gpsCoordinate[0],
                    lat: feature.gpsCoordinate[1],
                    address: feature.address,
                    issue: feature.featureType,
                    severity: feature.severity ?? "",
                    program_id: 1,
                    program_name: programData?.name,
                })),
        [acceptedFeatureIds],
    );

    const goToNextFeature = (): void => {
        if (selectedFeatureId) {
            const featureIndex = features.findIndex(feature => feature.id === selectedFeatureId);
            const nextIndex = featureIndex + 1 >= features.length ? 0 : featureIndex + 1;
            setSelectedFeatureId(features[nextIndex].id);
        }
    };

    const goToPreviousFeature = (): void => {
        if (selectedFeatureId) {
            const featureIndex = features.findIndex(feature => feature.id === selectedFeatureId);
            const prevIndex = featureIndex - 1 < 0 ? features.length - 1 : featureIndex - 1;
            setSelectedFeatureId(features[prevIndex].id);
        }
    };

    const rejectProgramFeature = (): void => {
        // if a feature is rejected, highlight it as red
        if (selectedFeatureId) {
            if (acceptedFeatureIds.includes(selectedFeatureId)) {
                setAcceptedFeatureIds(prev => prev.filter(id => id !== selectedFeatureId));
            }
            setRejectedFeatureIds(prev => [...prev, selectedFeatureId]);
        }
    };

    const acceptProgramFeature = (): void => {
        // if a feature is accepted, highlight it as green
        if (selectedFeatureId) {
            if (rejectedFeatureIds.includes(selectedFeatureId)) {
                setRejectedFeatureIds(prev => prev.filter(id => id !== selectedFeatureId));
            }
            setAcceptedFeatureIds(prev => [...prev, selectedFeatureId]);
        }
    };

    React.useEffect(() => {
        if (acceptedFeatureIds.length ?? rejectedFeatureIds.length) {
            goToNextFeature();
        }
    }, [acceptedFeatureIds, rejectedFeatureIds]);

    const saveProgramMutation = useMutation({
        mutationFn: async () => {
            if (!programData ?? !currentProject) {
                throw new TypeError("Could not find program data or project");
            }
            const validatedFeatures: FeatureOutput[] = features.map(feature => {
                const validatedFeature: FeatureOutput = {
                    ...feature,
                    severity: feature.severity ?? undefined,
                    reviewed: !!(
                        acceptedFeatureIds.find(acc => acc === feature.id) ??
                        rejectedFeatureIds.find(rej => rej === feature.id)
                    ),
                    accepted: !!acceptedFeatureIds.find(acc => acc === feature.id),
                };
                delete validatedFeature.programId;
                return validatedFeature;
            });
            const response = await apiRequest({
                path: `program/${programData?.id ?? ""}`,
                method: "PUT",
                params: {isDraft: true, projectId: currentProject?.id},
                data: {name: programData?.name, features: validatedFeatures},
            });
            return response;
        },
        onSuccess: () => {
            void qc.invalidateQueries({queryKey: ["getProgramById", programId]});
            setAlert({severity: "success", message: "Program successfully saved as draft"});
        },
        onError: error => {
            setAlert({severity: "error", message: `Error saving program as draft: ${error.message}`});
        },
    });

    const publishProgramMutation = useMutation({
        mutationFn: async () => {
            if (!programData ?? !currentProject) {
                throw new TypeError("Could not find program data or project");
            }
            const validatedFeatures: FeatureOutput[] = features.map(feature => {
                const validatedFeature: FeatureOutput = {
                    ...feature,
                    severity: feature.severity ?? undefined,
                    reviewed: !!(
                        acceptedFeatureIds.find(acc => acc === feature.id) ??
                        rejectedFeatureIds.find(rej => rej === feature.id)
                    ),
                    accepted: !!acceptedFeatureIds.find(acc => acc === feature.id),
                };
                delete validatedFeature.programId;
                return validatedFeature;
            });
            const response = await apiRequest({
                path: `program/${programData?.id ?? ""}`,
                method: "PUT",
                params: {isDraft: false, projectId: currentProject?.id},
                data: {name: programData?.name, features: validatedFeatures},
            });
            return response.data;
        },
        onError: error => {
            setAlert({severity: "error", message: `Could not publish program: ${error.message}`});
        },
        onSuccess: () => {
            void qc.invalidateQueries({queryKey: ["getPrograms", currentProject?.id]});
            navigate("/portal/implement/programs");
        },
    });

    React.useEffect(() => {
        if (programData?.features.length) {
            if (!selectedFeatureId) {
                setSelectedFeatureId(programData.features[0].id);
            }
            setAcceptedFeatureIds(
                programData.features.filter(feature => feature.reviewed && feature.accepted).map(feature => feature.id),
            );
            setRejectedFeatureIds(
                programData.features
                    .filter(feature => feature.reviewed && !feature.accepted)
                    .map(feature => feature.id),
            );
        }
    }, [data]);

    React.useEffect(() => {
        const interval = setInterval(() => {
            saveProgramMutation.mutate();
        }, 30000);

        return () => clearInterval(interval);
    }, []);

    const imageContainerRef = React.useRef<HTMLDivElement | null>(null);

    // TODO: add event listener to show an alert before the user navigates away from the page

    if (programError) {
        return <LoadingError errorMessage={programError?.message} />;
    } else if (programData) {
        return (
            <>
                <Snackbar
                    open={!!alert}
                    onClose={() => setAlert(undefined)}
                    autoHideDuration={3000}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
                    <Alert
                        onClose={() => setAlert(undefined)}
                        severity={alert?.severity}
                        icon={alert?.severity === "success" ? <Check /> : <Error />}>
                        {alert?.message}
                    </Alert>
                </Snackbar>
                <Box
                    id="page-container"
                    sx={{
                        height: "100vh",
                        position: "relative",
                        boxSizing: "border-box",
                        overflow: "auto",
                    }}>
                    <Stack p={4} boxSizing="border-box" height="100%">
                        <Stack direction="row" height="100%" gap={4} sx={{boxSizing: "border-box"}}>
                            <Stack gap={4} flexGrow={1} height="100%">
                                <Stack direction="row" justifyContent="space-between">
                                    <Stack direction="row" alignItems="center">
                                        <Tooltip title="Back to the Create Program page">
                                            <IconButton
                                                size="large"
                                                color="primary"
                                                sx={{
                                                    height: "60px",
                                                }}
                                                onClick={() => {
                                                    navigate("/portal/implement/create/obstruction", {
                                                        state: {
                                                            features: data.features,
                                                            programName: programData.name,
                                                            programId: programData.id,
                                                        },
                                                    });
                                                }}>
                                                <ChevronLeft fontSize="large" />
                                            </IconButton>
                                        </Tooltip>
                                        <Stack direction="row" gap="0.6rem" alignItems="baseline">
                                            <Typography variant="h4" component="h2">
                                                Review
                                            </Typography>
                                            <Tooltip title={programData.name}>
                                                <Typography
                                                    variant="h4"
                                                    component="h2"
                                                    sx={{
                                                        boxSizing: "border-box",
                                                        px: 1,
                                                        py: 0.5,
                                                        backgroundColor: theme =>
                                                            theme.palette.deepWalkBlue.main + "44",
                                                        borderRadius: theme => theme.shape.borderRadius,
                                                        color: theme => theme.palette.midnightBlue.dark,
                                                    }}>
                                                    {programData.name}
                                                </Typography>
                                            </Tooltip>
                                        </Stack>
                                    </Stack>
                                    <Stack direction="row" gap={2} justifyContent="center" alignItems="center">
                                        <Tooltip title="View previous feature">
                                            <IconButton sx={{height: "40px"}} onClick={goToPreviousFeature}>
                                                <ArrowBack />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="View next feature">
                                            <IconButton sx={{height: "40px"}} onClick={goToNextFeature}>
                                                <ArrowForward />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </Stack>
                                <Box
                                    ref={imageContainerRef}
                                    borderRadius={theme => theme.shape.borderRadius}
                                    boxShadow={theme => theme.shadows[2]}
                                    height="100%"
                                    minHeight="33%"
                                    overflow="clip"
                                    flexShrink={1}>
                                    <FeatureImageContainer
                                        feature={getRawFeature(
                                            rawFeatures,
                                            features.find(feature => selectedFeatureId === feature.id),
                                        )}
                                        {...{imageContainerRef}}
                                    />
                                </Box>
                            </Stack>
                            <Stack width="33%" flexShrink={1} gap={2}>
                                <Box
                                    minHeight="30%"
                                    flexShrink={1}
                                    position="relative"
                                    borderRadius={theme => theme.shape.borderRadius}
                                    boxShadow={theme => theme.shadows[2]}>
                                    <DWMap
                                        dataId="scan_id"
                                        borderRadius
                                        selectedFeature={
                                            data.features.filter(
                                                feature => feature.properties.id === selectedFeatureId,
                                            )[0]
                                        }
                                        {...{data}}
                                        onMarkerClick={(feature: GeoJSON.Feature) => {
                                            setSelectedFeatureId(feature.properties?.id);
                                        }}
                                    />
                                </Box>
                                <AddressList
                                    {...{
                                        features,
                                        selectedFeatureId,
                                        setSelectedFeatureId,
                                        acceptedFeatureIds,
                                        rejectedFeatureIds,
                                        setViewingImageIndex,
                                        acceptProgramFeature,
                                        rejectProgramFeature,
                                    }}
                                />
                                <Stack
                                    direction="row"
                                    justifyContent="space-around"
                                    flexBasis="calc(0.875rem + 6px)"
                                    flexShrink={1}>
                                    {acceptedFeatureIds.length > 0 && (
                                        <CSVLink data={csvDataJson} filename="project.csv">
                                            <Button variant="contained" color="secondary">
                                                Export to CSV
                                            </Button>
                                        </CSVLink>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={acceptedFeatureIds.length === 0}
                                        onClick={() => publishProgramMutation.mutate()}>
                                        Save Program
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </>
        );
    } else {
        return (
            <Stack width="100%" height="100vh" alignItems="center" justifyContent="center">
                <LoadingScreen />
            </Stack>
        );
    }
}
