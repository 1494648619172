/* eslint-disable @typescript-eslint/no-explicit-any */
import {Add, Cancel, Delete, Edit, Restore, Save, SentimentVeryDissatisfied} from "@mui/icons-material";
import {
    Alert,
    type AlertColor,
    Box,
    Button,
    ClickAwayListener,
    IconButton,
    Paper,
    Snackbar,
    Stack,
    TextField,
    Typography,
    useTheme,
    Tabs,
    Tab,
    tabClasses,
    darken,
    Tooltip,
} from "@mui/material";
import {UseMutateFunction, useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import BeforeUnloadDialog from "components/BeforeUnloadDialog";
import {useProject} from "contexts/ProjectContext";
import {type ArrayActions, useArray} from "hooks/useArray";
import {projectKeys} from "queries/queries";
import React from "react";
import {HexColorPicker} from "react-colorful";
import {useBlocker} from "react-router-dom";
import {useDebounce} from "react-use";
import {apiRequest} from "react_ct/requests";
import {colors} from "react_ct/theme";
import {ManualTagSQLOutput, type ManualTag, type ManualTagSQLInput} from "react_ct/types";

function ProjectTagRow({
    tag,
    tagData,
    setTagData,
    editState,
}: {
    tag: ManualTag;
    tagData: ManualTag[];
    setTagData: ArrayActions<ManualTag>;
    editState: boolean;
}): React.ReactElement {
    const theme = useTheme();

    const index = tagData.findIndex(t => t.id === tag.id);
    const [color, setColor] = React.useState<string>(tag.color ?? "#000000");
    const [tagNameValue, setTagNameValue] = React.useState<string>(tag.name);
    const [tagDefinitionValue, setTagDefinitionValue] = React.useState<string>(tag.definition);
    const [openColorPicker, setOpenColorPicker] = React.useState<boolean>(false);

    const [debouncedColor, setDebouncedColor] = React.useState(color);
    const [debouncedNameVal, setDebouncedNameVal] = React.useState(tagNameValue);
    const [debouncedDefinitionVal, setDebouncedDefinitionVal] = React.useState(tagDefinitionValue);

    const [, cancelColor] = useDebounce(
        () => {
            setDebouncedColor(color);
        },
        300,
        [color],
    );

    const [, cancelName] = useDebounce(
        () => {
            setDebouncedNameVal(tagNameValue);
        },
        300,
        [tagNameValue],
    );

    const [, cancelDef] = useDebounce(
        () => {
            setDebouncedDefinitionVal(tagDefinitionValue);
        },
        300,
        [tagDefinitionValue],
    );

    React.useEffect(() => {
        if (
            index > -1 &&
            debouncedColor &&
            debouncedColor.length > 0 &&
            debouncedNameVal.length > 0 &&
            debouncedDefinitionVal.length > 0
        ) {
            setTagData.replace(index, {
                ...tagData[index],
                color: debouncedColor,
                name: debouncedNameVal,
                definition: debouncedDefinitionVal,
            });
        }
    }, [debouncedColor, debouncedNameVal, debouncedDefinitionVal]);

    React.useEffect(() => {
        setColor(tag.color ?? "#000000");
        setTagNameValue(tag.name);
        setTagDefinitionValue(tag.definition);
    }, [editState]);

    return (
        <Paper variant="outlined" sx={{px: 2, py: 1, borderRadius: 50}}>
            <Stack direction="row" alignItems="center" spacing={8}>
                <Stack direction="row" alignItems="center" spacing={2} flexShrink={0} flexBasis="25%">
                    <ClickAwayListener
                        onClickAway={(): void => {
                            if (openColorPicker) {
                                setOpenColorPicker(false);
                            }
                        }}>
                        <Box position="relative" flexBasis="20px" flexShrink={0}>
                            {editState && (
                                <Paper
                                    variant="outlined"
                                    sx={theme => ({
                                        position: "absolute",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "10px",
                                        height: "10px",
                                        top: -10,
                                        left: 15,
                                        borderRadius: 50,
                                        padding: 1,
                                        backgroundColor: theme.palette.grey[100],
                                    })}
                                    elevation={0}>
                                    <Edit sx={{fontSize: "12px"}} />
                                </Paper>
                            )}
                            <Box
                                width="20px"
                                height="20px"
                                borderRadius="50%"
                                bgcolor={color}
                                onClick={editState ? (): void => setOpenColorPicker(prev => !prev) : undefined}
                                border={editState ? `3px solid ${theme.palette.grey[200]}` : undefined}
                                boxSizing="content-box"
                                sx={{
                                    cursor: editState ? "pointer" : "auto",
                                }}
                            />
                            {editState && (
                                <Box
                                    position="absolute"
                                    visibility={openColorPicker ? "visible" : "hidden"}
                                    zIndex={5}
                                    left="-80px"
                                    bottom="-210px">
                                    <HexColorPicker color={color} onChange={setColor} />
                                </Box>
                            )}
                        </Box>
                    </ClickAwayListener>
                    {editState ? (
                        <TextField
                            variant="outlined"
                            aria-label="tag name"
                            placeholder={tag.name}
                            value={tagNameValue}
                            onChange={(event): void => setTagNameValue(event.target.value)}
                        />
                    ) : (
                        <Typography fontWeight="bold" fontSize="1.2rem" alignSelf="flex-start">
                            {tagNameValue}
                        </Typography>
                    )}
                </Stack>

                {editState ? (
                    <TextField
                        aria-label="tag definition"
                        placeholder={tag.definition}
                        style={{flexGrow: 1}}
                        value={tagDefinitionValue}
                        multiline
                        onChange={(event): void => setTagDefinitionValue(event.target.value)}
                    />
                ) : (
                    <Typography flexGrow={1}>{tagDefinitionValue}</Typography>
                )}
                <Box flexBasis="24px">
                    {editState && tag.editable && (
                        <Tooltip title="Archive Tag">
                            <IconButton
                                color="error"
                                sx={{padding: 0}}
                                onClick={(): void => {
                                    const index = tagData.findIndex(t => t.id === tag.id);
                                    if (index > -1) {
                                        setTagData.replace(index, {...tagData[index], archived: true});
                                    }
                                }}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            </Stack>
        </Paper>
    );
}

const normalizedRGBToHexString = (red: number, green: number, blue: number): string => {
    const redVal = Math.round(red * 255);
    const greenVal = Math.round(green * 255);
    const blueVal = Math.round(blue * 255);

    const redHex = redVal.toString(16);
    const greenHex = greenVal.toString(16);
    const blueHex = blueVal.toString(16);

    const formatHex = (hex: string): string => {
        if (hex.length === 1) {
            return `0${hex}`;
        } else {
            return hex;
        }
    };

    const hexString = `#${formatHex(redHex)}${formatHex(greenHex)}${formatHex(blueHex)}`;

    return hexString;
};

export const convertTagColorToHex = (tag: ManualTagSQLOutput): ManualTag => {
    const hexColor = normalizedRGBToHexString(tag.red_normalized, tag.green_normalized, tag.blue_normalized);
    return {
        id: tag.id,
        name: tag.name,
        definition: tag.definition,
        editable: tag.editable,
        archived: tag.archived,
        color: hexColor,
    };
};

function ArchivedTagRow({
    tag,
    setAlert,
}: {
    tag: ManualTag;
    setAlert: React.Dispatch<React.SetStateAction<{severity: AlertColor; msg: string} | undefined>>;
}): React.ReactElement {
    const qc = useQueryClient();
    const {currentProject} = useProject();
    const unarchiveTagMutation = useMutation({
        mutationFn: async () => {
            const tempTag = tag;
            delete tempTag.color;
            await apiRequest({
                path: `project/config/${currentProject?.id ?? 0}/manualTags/${tag.id ?? 0}`,
                method: "put",
                data: {
                    manualTag: {
                        ...tempTag,
                        archived: false,
                    },
                },
            });
        },
        onMutate: () => {
            setAlert({severity: "info", msg: "Moving tag out of archive..."});
        },
        onSuccess: () => {
            setAlert({severity: "success", msg: "Tag successfully moved out of the archive"});
            void qc.invalidateQueries({queryKey: [projectKeys.projectManualTags(currentProject?.id)]});
        },
        onError: error => {
            setAlert({severity: "error", msg: `Could not move tag out of archive: ${error.message}`});
        },
    });
    return (
        <Paper variant="outlined" sx={{px: 2, py: 1, borderRadius: 50}}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" spacing={8} flexGrow="67%">
                    <Typography fontWeight="bold" fontSize="1.2rem" alignSelf="flex-start">
                        {tag.name}
                    </Typography>
                    <Typography flexGrow={1}>{tag.definition}</Typography>
                </Stack>
                <Tooltip title="Unarchive Scan">
                    <IconButton
                        onClick={(): void => {
                            void unarchiveTagMutation.mutate();
                        }}>
                        <Restore />
                    </IconButton>
                </Tooltip>
            </Stack>
        </Paper>
    );
}

function ArchivedTags({
    tagData,
    setAlert,
}: {
    tagData: ManualTag[];
    setAlert: React.Dispatch<React.SetStateAction<{severity: AlertColor; msg: string} | undefined>>;
}): React.ReactElement {
    const archivedTags: ManualTag[] = tagData.filter(tag => tag.archived);
    if (!archivedTags.length) {
        return (
            <Box
                position="relative"
                mt={6}
                border={theme => `1px solid ${theme.palette.grey[300]}`}
                bgcolor={theme => theme.palette.grey.A100}
                borderRadius={theme => theme.shape.borderRadius / 2}
                height="25vh"
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={1}>
                <Typography variant="h2" textAlign="center" color={theme => theme.palette.grey[500]}>
                    No archived tags available
                </Typography>
            </Box>
        );
    } else {
        return (
            <Stack mt={6}>
                {archivedTags.map(tag => (
                    <ArchivedTagRow key={tag.id} {...{tag, setAlert}} />
                ))}
            </Stack>
        );
    }
}

function UnarchivedTags({
    editState,
    setEditState,
    saveTags,
    tagData,
    tempTagData,
    setTempTagData,
}: {
    editState: boolean;
    setEditState: React.Dispatch<React.SetStateAction<boolean>>;
    saveTags: UseMutateFunction<AxiosResponse<any, any> | undefined, Error, void, unknown>;
    tagData: ManualTag[];
    tempTagData: ManualTag[];
    setTempTagData: ArrayActions<ManualTag>;
}): React.ReactElement {
    const unarchivedTags: ManualTag[] = (editState ? tempTagData : tagData).filter(tag => !tag.archived);
    return (
        <Box width="100%" margin="auto" id="page-body" mt={6}>
            <Stack margin="auto" mt={2} spacing={2}>
                <Stack direction="row" spacing={4} alignItems="baseline">
                    <Box flexBasis="20px" />
                    <Box flexBasis="25%" flexShrink={0}>
                        <Typography
                            fontWeight="bold"
                            textAlign="left"
                            color={theme => theme.palette.midnightBlue.light}>
                            Tag Name
                        </Typography>
                    </Box>
                    <Box flexGrow={1}>
                        <Typography
                            fontWeight="bold"
                            textAlign="left"
                            color={theme => theme.palette.midnightBlue.light}>
                            Tag Definition
                        </Typography>
                    </Box>
                    <Stack direction="row" justifyContent="flex-end" spacing={2}>
                        {editState && (
                            <Button
                                color="error"
                                variant="outlined"
                                sx={{borderRadius: 50}}
                                onClick={() => {
                                    setEditState(false);
                                }}>
                                <Stack direction="row" gap={1}>
                                    <Cancel />
                                    <Typography color="error">Discard changes</Typography>
                                </Stack>
                            </Button>
                        )}
                        <Button
                            variant={editState ? "contained" : "outlined"}
                            sx={{borderRadius: 50, transition: "width 0.2s ease-in-out"}}
                            onClick={editState ? () => saveTags() : () => setEditState(true)}>
                            <Stack direction="row" gap={2}>
                                {editState ? <Save /> : <Edit />}
                                {editState && (
                                    <Typography color={editState ? "secondary" : "primary"}>Save changes</Typography>
                                )}
                            </Stack>
                        </Button>
                    </Stack>
                </Stack>
                {unarchivedTags.length ? (
                    unarchivedTags.map(tag => (
                        <Box key={tag.id} position="relative">
                            <ProjectTagRow
                                tag={tag}
                                tagData={editState ? tempTagData : tagData}
                                setTagData={setTempTagData}
                                editState={editState}
                            />
                        </Box>
                    ))
                ) : (
                    <Box
                        position="relative"
                        mt={6}
                        border={theme => `1px solid ${theme.palette.grey[300]}`}
                        bgcolor={theme => theme.palette.grey.A100}
                        borderRadius={theme => theme.shape.borderRadius / 2}
                        height="25vh"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        gap={1}>
                        <SentimentVeryDissatisfied sx={{color: theme => theme.palette.grey[400], fontSize: "2rem"}} />
                        <Typography variant="h2" textAlign="center" color={theme => theme.palette.grey[500]}>
                            No tags available
                        </Typography>
                    </Box>
                )}
            </Stack>
            {editState && (
                <Box
                    border={theme => `1px solid ${theme.palette.grey[300]}`}
                    width="100%"
                    borderRadius={50}
                    mt={6}
                    px={2}
                    py={1}
                    onClick={() => {
                        setTempTagData.push({
                            name: "New tag name",
                            definition: "Give your tag a definition",
                            color: "#ff0000",
                            editable: true,
                        });
                    }}
                    sx={{cursor: "pointer"}}>
                    <Stack direction="row" spacing={1} justifyContent="center">
                        <Add sx={theme => ({color: theme.palette.grey[600]})} />
                        <Typography color={theme => theme.palette.grey[600]}>Add</Typography>
                    </Stack>
                </Box>
            )}
        </Box>
    );
}

export function ProjectTags(): React.ReactElement {
    const qc = useQueryClient();
    const theme = useTheme();
    const projectContext = useProject();
    const {
        currentProject: project,
        projectManualTags: manualTags,
        projectManualTagsError: manualTagsError,
    } = projectContext;
    const [editState, setEditState] = React.useState<boolean>(false);
    const [tempTagData, setTempTagData] = useArray<ManualTag>([]);
    const tagData: ManualTag[] = React.useMemo(() => tempTagData, [editState, tempTagData]);
    const [alertMsg, setAlertMsg] = React.useState<{severity: AlertColor; msg: string}>();
    const [showArchivedTags, setShowArchivedTags] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
        setShowArchivedTags(newValue);
    };

    const blocker = useBlocker(
        ({currentLocation, nextLocation}) => editState && currentLocation.pathname !== nextLocation.pathname,
    );

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string): void => {
        if (reason === "clickaway") {
            return;
        }

        setAlertMsg(undefined);
    };

    React.useEffect(() => {
        if (manualTags) {
            setTempTagData.overwrite(manualTags);
        }
    }, [manualTags]);

    const saveTagsMutation = useMutation({
        mutationKey: ["saveManualTags"],
        mutationFn: async () => {
            if (project && tempTagData) {
                const manualTags: ManualTagSQLInput[] = tempTagData.map(tag => {
                    const tagColor = tag.color ?? "#000000";
                    const red = parseInt(tagColor.slice(1, 3), 16) / 255;
                    const green = parseInt(tagColor.slice(3, 5), 16) / 255;
                    const blue = parseInt(tagColor.slice(5, 7), 16) / 255;
                    return {
                        id: tag.id,
                        name: tag.name,
                        definition: tag.definition,
                        red_normalized: red,
                        green_normalized: green,
                        blue_normalized: blue,
                        editable: tag.editable,
                        archived: tag.archived,
                    };
                });
                return await apiRequest({
                    path: `project/config/${project.id}/manualTags`,
                    method: "post",
                    data: {manualTags},
                });
            }
        },
        onSuccess: () => {
            void qc.invalidateQueries({queryKey: ["getManualTags", project?.id]});
            setEditState(false);
            setAlertMsg({severity: "success", msg: "Changes saved"});
            if (blocker.state === "blocked" && blocker.proceed) {
                blocker.proceed();
            }
        },
        onError: error => {
            setAlertMsg({severity: "error", msg: `Failed to save changes: ${error.message}`});
            if (blocker.state === "blocked" && blocker.reset) {
                blocker.reset();
            }
        },
    });

    return (
        <>
            <BeforeUnloadDialog
                {...{blocker}}
                onSave={() => {
                    saveTagsMutation.mutate();
                }}
                onLeave={() => {
                    if (blocker.proceed) {
                        blocker.proceed();
                    }
                }}
            />
            <Box p={3} height="100vh" width="67%" margin="auto">
                <Typography variant="h2" component="h2">
                    Manual Tags
                </Typography>
                <Typography component="p" color={theme.palette.midnightBlue.light}>
                    Manual tagging is a feature that allows data collectors to mark sidewalk features that are not
                    automatically collected by DeepWalk. Set the manual tags for your project below.
                </Typography>
                {!manualTagsError && (
                    <Box>
                        <Box sx={{borderBottom: 1, borderColor: "divider", mt: 2}}>
                            <Tabs
                                value={showArchivedTags}
                                onChange={handleChange}
                                aria-label="Show or hide archived tags">
                                <Tab
                                    label="Available Tags"
                                    id={`show-available-tags`}
                                    aria-controls="show-available-tags"
                                    sx={{
                                        [`&.${tabClasses.textColorPrimary}:not(.${tabClasses.selected})`]: {
                                            color: darken(colors.gray, 0.4),
                                        },
                                    }}
                                />
                                <Tab
                                    label="Archived Tags"
                                    id="show-archived-tags"
                                    aria-controls="show-archived-tags"
                                    sx={{
                                        [`&.${tabClasses.textColorPrimary}:not(.${tabClasses.selected})`]: {
                                            color: darken(colors.gray, 0.4),
                                        },
                                    }}
                                />
                            </Tabs>
                        </Box>
                        <Box
                            role="tabpanel"
                            hidden={showArchivedTags > 0}
                            id="show-available-tags-panel"
                            aria-labelledby="show-available-tags">
                            <UnarchivedTags
                                {...{editState, setEditState, tempTagData, tagData, setTempTagData}}
                                saveTags={saveTagsMutation.mutate}
                            />
                        </Box>
                        <Box
                            role="tabpanel"
                            hidden={!showArchivedTags}
                            id="show-archived-tags-panel"
                            aria-labelledby="show-archived-tags">
                            <ArchivedTags {...{tagData}} setAlert={setAlertMsg} />
                        </Box>
                    </Box>
                )}

                {manualTagsError && (
                    <Box
                        position="relative"
                        mt={6}
                        border={`1px solid ${theme.palette.grey[300]}`}
                        bgcolor={theme.palette.grey.A100}
                        borderRadius={theme.shape.borderRadius / 2}
                        height="25vh"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        gap={1}>
                        <SentimentVeryDissatisfied sx={{color: theme.palette.grey[400], fontSize: "2rem"}} />
                        <Typography variant="h2" textAlign="center" color={theme.palette.grey[500]}>
                            {manualTagsError}
                        </Typography>
                    </Box>
                )}
            </Box>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                autoHideDuration={3000}
                open={!!alertMsg}
                onClose={handleClose}>
                <Alert severity={alertMsg?.severity}>{alertMsg?.msg}</Alert>
            </Snackbar>
        </>
    );
}
