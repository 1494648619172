import {Alert, AlertColor, Box, Snackbar, Stack, Tab, Tabs, Typography} from "@mui/material";
import React, {useState} from "react";
import ChangePassword from "./components/ChangePassword";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface A11yProps {
    id: string;
    "aria-controls": string;
}

export interface AlertType {
    severity: AlertColor;
    message: string;
}

const TabPanel: React.FC<TabPanelProps> = props => {
    const {children, value, index, ...other} = props;
    return (
        <Box
            flexGrow={1}
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}>
            {children}
        </Box>
    );
};

const a11yProps = (index: number): A11yProps => ({
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
});

/**
 * User settings page
 *
 * The new password and confirm new password fields must match
 * @returns A component for the user settings page
 */
export const UserSettings: React.FC = () => {
    const [tabValue, setTabValue] = useState<number>(0);
    // these states will store the actual password error message
    const [alert, setAlert] = useState<AlertType>();

    const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
        setTabValue(newValue);
    };

    return (
        <Box p={3}>
            <Typography variant="h2" component="h2">
                Settings
            </Typography>
            <Stack direction="row" gap={2} mt={4}>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    orientation="vertical"
                    aria-label="settings page navigation tabs"
                    sx={{
                        "& .MuiTabs-flexContainer": {
                            gap: 2,
                        },
                        "& .MuiTabs-indicator": {
                            left: 0,
                        },
                    }}>
                    <Tab
                        label="Project"
                        sx={theme => ({
                            "&.MuiTab-root": {
                                color: theme.palette.primary.main,
                                fontSize: "1.2rem",
                                padding: 0,
                            },
                        })}
                        {...a11yProps(0)}
                    />
                    <Tab
                        label="Account"
                        sx={theme => ({
                            "&.MuiTab-root": {
                                color: theme.palette.primary.main,
                                fontSize: "1.2rem",
                                padding: 0,
                            },
                        })}
                        {...a11yProps(1)}
                    />
                </Tabs>
                <TabPanel value={tabValue} index={0}></TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <ChangePassword {...{setAlert}} />
                </TabPanel>
            </Stack>
            <Snackbar
                open={Boolean(alert)}
                autoHideDuration={3000}
                onClose={event => setAlert(undefined)}
                anchorOrigin={{vertical: "top", horizontal: "center"}}>
                <Alert severity={alert?.severity}>{alert?.message}</Alert>
            </Snackbar>
        </Box>
    );
};
