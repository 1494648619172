import {Card, CardHeader, CardContent, TextField, CardActions, Button, Box, Typography, Stack} from "@mui/material";
import React from "react";
import {apiRequest} from "react_ct/requests";
import {type AlertType} from "../Settings";
import {useMutation} from "@tanstack/react-query";
import {CheckCircleOutline, ErrorOutline} from "@mui/icons-material";
import {useDebounce} from "react-use";

const defaultMetRequirements = {
    minCharas: false,
    maxCharas: false,
    hasDigit: false,
    hasLowercase: false,
    hasUppercase: false,
    hasNoSpaces: false,
};

export default function ChangePassword({
    setAlert,
}: {
    setAlert: React.Dispatch<React.SetStateAction<AlertType | undefined>>;
}): React.ReactElement {
    const [currentPasswordError, setCurrentPasswordError] = React.useState<string>();
    const [confirmPasswordError, setConfirmPasswordError] = React.useState<string>();
    const [metRequirements, setMetRequirements] = React.useState(defaultMetRequirements);
    const [showMetRequirements, setShowMetRequirements] = React.useState(false);

    enum RequirementsKeyToString {
        minCharas = "Minimum of 8 characters",
        maxCharas = "Maximum of 32 characters",
        hasDigit = "At least 1 digit",
        hasLowercase = "At least one lowercase letter",
        hasUppercase = "At least one uppercase letter",
        hasNoSpaces = "Contains no spaces",
    }

    const currentPasswordRef = React.useRef<HTMLInputElement>(null);

    const [newPasswordValue, setNewPasswordValue] = React.useState<string>("");
    const [useDebouncedNewVal, setDebouncedNewVal] = React.useState(newPasswordValue);
    const [, cancelNewPass] = useDebounce(
        () => {
            setDebouncedNewVal(newPasswordValue);
        },
        100,
        [newPasswordValue],
    );

    const [confirmValue, setConfirmValue] = React.useState<string>("");
    const [useDebouncedConfirmVal, setDebouncedConfirmVal] = React.useState(confirmValue);
    const [, cancelConfirmPass] = useDebounce(
        () => {
            setDebouncedConfirmVal(confirmValue);
        },
        300,
        [confirmValue],
    );

    React.useEffect(() => {
        if (useDebouncedNewVal.length === 0) {
            setMetRequirements(defaultMetRequirements);
        } else {
            setMetRequirements({
                minCharas: useDebouncedNewVal.length >= 8,
                maxCharas: useDebouncedNewVal.length <= 32 && useDebouncedNewVal.length >= 8,
                hasDigit: /\d/.test(useDebouncedNewVal),
                hasLowercase: useDebouncedNewVal !== useDebouncedNewVal.toUpperCase(),
                hasUppercase: useDebouncedNewVal !== useDebouncedNewVal.toLowerCase(),
                hasNoSpaces: !useDebouncedNewVal.includes(" "),
            });
        }
    }, [useDebouncedNewVal]);

    // if the confirm password field value is updated, check if the passwords match
    React.useEffect(() => {
        if (useDebouncedConfirmVal.length === 0) {
            setConfirmPasswordError(undefined);
        }
        if (useDebouncedConfirmVal.length > 0 && useDebouncedNewVal !== useDebouncedConfirmVal) {
            setConfirmPasswordError("Passwords do not match");
        } else {
            setConfirmPasswordError(undefined);
        }
    }, [useDebouncedConfirmVal]);

    const changePasswordMutation = useMutation({
        mutationFn: async () => {
            await apiRequest({
                path: "auth/change-password",
                method: "POST",
                data: {
                    oldPassword: currentPasswordRef.current?.value,
                    newPassword: useDebouncedNewVal,
                },
            });
        },
        onSuccess: () => {
            setAlert({severity: "success", message: "Password changed successfully"});
            currentPasswordRef.current!.value = "";
            setNewPasswordValue("");
            setConfirmValue("");
        },
        onError: error => {
            const e: Error & {response: {data: string}} = error as Error & {response: {data: string}};
            setAlert({severity: "error", message: "Could not update password: " + e.response.data ?? error.message});
        },
    });

    const onSubmit: React.FormEventHandler = async event => {
        event.preventDefault();
        // clear password errors
        setCurrentPasswordError(undefined);
        setMetRequirements(defaultMetRequirements);
        setConfirmPasswordError(undefined);
        // TODO: validate password

        // if the new passowrd field is empty
        if (useDebouncedNewVal.length === 0) {
            setAlert({severity: "error", message: "Please provide a new password"});
            return;
        }
        // if the new password is the same as the current password
        if (currentPasswordRef.current?.value === useDebouncedNewVal) {
            setAlert({severity: "error", message: "New password cannot be the same as the current password"});
            return;
        }
        // if the confirmed password field is empty, set an error
        if (useDebouncedConfirmVal.length === 0) {
            setConfirmPasswordError("Please confirm your password");
            return;
        }
        changePasswordMutation.mutate();
    };

    return (
        <Card variant="outlined">
            <CardHeader title="Change password" />
            <form onSubmit={onSubmit}>
                <CardContent sx={{display: "flex", flexDirection: "column", gap: 2}}>
                    <TextField
                        error={Boolean(currentPasswordError)}
                        helperText={currentPasswordError}
                        inputRef={currentPasswordRef}
                        id="current-password"
                        label="Current password"
                        type="password"
                    />
                    <TextField
                        error={Object.values(metRequirements).includes(false) && showMetRequirements}
                        helperText={
                            <Box>
                                {Object.entries(metRequirements).map(([requirement, isMet]) => (
                                    <Stack key={requirement} direction="row" gap={1} mt={1}>
                                        {isMet ? (
                                            <CheckCircleOutline color="success" fontSize="small" />
                                        ) : (
                                            <ErrorOutline color="error" fontSize="small" />
                                        )}
                                        <Typography color={isMet ? "success" : "error"}>
                                            {
                                                RequirementsKeyToString[
                                                    requirement as keyof typeof RequirementsKeyToString
                                                ]
                                            }
                                        </Typography>
                                    </Stack>
                                ))}
                            </Box>
                        }
                        value={newPasswordValue}
                        onFocus={() => setShowMetRequirements(true)}
                        onBlur={() => {
                            if (!Object.values(metRequirements).includes(false)) {
                                setShowMetRequirements(false);
                            }
                        }}
                        onChange={event => setNewPasswordValue(event.target.value)}
                        id="new-password"
                        label="New password"
                        type="password"
                    />
                    <TextField
                        error={Boolean(confirmPasswordError)}
                        helperText={confirmPasswordError}
                        id="confirm-password"
                        label="Confirm new password"
                        type="password"
                        value={confirmValue}
                        onChange={event => setConfirmValue(event.target.value)}
                    />
                </CardContent>
                <CardActions sx={{justifyContent: "flex-end"}}>
                    <Button
                        type="submit"
                        onClick={onSubmit}
                        onSubmit={onSubmit}
                        color="info"
                        variant="contained"
                        disableElevation>
                        Update password
                    </Button>
                </CardActions>
            </form>
        </Card>
    );
}
