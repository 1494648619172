export const saveCookie = (key: string, value?: string): void => {
    if (value) {
        document.cookie = `${key}=${value}; path=/`;
    }
};

export const getCookie = (key: string): string | undefined =>
    document.cookie
        .split("; ")
        .find(row => row.startsWith(`${key}=`))
        ?.split("=")[1];

export const removeCookie = (key: string): void => {
    document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
};

export const removeAllCookies = (): void => {
    // delete all cookies for app.deepwalkresearch.com
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie;
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${window.location.hostname};path=/`;
    }
};
