import React from "react";
import {Box, CssBaseline} from "@mui/material";

import {NavDrawer} from "./components/NavDrawer";
import {LayoutProvider} from "contexts/LayoutContext";

interface LayoutProps {
    children: React.ReactElement;
    hideNav?: boolean;
}

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => (
    <LayoutProvider>
        <Box
            sx={theme => ({
                position: "relative",
                minHeight: "100vh",
                height: "auto",
                backgroundColor: theme.palette.secondary.main,
            })}>
            <CssBaseline />
            {/* <SiteHeader /> */}
            <Box
                sx={{
                    position: "relative",
                }}>
                {!props.hideNav && <NavDrawer />}
                <Box
                    sx={theme => ({
                        position: "absolute",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: theme.palette.background.default,
                        left: "48px",
                        width: "calc(100vw - 48px)",
                        minHeight: "100vh",
                        height: "auto",
                    })}>
                    <Box component="main" width="100%" sx={{p: 0}}>
                        {props.children}
                    </Box>
                    {/* <Footer /> */}
                </Box>
            </Box>
        </Box>
    </LayoutProvider>
);
