import React from "react";
import {
    type AlertProps,
    Box,
    Button,
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tabs,
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Input,
    InputAdornment,
    Pagination,
    rgbToHex,
    paginationClasses,
} from "@mui/material";
import {colors} from "react_ct/theme";
import {hexToHSL} from "../components";
import {ProjectUser, ReportType} from "react_ct/types";
import {adminDataCategories, dataCategories, stageOptions, tableContents} from "../constants";
import {AuthContext} from "contexts/AuthContext";
import {putScanStage} from "react_ct/requests";
import {DWAlert, SelectSingle} from "react_ct/components";
import {capitalizeFirstLetterOfEachWord, toProperCase} from "helpers/utils";
import {Error as ErrorIcon, Search} from "@mui/icons-material";
import {ErrorBoundary} from "react-error-boundary";
import {DataCategory, TabPanelProps} from "../../types";

const firstTab = dataCategories[0]; // same for both admin and non-admin

export const TabPanel: React.FC<TabPanelProps> = props => {
    const {children, value, hidden, ...other} = props;
    const hslColor = hexToHSL(`${colors.blue}ff`);
    return (
        <Box
            role="tabpanel"
            hidden={hidden}
            id={`tabpanel-${value}`}
            aria-labelledby={`tab-${value}`}
            p={3}
            sx={theme => ({
                flexGrow: 1,
                backgroundColor: `hsl(${hslColor.hue}, ${hslColor.saturation - 8}%, ${hslColor.lum + 20}%)`,
                color: theme.palette.text.primary,
                borderRadius: `${value === firstTab ? 0 : theme.shape.borderRadius * 3}px ${
                    theme.shape.borderRadius * 3
                }px ${theme.shape.borderRadius * 3}px  ${theme.shape.borderRadius * 3}px`,
                transition: "border-radius 0.5s ease-in-out",
            })}
            {...other}>
            {!hidden && <Box>{children}</Box>}
        </Box>
    );
};

/**
 *
 * @param props The inspection summary table props
 * @returns A table summarizing the inspection
 */
export function InspectionSummaryTable({
    report,
    projectUser,
}: {
    report: ReportType & {lastModified?: string};
    projectUser: ProjectUser | undefined;
}): React.ReactElement {
    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="inspection summary table">
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <strong>Folder Name</strong>
                        </TableCell>
                        <TableCell>{report.metadata.folder}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Date</strong>
                        </TableCell>
                        <TableCell>
                            {new Date(report.metadata.date).toLocaleDateString() +
                                " " +
                                new Date(report.metadata.date).toLocaleTimeString()}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Collector</strong>
                        </TableCell>
                        <TableCell>{projectUser?.userEmail ?? "--"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Type</strong>
                        </TableCell>
                        <TableCell>{report.scan_type ? toProperCase(report.scan_type) : "--"}</TableCell>
                    </TableRow>
                    {report.scan_subtype && (
                        <TableRow>
                            <TableCell>
                                <strong>Subtype</strong>
                            </TableCell>
                            <TableCell>
                                <Stack direction="row" gap={1}>
                                    {toProperCase(report.scan_subtype)}
                                </Stack>
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>
                            <strong>Detectable Warning</strong>
                        </TableCell>
                        <TableCell>{report?.detectable_warning}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Sidewalk Area</strong>
                        </TableCell>
                        <TableCell>{report.areas.combined.toFixed(1)} sf</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Material</strong>
                        </TableCell>
                        <TableCell>{report?.material}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Length</strong>
                        </TableCell>
                        <TableCell>{report.metadata.length.toFixed(1)} feet</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Deterioration</strong>
                        </TableCell>
                        <TableCell>
                            {isNaN(Math.round((report.areas.Deteriorated / report.areas.combined) * 100))
                                ? "--"
                                : `${Math.round((report.areas.Deteriorated / report.areas.combined) * 100)}%`}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Last Update</strong>
                        </TableCell>
                        <TableCell>
                            {report.lastModified
                                ? `${new Date(report.lastModified).toLocaleDateString()} ${new Date(
                                      report.lastModified,
                                  ).toLocaleTimeString()}`
                                : "--"}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function InspectionDetailsTablePage({
    filteredData,
    itemsPerPage,
    page,
}: {
    filteredData: Record<string, number>;
    itemsPerPage: number;
    page: number;
}): React.ReactElement {
    const slicedData = Object.entries(filteredData).slice((page - 1) * itemsPerPage, page * itemsPerPage);

    return (
        <Grid container component={Paper} columnSpacing={1} sx={{mt: 1, p: 1}}>
            {slicedData.map(([key, value]) => (
                <Grid item key={key} xs={6} py={1}>
                    <Stack direction="row" gap={1}>
                        <Typography fontWeight="bold" fontSize="0.875rem" flexGrow={1}>
                            {capitalizeFirstLetterOfEachWord(key.replaceAll("_", " "))}
                        </Typography>
                        <ErrorBoundary
                            fallbackRender={() => (
                                <Typography>
                                    <ErrorIcon color="disabled" />
                                </Typography>
                            )}>
                            <Typography fontSize="0.875rem" flexBasis="25%">
                                {(value ?? 0).toFixed(1)}
                                {key.endsWith("area") ? " sf" : ""}
                            </Typography>
                        </ErrorBoundary>
                    </Stack>
                </Grid>
            ))}
        </Grid>
    );
}

/**
 *
 * @param props The inspection details table props
 * @returns A table detailing the inspection
 */
export function InspectionDetailsTable({evalData}: {evalData: ReportType}): React.ReactElement {
    const detailsData: Record<string, number> = React.useMemo(
        () => ({
            ...Object.entries(evalData.raw_score ?? {})
                .map(([key, val]) => [`raw_${key}`, val])
                .reduce((total, [key, val]) => ({...total, [String(key)]: val}), {}),
            ...Object.entries(evalData.measurement_averages ?? {})
                .map(([key, val]) => [`avg_${key}`, val])
                .reduce((total, [key, val]) => ({...total, [String(key)]: val}), {}),
            ...Object.entries(evalData.measurement_maximums ?? {})
                .map(([key, val]) => [`max_${key}`, val])
                .reduce((total, [key, val]) => ({...total, [String(key)]: val}), {}),
            ...Object.entries(evalData.measurement_minimums ?? {})
                .map(([key, val]) => [`min_${key}`, val])
                .reduce((total, [key, val]) => ({...total, [String(key)]: val}), {}),
            ...Object.entries(evalData.areas ?? {})
                .map(([key, val]) => [`${key}_area`, val])
                .reduce((total, [key, val]) => ({...total, [String(key)]: val}), {}),
            ...Object.entries(evalData.violation_count ?? {})
                .map(([key, val]) => [`${key}_count`, val])
                .reduce((total, [key, val]) => ({...total, [String(key)]: val}), {}),
        }),
        [evalData],
    );
    const tableRef = React.useRef<HTMLDivElement>(null);

    const [searchTerm, setSearchTerm] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(10);

    const filteredData: Record<string, number> = React.useMemo(() => {
        if (!searchTerm.length) {
            return detailsData;
        }
        const data: Record<string, number> = {
            ...Object.entries(detailsData)
                .filter(
                    ([key]) =>
                        key.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        key.replaceAll("_", " ").toLowerCase().includes(searchTerm.toLowerCase()),
                )
                .reduce((total, [key, val]) => ({...total, [String(key)]: val}), {}),
        };
        return data;
    }, [searchTerm, detailsData]);

    React.useEffect(() => {
        const ROW_HEIGHT = 30;

        if (tableRef.current) {
            setItemsPerPage(Math.round((tableRef.current?.clientHeight ?? 100) / ROW_HEIGHT / 2) * 2);
            const handleResize = (): void => {
                setItemsPerPage(Math.round((tableRef.current?.clientHeight ?? 100) / ROW_HEIGHT / 2) * 2);
            };

            window.addEventListener("resize", handleResize);

            return () => window.removeEventListener("resize", handleResize);
        }
    }, [tableRef]);

    React.useEffect(() => {
        setPage(1);
    }, [searchTerm]);

    return (
        <Stack ref={tableRef} gap={2}>
            <FormControl variant="outlined" size="small" color="secondary">
                <InputLabel sx={{color: theme => theme.palette.secondary.light}}>Search for property</InputLabel>
                <Input
                    value={searchTerm}
                    sx={{
                        color: theme => theme.palette.secondary.light,
                        "&:before": {
                            borderBottomColor: theme => rgbToHex(theme.palette.secondary.light) + "aa",
                        },
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                        setSearchTerm(event.target.value);
                    }}
                    startAdornment={
                        <InputAdornment
                            position="start"
                            sx={{color: theme => rgbToHex(theme.palette.secondary.light) + "44"}}>
                            <Search fontSize="small" />
                        </InputAdornment>
                    }
                />
            </FormControl>
            <ErrorBoundary
                fallbackRender={({error, resetErrorBoundary}): React.ReactNode => (
                    <Paper sx={{mt: 1, p: 1}}>
                        <Stack gap={2} alignItems="center">
                            <Stack direction="row" gap={1}>
                                <ErrorIcon color="error" />
                                <Typography>Could not load page: {error.message}</Typography>
                            </Stack>
                            <Button onClick={resetErrorBoundary}>Retry</Button>
                        </Stack>
                    </Paper>
                )}>
                <InspectionDetailsTablePage {...{filteredData, itemsPerPage, page}} />
            </ErrorBoundary>
            <Stack alignItems="center" justifyContent="center">
                <Pagination
                    sx={{
                        color: theme => theme.palette.secondary.light,
                        [`&.${paginationClasses.root}`]: {
                            color: theme => theme.palette.secondary.light,
                        },
                        [`& .${paginationClasses.ul}`]: {
                            color: theme => theme.palette.secondary.light,

                            li: {
                                color: theme => theme.palette.secondary.light,

                                button: {
                                    color: theme => theme.palette.secondary.light,
                                    "&.Mui-selected": {
                                        backgroundColor: theme => rgbToHex(theme.palette.info.main),
                                        boxShadow: "0px 1px 0px #00000033",
                                    },
                                },
                            },
                        },
                    }}
                    count={Math.ceil(Object.keys(filteredData).length / itemsPerPage)}
                    {...{page}}
                    onChange={(event: React.ChangeEvent<unknown>, value: number): void => setPage(value)}
                />
            </Stack>
        </Stack>
    );
}

/**
 *
 * @returns A table containing the accessibility score legend
 */
export const TableLegend: React.FC = () => (
    <Paper sx={theme => ({p: 2, boxSizing: "border-box", borderRadius: theme.shape.borderRadius})}>
        <Stack gap={1}>
            {tableContents.map(item => {
                const hslColor = hexToHSL(`${item.color}ff`);
                return (
                    <Stack
                        key={item.id}
                        direction="row"
                        alignItems="center"
                        gap={1}
                        sx={{
                            backgroundColor: `${item.color}44`,
                            borderRadius: 50,
                            p: "2px",
                            boxSizing: "border-box",
                            border: `1px solid ${item.color}34`,
                            borderTop: "none",
                        }}>
                        <Box
                            width="30px"
                            height="30px"
                            borderRadius="50%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{backgroundColor: item.color}}>
                            <Typography fontWeight="bold" color="white">
                                {item.score ?? "--"}
                            </Typography>
                        </Box>
                        <Typography
                            fontSize="0.9rem"
                            color={`hsl(${hslColor.hue - 2}, ${hslColor.saturation - 3}%, ${hslColor.lum - 35}%, ${
                                hslColor.alpha
                            })`}>
                            {item.description}
                        </Typography>
                    </Stack>
                );
            })}
        </Stack>
    </Paper>
);

export function AdminTable({evalData}: {evalData: ReportType}): React.ReactElement {
    const {id: scanId} = evalData.metadata;

    const [stage, setStage] = React.useState(stageOptions[0]);

    const [loading, setLoading] = React.useState(false);
    const [alert, setAlert] = React.useState<{
        severity: AlertProps["severity"];
        message: string;
    }>();

    const updateScanStage = async (): Promise<void> => {
        setLoading(true);
        try {
            const resp = await putScanStage(scanId, stage.name);
            if (resp.status === 200) {
                setAlert({
                    severity: "success",
                    message: "Scan updated successfully!",
                });
            } else {
                console.error(resp);
                setAlert({
                    severity: "error",
                    message: "Could not update scan",
                });
            }
        } catch (e) {
            console.error(e);
            setAlert({
                severity: "error",
                message: "Could not update scan",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Paper sx={theme => ({p: 2, boxSizing: "border-box", borderRadius: theme.shape.borderRadius})}>
            <Box
                sx={theme => ({
                    p: 2,
                    boxSizing: "border-box",
                    borderRadius: theme.shape.borderRadius,
                })}
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection={"row"}
                alignItems={"center"}>
                <SelectSingle<{name: string}>
                    label="Stage"
                    options={stageOptions}
                    dataKey={"name"}
                    selectedOption={stage}
                    setSelectedOption={setStage}
                />
                <Button variant="contained" color="primary" disabled={loading} onClick={updateScanStage}>
                    Update Scan {scanId}
                </Button>
            </Box>
            <DWAlert
                openAlert={!!alert}
                onClose={(): void => {
                    setAlert(undefined);
                }}
                alertSeverity={alert?.severity}
                alertMessage={alert?.message}
            />
        </Paper>
    );
}

export function InspectionTabs({
    projectUser,
    currentDataCategory,
    handleChange,
    evalData,
}: {
    projectUser: ProjectUser | undefined;
    currentDataCategory: DataCategory;
    handleChange: (event: React.SyntheticEvent, newValue: DataCategory) => void;
    evalData: ReportType & {lastModified?: string};
}): React.ReactElement {
    const auth = React.useContext(AuthContext);

    const activeDataCategories = auth.checkIsAdmin?.() ? adminDataCategories : dataCategories;
    return (
        <Box sx={{flexGrow: 1, display: "flex", flexDirection: "column"}}>
            <Box sx={{position: "relative", zIndex: 2}}>
                <Tabs
                    value={currentDataCategory}
                    textColor="primary"
                    onChange={handleChange}
                    sx={{
                        flexBasis: "48px",
                        "& .MuiTabs-indicator": {
                            display: "none",
                        },
                    }}>
                    {activeDataCategories.map(category => {
                        const hslColor = hexToHSL(`${colors.blue}ff`);
                        return (
                            <Tab
                                key={category}
                                value={category}
                                label={category}
                                sx={theme => ({
                                    color: theme.palette.midnightBlue.light,
                                    "&.Mui-selected": {
                                        backgroundColor: `hsl(${hslColor.hue}, ${hslColor.saturation - 8}%, ${
                                            hslColor.lum + 20
                                        }%)`,
                                        color: theme.palette.text.secondary,
                                        borderTopLeftRadius: theme.shape.borderRadius,
                                        borderTopRightRadius: theme.shape.borderRadius,
                                    },
                                })}
                            />
                        );
                    })}
                </Tabs>
            </Box>
            {activeDataCategories.map(category => (
                <TabPanel key={category} hidden={currentDataCategory !== category} value={category}>
                    {category === "Inspection Summary" && (
                        <InspectionSummaryTable report={evalData} {...{projectUser}} />
                    )}
                    {category === "Inspection Details" && <InspectionDetailsTable {...{evalData}} />}
                    {category === "Inspection Legend" && <TableLegend />}
                    {Boolean(category === "Admin" && auth.checkIsAdmin?.()) && <AdminTable {...{evalData}} />}
                </TabPanel>
            ))}
        </Box>
    );
}
