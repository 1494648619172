import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    Input,
    InputAdornment,
    Paper,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    lighten,
    toggleButtonClasses,
} from "@mui/material";
import {colors} from "react_ct/theme";
import {useProject} from "contexts/ProjectContext";
import DWMap from "components/Map/DWMap";
import {Feature, FeatureCollection, LineString, Point} from "geojson";
import LoadingScreen from "components/LoadingScreen";
import {ManualTag, ManualTagProperties, ReportType} from "react_ct/types";
import {AttachMoney, ExpandMore} from "@mui/icons-material";
import {useQuery} from "@tanstack/react-query";
import {geojsonKeys, getGeojsonByType} from "queries/queries";
import {ArrayActions, useArray} from "hooks/useArray";
import {scoreToColor} from "../data/components/components";

interface CurbRampFilter {
    accessibilityGrades: number[];
    missingDWs: boolean;
}

interface BudgetingRowObject {
    title: string;
    quantity: number;
    price: number | undefined;
}

function BudgetOptions({
    projectManualTags,
    selectedManualTags,
    setSelectedManualTags,
    curbRampFilters,
    setCurbRampFilters,
}: {
    projectManualTags: ManualTag[] | undefined;
    selectedManualTags: ManualTag[];
    setSelectedManualTags: React.Dispatch<React.SetStateAction<ManualTag[]>>;
    curbRampFilters: CurbRampFilter;
    setCurbRampFilters: React.Dispatch<React.SetStateAction<CurbRampFilter>>;
    maxDeterioration: number;
}): React.ReactElement {
    return (
        <Box id="accordion-group">
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} id="accordion-curb-ramps">
                    <Typography fontWeight={600}>Curb Ramp Replacement Criteria</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography>Accessibility Score</Typography>
                            <ToggleButtonGroup
                                color="info"
                                sx={{width: "100%"}}
                                value={curbRampFilters.accessibilityGrades}
                                onChange={(event: React.MouseEvent<HTMLElement>, newValues: number[]) =>
                                    setCurbRampFilters(prev => ({...prev, accessibilityGrades: newValues}))
                                }>
                                {[0, 1, 2, 3, 4, 5].map(score => (
                                    <ToggleButton
                                        key={score}
                                        value={score}
                                        sx={{
                                            mx: 0.25,
                                            [`&.${toggleButtonClasses.selected}`]: {
                                                background: theme => theme.palette.info.main,
                                                color: "white",
                                                border: "none",
                                            },
                                        }}>
                                        {score}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControlLabel
                                    label={<Typography>Missing Detectable Warning</Typography>}
                                    control={
                                        <Checkbox
                                            color="info"
                                            sx={{
                                                "&.MuiCheckbox-colorInfo": {
                                                    color: theme => theme.palette.grey[400],
                                                },
                                                "&.Mui-checked": {
                                                    color: theme => theme.palette.orange.main,
                                                },
                                            }}
                                            checked={curbRampFilters.missingDWs}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                setCurbRampFilters(prev => ({
                                                    ...prev,
                                                    missingDWs: event.target.checked,
                                                }))
                                            }
                                        />
                                    }
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {projectManualTags?.length && (
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />} id="accordion-manual-tags">
                        <Typography fontWeight={600}>Manual Tags</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormGroup>
                            {projectManualTags.map(tag => (
                                <FormControlLabel
                                    key={tag.name}
                                    control={
                                        <Checkbox
                                            color="info"
                                            sx={{
                                                "&.MuiCheckbox-colorInfo": {
                                                    color: theme => theme.palette.grey[400],
                                                },
                                                "&.Mui-checked": {
                                                    color: theme => theme.palette.orange.main,
                                                },
                                            }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                if (event.target.checked) {
                                                    if (!selectedManualTags.includes(tag)) {
                                                        setSelectedManualTags(prev => [...prev, tag]);
                                                    }
                                                } else {
                                                    if (selectedManualTags.includes(tag)) {
                                                        setSelectedManualTags(prev => {
                                                            const filteredTags = prev.filter(t => t.id !== tag.id);
                                                            return filteredTags;
                                                        });
                                                    }
                                                }
                                            }}
                                        />
                                    }
                                    label={tag.name}
                                />
                            ))}
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
            )}
        </Box>
    );
}

function BudgetingRow({
    title,
    quantity,
    price,
    index,
    updateBudget,
}: {
    title: string;
    quantity: number;
    price: number | undefined;
    index: number;
    updateBudget: ArrayActions<BudgetingRowObject>;
}): React.ReactElement {
    const [totalAmount, setTotalAmount] = React.useState<number>(0);
    const [itemCost, setItemCost] = React.useState<string>(String(price ?? ""));

    React.useEffect(() => {
        if (itemCost.length) {
            setTotalAmount(parseInt(itemCost) * quantity);
            updateBudget.replace(index, {title, quantity, price: parseInt(itemCost)});
        } else {
            setTotalAmount(0);
            updateBudget.replace(index, {title, quantity, price: 0});
        }
    }, [itemCost, quantity]);

    return (
        <Stack direction="row" height="48px" alignItems="flex-end" px={4}>
            <Box flexGrow={1}>
                <Typography variant="h5">{title}</Typography>
            </Box>
            <Box width="15%" px={4}>
                <Typography>{quantity}</Typography>
            </Box>
            <Box width="20%" px={2}>
                <Input
                    type="number"
                    size="small"
                    value={itemCost}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setItemCost(event.target.value);
                    }}
                    startAdornment={
                        <InputAdornment position="start">
                            <AttachMoney />
                        </InputAdornment>
                    }
                    endAdornment={
                        <Typography fontSize="0.75em" color="dimgray">
                            each
                        </Typography>
                    }
                />
            </Box>
            <Stack direction="row" width="20%" px={4} alignItems="flex-end">
                <AttachMoney />
                <Typography className="budget-row-total">{totalAmount.toLocaleString()}</Typography>
            </Stack>
        </Stack>
    );
}

function countManualTags(manualTagData: Array<Feature<Point, ManualTagProperties>>, tag: ManualTag): number {
    const filteredTags = manualTagData.filter(
        feature => feature.properties.type.toLowerCase() === tag.name.toLowerCase(),
    );
    return filteredTags.length;
}

export default function Budget(): React.ReactElement {
    const {currentProject: project, areProjectsPending, projectManualTags} = useProject();

    const {data: geojsonData} = useQuery({
        queryKey: geojsonKeys.featuresGeojson(project?.id),
        queryFn: async ({queryKey}) => await getGeojsonByType(...(queryKey as [number | undefined, string, string])),
        enabled: !!project,
    });

    const {data: manualTagData} = useQuery({
        queryKey: geojsonKeys.individualFeaturesGeojson(project?.id),
        queryFn: async ({queryKey}) => await getGeojsonByType(...(queryKey as [number | undefined, string, string])),
        enabled: !!project,
    });

    const mapData: FeatureCollection<LineString, ReportType> = React.useMemo(() => {
        const featuresData = geojsonData?.geojsonData;
        if (!featuresData)
            return {
                type: "FeatureCollection",
                features: [],
            } as FeatureCollection<LineString, ReportType>;

        return {
            ...featuresData,
            features: (featuresData as FeatureCollection<LineString, ReportType>).features.map(feature => ({
                ...feature,
                properties: {
                    ...feature.properties,
                    color: scoreToColor(feature.properties),
                },
            })),
        };
    }, [geojsonData]);

    const allManualTags = (manualTagData?.geojsonData.features || []) as Array<Feature<Point, ManualTagProperties>>;

    const allSidewalkFeatures = (geojsonData?.geojsonData.features || []) as Array<Feature<LineString, ReportType>>;

    const [selectedManualTags, setSelectedManualTags] = React.useState<ManualTag[]>([]);
    const [curbRampFilters, setCurbRampFilters] = React.useState<CurbRampFilter>({
        accessibilityGrades: [0, 1, 2, 3, 4],
        missingDWs: true,
    });
    const [budgetingRows, setBudgetingRows] = useArray<BudgetingRowObject>([]);
    const [grandTotal, setGrandTotal] = React.useState<number>(0);

    React.useEffect(() => {
        if (allSidewalkFeatures.length) {
            const maxDeterioration = Math.max(
                ...allSidewalkFeatures.map(feature => feature.properties.percent_deteriorated),
            );
            setCurbRampFilters(prev => ({
                ...prev,
                deteriorationRange: [0, maxDeterioration],
            }));
        }
    }, [allSidewalkFeatures]);

    const curbRampCount = React.useMemo(() => {
        const filteredByAccessibilityScore = allSidewalkFeatures.filter(
            feature =>
                feature.properties.detectable_warning.toLowerCase() !== "no dw necessary" &&
                curbRampFilters.accessibilityGrades.includes(feature.properties.accessibility_grade),
        );
        const missingDWs = curbRampFilters.missingDWs
            ? allSidewalkFeatures.filter(
                  feature =>
                      !filteredByAccessibilityScore.includes(feature) &&
                      feature.properties.detectable_warning.toLowerCase() === "missing detectable warning",
              ).length
            : 0;
        return filteredByAccessibilityScore.length + missingDWs;
    }, [allSidewalkFeatures, curbRampFilters]);

    React.useEffect(() => {
        const indexOfCurbRamp = budgetingRows.findIndex(row => row.title === "Curb Ramp");
        if (indexOfCurbRamp > -1) {
            setBudgetingRows.replace(indexOfCurbRamp, {...budgetingRows[indexOfCurbRamp], quantity: curbRampCount});
        } else {
            setBudgetingRows.push({title: "Curb Ramp", quantity: curbRampCount, price: 0});
        }
    }, [curbRampCount]);

    React.useEffect(() => {
        projectManualTags?.forEach(tag => {
            const selectedTag = selectedManualTags.find(selected => selected.name === tag.name);
            if (selectedTag) {
                // if tag is selected
                if (!budgetingRows.find(row => row.title === selectedTag.name)) {
                    // if it is not in the budget
                    const tagCount = countManualTags(allManualTags, selectedTag);
                    setBudgetingRows.push({title: selectedTag.name, quantity: tagCount, price: 0});
                }
            } else {
                // if tag is not selected
                const tagIndex = budgetingRows.findIndex(row => row.title === tag.name);
                if (tagIndex > -1) {
                    /// if the tag exists in the budget
                    setBudgetingRows.remove(tagIndex);
                }
            }
        });
    }, [selectedManualTags, projectManualTags]);

    React.useEffect(() => {
        setGrandTotal(budgetingRows.reduce((total, current) => total + (current.price ?? 0) * current.quantity, 0));
    }, [budgetingRows]);

    const projectName = project?.name ?? "Untitled Project";

    const RED_LINE = lighten(colors.red, 0.75);
    const BLUE_LINE = lighten(colors.lightBlue, 0.75);

    if (areProjectsPending) {
        return <LoadingScreen />;
    }

    return (
        <Box width="100%" height="100vh" position="relative" sx={{backgroundColor: "#ebf2fc"}}>
            <Stack direction="row" p={3} height="100%" gap={4}>
                <Paper
                    id="budgeting-paper"
                    sx={{flexGrow: 1, borderRadius: theme => theme.shape.borderRadius}}
                    elevation={2}>
                    <Box id="budgeting-container" height="100%">
                        <Stack height="100%">
                            <Typography
                                variant="h2"
                                sx={{borderBottom: `2px solid ${RED_LINE}`}}
                                pt={4}
                                pb={1}
                                px={4}
                                flexShrink={1}>
                                {projectName}'s Budget
                            </Typography>
                            <Stack
                                id="budgeting-items"
                                flexGrow={1}
                                sx={{
                                    overflowY: "auto",
                                    backgroundImage: `repeating-linear-gradient(white, white 48px, ${BLUE_LINE} 49px, ${BLUE_LINE} 50px)`,
                                }}>
                                <Stack
                                    id="budgeting-items-labels"
                                    direction="row"
                                    width="100%"
                                    px={4}
                                    height="48px"
                                    alignItems="flex-end">
                                    <Box flexGrow="1"></Box>
                                    <Typography fontWeight={600} width="15%">
                                        Quantity
                                    </Typography>
                                    <Typography fontWeight={600} width="20%">
                                        Cost
                                    </Typography>
                                    <Typography fontWeight={600} width="15%">
                                        Total
                                    </Typography>
                                </Stack>
                                {budgetingRows.map((row, index) => (
                                    <BudgetingRow
                                        key={row.title}
                                        index={index}
                                        updateBudget={setBudgetingRows}
                                        {...row}
                                    />
                                ))}
                                {budgetingRows.length > 0 && (
                                    <Stack direction="row" alignItems="flex-end" height="48px" px={4} marginTop="48px">
                                        <Box flexGrow={1} />
                                        <Typography width="20%" variant="h5" px={2}>
                                            Grand Total
                                        </Typography>
                                        <Stack width="20%" direction="row" alignItems="center" px={4}>
                                            {" "}
                                            <AttachMoney /> <Typography>{grandTotal.toLocaleString()}</Typography>
                                        </Stack>
                                    </Stack>
                                )}
                            </Stack>
                        </Stack>
                    </Box>
                </Paper>
                <Stack id="map-and-options" gap={4} flexBasis="33%">
                    <Paper
                        sx={{
                            position: "relative",
                            borderRadius: theme => theme.shape.borderRadius,
                            flexBasis: "33%",
                        }}>
                        <DWMap data={mapData} dataId={["id", "scan_id"]} borderRadius />
                    </Paper>
                    <Stack id="budget-options">
                        <Typography variant="h2" component="h3">
                            Options
                        </Typography>
                        <BudgetOptions
                            maxDeterioration={Math.max(
                                ...allSidewalkFeatures.map(feature => feature.properties.percent_deteriorated),
                            )}
                            {...{
                                projectManualTags,
                                selectedManualTags,
                                setSelectedManualTags,
                                curbRampFilters,
                                setCurbRampFilters,
                            }}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
}
