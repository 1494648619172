import React from "react";
import {type AlertProps, Box, IconButton, Paper, Stack, Typography, Chip, Button} from "@mui/material";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useProject} from "contexts/ProjectContext";
import LoadingScreen from "components/LoadingScreen";
import LoadingError from "components/LoadingError";
import {apiRequest} from "react_ct/requests";
import {AxiosError} from "axios";
import type {ImplementProgram} from "react_ct/types";
import {Delete, Edit} from "@mui/icons-material";
import {DWAlert} from "react_ct/components";
import {useNavigate, Link} from "react-router-dom";
import {projectKeys} from "queries/queries";

function ProgramItem({
    program,
    setAlert,
}: {
    program: ImplementProgram;
    setAlert: React.Dispatch<React.SetStateAction<{severity: AlertProps["severity"]; message: string} | undefined>>;
}): React.ReactElement {
    const {currentProject} = useProject();
    const qc = useQueryClient();
    const navigate = useNavigate();

    const deleteItemMutation = useMutation({
        mutationFn: async () =>
            await apiRequest({
                path: "program/delete",
                method: "delete",
                params: {programId: program.id, projectId: currentProject?.id},
            }),
        onSuccess: () => {
            void qc.invalidateQueries({
                queryKey: projectKeys.projectPrograms(currentProject?.id),
            });
            setAlert({severity: "success", message: "Successfully deleted program"});
        },
        onError: error => {
            setAlert({severity: "error", message: `Could not delete program: ${error.message}`});
        },
    });

    return (
        <Paper
            sx={{
                p: 2,
                backgroundColor: theme =>
                    program.isDraft ? theme.palette.background.default : theme.palette.background.paper,
            }}>
            <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" gap={6}>
                    <Typography
                        component={Link}
                        to={`/portal/implement/program/${program.id}`}
                        variant="h5"
                        fontWeight="bold"
                        color={theme => theme.palette.text.primary}
                        sx={{textDecoration: "none"}}>
                        {program.name}
                    </Typography>
                    {program.programType && (
                        <Chip
                            label={`${program.programType[0].toLocaleUpperCase()}${program.programType
                                ?.slice(1)
                                .split("_")
                                .join(" ")}`}
                        />
                    )}
                </Stack>
                <Stack direction="row" alignItems="center" gap={2}>
                    <IconButton
                        onClick={() => {
                            if (program.id && program.programType) {
                                navigate(`/portal/implement/create/${program.programType}/review?id=${program.id}`, {
                                    state: {newProgram: program},
                                });
                            }
                        }}>
                        <Edit />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            deleteItemMutation.mutate();
                        }}>
                        <Delete color="error" />
                    </IconButton>
                </Stack>
            </Stack>
        </Paper>
    );
}

export default function ProjectPrograms(): React.ReactElement {
    const {currentProject} = useProject();
    const navigate = useNavigate();

    const {
        isPending: programsPending,
        data: programs,
        error: programsError,
    } = useQuery({
        queryKey: projectKeys.projectPrograms(currentProject?.id),
        queryFn: async () => {
            if (currentProject) {
                try {
                    const response = await apiRequest({path: "programs", params: {projectId: currentProject.id}});
                    const programs: ImplementProgram[] = response.data;
                    return programs;
                } catch (error) {
                    if (error instanceof AxiosError) {
                        if (error.request.status === 404) {
                            return [];
                        }
                    } else {
                        throw error;
                    }
                }
            } else {
                throw new Error("Could not retrieve the current project");
            }
        },
        enabled: !!currentProject,
    });

    const [alert, setAlert] = React.useState<{severity: AlertProps["severity"]; message: string}>();

    return (
        <Box id="page-container" width="100%" height="100vh" boxSizing="border-box" pt={8}>
            {programsPending ? (
                <LoadingScreen />
            ) : programsError ? (
                <LoadingError errorMessage={programsError.message} />
            ) : (
                <Box width="67%" margin="auto" boxSizing="border-box" py={4}>
                    <DWAlert
                        openAlert={!!alert}
                        onClose={() => {
                            setAlert(undefined);
                        }}
                        alertMessage={alert?.message}
                        alertSeverity={alert?.severity}
                    />
                    {!programs?.length ? (
                        <Stack width="100%" height="100vh" alignItems="center" justifyContent="center" gap={4}>
                            <Typography variant="h5" color="#00000088" textAlign="center">
                                No programs available for this project
                            </Typography>
                            <Button
                                variant="contained"
                                startIcon={<Edit />}
                                onClick={() => navigate("/portal/implement/create")}>
                                Create a program
                            </Button>
                        </Stack>
                    ) : (
                        <>
                            <Box mb={6}>
                                <Typography component="h2" variant="h3" mb={4}>
                                    Programs
                                </Typography>
                                <Stack gap={2}>
                                    {programs.filter(program => !program.isDraft).length ? (
                                        programs
                                            .filter(program => !program.isDraft)
                                            .map(program => <ProgramItem key={program.id} {...{program, setAlert}} />)
                                    ) : (
                                        <Typography variant="h5" textAlign="center" color="#00000088">
                                            No published programs available for this project
                                        </Typography>
                                    )}
                                </Stack>
                            </Box>
                            {programs.filter(program => program.isDraft) && (
                                <Box width="87%" margin="auto">
                                    <Typography component="h3" variant="h2">
                                        Draft Programs
                                    </Typography>
                                    <Stack gap={2}>
                                        {programs
                                            .filter(program => program.isDraft)
                                            .map(program => (
                                                <ProgramItem key={program.id} {...{program, setAlert}} />
                                            ))}
                                    </Stack>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            )}
        </Box>
    );
}
