import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";

import Map, {
    Layer,
    type MapLayerMouseEvent,
    Source,
    type MapRef,
    type LngLatLike,
    type LngLatBoundsLike,
    type IControl,
    Marker,
} from "react-map-gl";
import {
    MAP_STYLE,
    SOURCE_IDS,
    type MapStyle,
    LAYER_IDS,
    type ShapeType,
    type PaintStyle,
    DRAW_STYLES,
} from "./constants";
import {createFilterArray, createSelectionFilterArray, type FilterLayer} from "./filterMapHelpers";
import Box from "@mui/material/Box/Box";
import {
    AnnotationsToggle,
    DrawOptions,
    PolygonSelect,
    ToggleSatelliteButton,
    useAnnotationsToggle,
} from "./MapComponents";
import {useTheme} from "@mui/material";
import {createLayerProps, sourceKeyToLayerShape} from "./drawMapHelpers";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {severityColors} from "pages/portal/components/PointsDashboard";
import {isNaN} from "lodash";
import {v4 as uuidv4} from "uuid";
import GeoJSON from "geojson";

let MAPBOX_TOKEN: string | undefined;
try {
    MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_PK;
} catch (e) {
    // @ts-expect-error hack for using this component in storybook
    MAPBOX_TOKEN = import.meta.env.STORYBOOK_MAPBOX_PK;
}

if (!MAPBOX_TOKEN) {
    console.error("Mapbox token not found");
}

export interface DWMapProps {
    data: GeoJSON.FeatureCollection;
    /** the data selector id */
    dataId: string | string[];
    /** the id used to filter layers */
    filterLayers?: FilterLayer[];
    onMarkerClick?: (feature: GeoJSON.Feature) => void;
    onMultiSelect?: <T extends string>(ids: T[]) => void;
    filteredScanIds?: number[];
    // ** boolean to allow satellite layer toggle */
    satelliteControl?: boolean;
    satelliteControlSize?: "sm" | "lg";
    defaultSatelliteView?: "street" | "satellite";
    // ** boolean to allow annotation toggle */
    annotationControl?: boolean;
    drawControl?: boolean;
    drawFeatures?: GeoJSON.FeatureCollection;
    setDrawFeatures?: React.Dispatch<React.SetStateAction<GeoJSON.FeatureCollection>>;
    freeSelectControl?: boolean;
    freeSelectRange?: GeoJSON.Feature[];
    onFreeSelect?: (features: Array<GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>>) => void;
    /** if the map should have a border radius */
    borderRadius?: boolean;
    /** the currently selected point */
    currentSelection?: number;
    /** if the map should allow multi select by holding shift */
    multiSelect?: boolean;
    /** the selected point */
    selectedFeature?: GeoJSON.Feature[] | GeoJSON.Feature | undefined;
    selectedDrawFeature?: GeoJSON.Feature | undefined;
    onDrawnFeatureClick?: (val: GeoJSON.Feature | undefined) => void;
    zoomOnClick?: boolean;
    cluster?: boolean;
    clusterRadius?: number;
    clusterProperties?: object;
    /** for adding custom clusters */
    clusterElement?: React.ReactElement;
    updatedScanIds?: React.MutableRefObject<number[]>;
}

const interactiveLayerIds = [
    String(LAYER_IDS.Point.regular),
    String(LAYER_IDS.LineString.regular),
    String(LAYER_IDS.Polygon.regular),
    String(LAYER_IDS.Symbol.regular),
    String(LAYER_IDS.Symbol.cluster),
    String(LAYER_IDS.Point.cluster),
];

export default function DWMap({
    data,
    dataId,
    filterLayers,
    satelliteControl,
    satelliteControlSize,
    defaultSatelliteView = "street",
    annotationControl,
    drawControl,
    drawFeatures,
    setDrawFeatures,
    freeSelectControl,
    freeSelectRange,
    onFreeSelect,
    borderRadius,
    onMarkerClick,
    multiSelect,
    onMultiSelect,
    selectedFeature,
    onDrawnFeatureClick,
    zoomOnClick = true,
    cluster = false,
    clusterRadius = 50,
    clusterProperties,
    updatedScanIds,
}: DWMapProps): React.ReactElement {
    const theme = useTheme();
    const annotationProps = useAnnotationsToggle();
    const {annotationOption} = annotationProps;

    const mapRef = React.useRef<MapRef | null>(null);
    const drawRef = React.useRef<MapboxDraw | null>(null);
    const freeSelectRef = React.useRef<MapboxDraw | null>(null);
    const mapContainerRef = React.useRef<HTMLDivElement>();

    const [currentStyle, setCurrentStyle] = React.useState<MapStyle>(defaultSatelliteView);
    const [dragPan, setDragPan] = React.useState<boolean>(true);
    const [drawSelectedFeatures, setDrawSelectedFeatures] = React.useState<string[]>();
    const [drawCurrentMode, setDrawCurrentMode] = React.useState<MapboxDraw.DrawMode>("simple_select");
    const [freeSelectMode, setFreeSelectMode] = React.useState<"idle" | "editing" | "drawing">("idle");
    const [freeSelectedShapes, setFreeSelectedShapes] = React.useState<GeoJSON.Feature[]>([]);
    const [clusterFeatures, setClusterFeatures] = React.useState<GeoJSON.Feature[]>([]);

    const selectionFilterArray = React.useCallback(
        (cluster: boolean) =>
            createSelectionFilterArray(
                annotationControl ?? false,
                annotationOption,
                dataId,
                filterLayers,
                selectedFeature,
                cluster,
            ),
        [annotationOption, selectedFeature, filterLayers],
    );

    const filterArray = React.useCallback(
        (cluster: boolean) => createFilterArray(annotationControl ?? false, annotationOption, filterLayers, cluster),
        [filterLayers, annotationOption],
    );

    const onLoad = (): void => {
        if (mapRef.current) {
            if (drawFeatures) {
                const drawControlObject = new MapboxDraw({
                    displayControlsDefault: false,
                    styles: DRAW_STYLES,
                });
                drawRef.current = drawControlObject;
                mapRef.current.addControl(drawControlObject as unknown as IControl);
                if (drawFeatures.features) {
                    drawRef.current.set({
                        type: "FeatureCollection",
                        features: drawFeatures.features.map(feature => ({
                            ...feature,
                            id: feature.id ?? feature.properties?.id ?? uuidv4(),
                        })),
                    });
                }

                mapRef.current.on("draw.selectionchange", (e: MapboxDraw.DrawSelectionChangeEvent) => {
                    // when a feature is selected, automatically go into direct_select mode to automatically adjust the vertices
                    if (e) {
                        const selectedFeatures: GeoJSON.Feature[] = e.features;
                        if (selectedFeatures) {
                            setDrawSelectedFeatures(selectedFeatures?.map(feature => String(feature.id)));
                        } else {
                            setDrawSelectedFeatures(undefined);
                            if (onDrawnFeatureClick) {
                                onDrawnFeatureClick(undefined);
                            }
                        }
                        if (drawRef.current && selectedFeatures.length === 1) {
                            setDrawCurrentMode("direct_select");
                            if (onDrawnFeatureClick) {
                                onDrawnFeatureClick(selectedFeatures[0]);
                            }
                        } else if (drawRef.current && selectedFeatures.length > 1) {
                            setDrawCurrentMode("simple_select");
                            if (onDrawnFeatureClick) {
                                onDrawnFeatureClick(undefined);
                            }
                        }
                    }
                });

                mapRef.current.on("draw.modechange", (e: MapboxDraw.DrawModeChangeEvent) => {
                    // change the state mode
                    setDrawCurrentMode(e.mode);
                });

                mapRef.current.on("draw.create", (e: MapboxDraw.DrawCreateEvent) => {
                    // automatically select a feature after it's created
                    const allFeatures = drawRef.current?.getAll().features;
                    const {features} = e;
                    if (features) {
                        setDrawSelectedFeatures(features.map((feature: GeoJSON.Feature) => String(feature.id)));
                    }
                    if (setDrawFeatures && allFeatures) {
                        setDrawFeatures({
                            type: "FeatureCollection",
                            features: allFeatures,
                        });
                    }
                });

                mapRef.current.on("draw.update", (e: MapboxDraw.DrawUpdateEvent) => {
                    const updatedFeatures = e.features;
                    updatedFeatures.forEach(feature => {
                        if (updatedScanIds) {
                            if (!updatedScanIds.current.includes(feature.properties?.id)) {
                                updatedScanIds.current = [...updatedScanIds.current, feature.properties?.id];
                            }
                        }
                    });

                    const allFeatures = drawRef.current?.getAll().features;
                    if (setDrawFeatures && allFeatures) {
                        setDrawFeatures({
                            type: "FeatureCollection",
                            features: allFeatures,
                        });
                    }
                });

                mapRef.current.on("click", e => {
                    // if a feature is clicked, select it
                    if (drawRef.current) {
                        const currentMode = drawRef.current.getMode();
                        if (currentMode === "direct_select" || currentMode === "simple_select") {
                            const clickedFeatures = drawRef.current.getFeatureIdsAt(e.point).filter(feature => feature);
                            if (clickedFeatures.length > 0) {
                                drawRef.current.changeMode("direct_select", {
                                    featureId: clickedFeatures[0],
                                });
                                setDrawSelectedFeatures([String(drawRef.current.get(clickedFeatures[0])?.id)]);
                                onDrawnFeatureClick?.(drawRef.current.get(clickedFeatures[0]) as GeoJSON.Feature);
                            } else {
                                drawRef.current.changeMode("simple_select");
                                onDrawnFeatureClick?.(undefined);
                            }
                        }
                    }
                });
            }

            // if the map is able to create polygon selections, add these event listeners
            if (freeSelectControl && freeSelectRange) {
                const freeSelectObject = new MapboxDraw({
                    displayControlsDefault: false,
                    styles: DRAW_STYLES,
                });

                freeSelectRef.current = freeSelectObject;
                mapRef.current.addControl(freeSelectObject as unknown as IControl);
                freeSelectRef.current.set({type: "FeatureCollection", features: freeSelectRange});

                mapRef.current.on("draw.selectionchange", (e: MapboxDraw.DrawSelectionChangeEvent) => {
                    // when a feature is selected, automatically go into direct_select mode to automatically adjust the vertices
                    if (e) {
                        const selectedFeatures: GeoJSON.Feature[] = e.features;
                        if (selectedFeatures) {
                            // set selected feature state
                            setFreeSelectedShapes(selectedFeatures);
                        } else {
                            // set selected feature state to undefined
                            setFreeSelectedShapes([]);
                        }
                        // if one feature is selected, enter direct select mode allow the user to drag the vertices or the whole shape
                        if (freeSelectRef.current && selectedFeatures.length === 1) {
                            setFreeSelectMode("editing");
                            // otherwise, do nothing since direct select mode requires exactly one feature to be selected
                        } else if (freeSelectRef.current && selectedFeatures.length > 1) {
                            setFreeSelectMode("idle");
                        }
                    }
                });

                mapRef.current.on("draw.create", (e: MapboxDraw.DrawCreateEvent) => {
                    // add polygon to selection range
                    const createdFeatures: Array<GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>> =
                        e.features as Array<GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>>;
                    if (onFreeSelect) {
                        onFreeSelect(createdFeatures);
                    }
                    setFreeSelectedShapes(e.features);
                });

                mapRef.current.on("draw.update", (e: MapboxDraw.DrawUpdateEvent) => {
                    const updatedFeatures: Array<GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>> =
                        e.features as Array<GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>>;
                    if (onFreeSelect) {
                        onFreeSelect(updatedFeatures);
                    }
                });

                mapRef.current.on("draw.modechange", (e: MapboxDraw.DrawModeChangeEvent) => {
                    // change the state mode
                    if (e.mode === "draw_polygon") {
                        setFreeSelectMode("drawing");
                        // direct select mode allows you to edit the polygon
                    } else if (e.mode === "direct_select") {
                        setFreeSelectMode("editing");
                    } else {
                        setFreeSelectMode("idle");
                    }
                });

                mapRef.current.on("click", e => {
                    // if a feature is clicked, select it
                    if (freeSelectRef.current) {
                        const currentMode = freeSelectRef.current.getMode();
                        if (currentMode === "direct_select" || currentMode === "simple_select") {
                            const clickedFeatures = freeSelectRef.current
                                .getFeatureIdsAt(e.point)
                                .filter(feature => feature);
                            if (clickedFeatures.length === 1) {
                                freeSelectRef.current.changeMode("direct_select", {
                                    featureId: clickedFeatures[0],
                                });
                            } else {
                                freeSelectRef.current.changeMode("simple_select");
                            }
                        }
                    }
                });
            }

            mapRef.current.on("mouseover", interactiveLayerIds, (e: MapLayerMouseEvent) => {
                e.target.getCanvas().style.cursor = "pointer";
            });

            mapRef.current.on("mouseout", interactiveLayerIds, (e: MapLayerMouseEvent) => {
                e.target.getCanvas().style.cursor = "";
            });

            mapRef.current.on("click", interactiveLayerIds, (e: MapLayerMouseEvent) => {
                const features = e.features;
                if (features && features.length > 0) {
                    const feature = features[0];
                    if (!feature.properties?.DO_NOT_INTERACT) {
                        onMarkerClick?.(feature);
                    }
                }
            });

            mapRef.current.getCanvasContainer().addEventListener("keydown", (event: KeyboardEvent) => {
                if (multiSelect && onMultiSelect) {
                    if (event.key === "Shift") {
                        (event.target as HTMLDivElement).style.cursor = "crosshair";
                    }
                }
            });

            mapRef.current.getCanvasContainer().addEventListener("keyup", (event: KeyboardEvent) => {
                if ((event.target as HTMLDivElement).style.cursor === "crosshair") {
                    (event.target as HTMLDivElement).style.cursor = "default";
                }
            });

            mapRef.current?.getCanvasContainer().addEventListener("mousedown", (event: MouseEvent) => {
                if (multiSelect && onMultiSelect) {
                    // implement multiselect functionality

                    const canvas: HTMLElement = mapRef.current?.getCanvasContainer() as HTMLElement;
                    const mousePos = (e: MouseEvent): mapboxgl.Point => {
                        const rect = canvas?.getBoundingClientRect();
                        return new mapboxgl.Point(
                            e.clientX - rect.left - canvas?.clientLeft,
                            e.clientY - rect.top - canvas?.clientTop,
                        );
                    };

                    const start: mapboxgl.Point = mousePos(event);
                    let current;
                    let box: HTMLDivElement | null = null;

                    const finish = (bbox?: [mapboxgl.Point, mapboxgl.Point]): void => {
                        document.removeEventListener("mousemove", onMouseMove);
                        document.removeEventListener("mouseup", onMouseUp);
                        document.removeEventListener("keydown", onKeyDown);

                        if (box) {
                            box.parentNode?.removeChild(box);
                            box = null;
                        }

                        if (bbox) {
                            const features = mapRef.current?.queryRenderedFeatures(bbox, {});
                            // if (features) setSelectedFeatures(prev => [...prev, ...features]);
                            if (!drawFeatures && !drawRef.current) {
                                const ids = features
                                    ?.filter(feature => !feature.properties?.DO_NOT_INTERACT)
                                    .map(feature => feature.properties?.[dataId as keyof GeoJSON.GeoJsonProperties]);
                                onMultiSelect(ids as string[]);
                            } else {
                                const drawnFeatures = drawRef.current?.getAll();
                                const selectedDrawnFeatures = features?.filter(feature => {
                                    const matchingFeature = drawnFeatures?.features.find(
                                        drawnFeature => drawnFeature.id === feature.properties?.id,
                                    );
                                    return matchingFeature;
                                });
                                const selectedDrawnFeatureIds = selectedDrawnFeatures?.map(
                                    feature => feature.properties?.id,
                                );
                                setDrawSelectedFeatures(selectedDrawnFeatureIds as string[]);
                                setDrawCurrentMode("simple_select");
                            }
                        }

                        setDragPan(true);
                    };

                    const onMouseMove = (e: MouseEvent): void => {
                        current = mousePos(e);

                        if (!box) {
                            box = document.createElement("div");
                            box.classList.add("boxdraw");
                            canvas.appendChild(box);
                        }

                        // Adjust width and xy position of the box element ongoing
                        const minX = Math.min(start.x, current.x);
                        const maxX = Math.max(start.x, current.x);
                        const minY = Math.min(start.y, current.y);
                        const maxY = Math.max(start.y, current.y);

                        const pos = `translate(${minX}px, ${minY}px)`;
                        box.style.transform = pos;
                        box.style.width = `${maxX - minX}px`;
                        box.style.height = `${maxY - minY}px`;
                    };

                    const onMouseUp = (e: MouseEvent): void => {
                        finish([start, mousePos(e)]);
                    };

                    const onKeyDown = (e: KeyboardEvent): void => {
                        // If the ESC key is pressed
                        if (e.key === "27") {
                            finish();
                        }
                    };
                    // Continue the rest of the function if the shiftkey is pressed.
                    if (!(event.shiftKey && event.button === 0)) {
                        return;
                    }

                    // Disable default drag zooming when the shift key is held down.
                    setDragPan(false);

                    // Call functions for the following events
                    document.addEventListener("mousemove", onMouseMove);
                    document.addEventListener("mouseup", onMouseUp);
                    document.addEventListener("keydown", onKeyDown);
                }
            });
        }
    };

    const mapBounds = React.useMemo(() => {
        const bounds = new mapboxgl.LngLatBounds();
        if (!data.features?.length && drawFeatures?.features?.length) {
            // if there are no scan features, but there are drawn features present, use the drawn features
            drawFeatures.features
                .filter(feature => feature)
                .forEach(feature => {
                    if (feature.geometry.type === "Point") {
                        bounds.extend(feature.geometry.coordinates as [number, number]);
                    } else if (feature.geometry.type === "LineString") {
                        (feature.geometry.coordinates as Array<[number, number]>).forEach((coord: [number, number]) =>
                            bounds.extend(coord),
                        );
                    } else if (feature.geometry.type === "Polygon") {
                        (feature.geometry.coordinates as Array<Array<[number, number]>>).forEach(
                            (coordGroup: Array<[number, number]>) =>
                                coordGroup.forEach((coord: [number, number]) => bounds.extend(coord)),
                        );
                    }
                });
        } else if (data?.features?.length) {
            data.features
                .filter(feature => feature)
                .forEach(feature => {
                    if (feature.geometry.type === "Point") {
                        bounds.extend(feature.geometry.coordinates as [number, number]);
                    } else if (feature.geometry.type === "LineString") {
                        feature.geometry.coordinates.forEach((coord: GeoJSON.Position) =>
                            bounds.extend(coord as [number, number]),
                        );
                    }
                });
            drawFeatures?.features
                .filter(feature => feature)
                .forEach(feature => {
                    if (feature.geometry.type === "Point") {
                        bounds.extend(feature.geometry.coordinates as [number, number]);
                    } else if (feature.geometry.type === "LineString") {
                        (feature.geometry.coordinates as Array<[number, number]>).forEach((coord: [number, number]) =>
                            bounds.extend(coord),
                        );
                    } else if (feature.geometry.type === "Polygon") {
                        (feature.geometry.coordinates as Array<Array<[number, number]>>).forEach(
                            (coordGroup: Array<[number, number]>) =>
                                coordGroup.forEach((coord: [number, number]) => bounds.extend(coord)),
                        );
                    }
                });
        }

        return bounds.isEmpty() ? {bounds, center: new mapboxgl.LngLat(0, 0)} : {bounds, center: bounds.getCenter()};
    }, [data, mapContainerRef]);

    React.useEffect(() => {
        if (!mapBounds.bounds.isEmpty()) {
            mapRef.current?.fitBounds(mapBounds.bounds as LngLatBoundsLike, {padding: 50});
        }
    }, [mapBounds]);

    React.useEffect(() => {
        if (zoomOnClick && selectedFeature) {
            if (Array.isArray(selectedFeature)) {
                const bounds = new mapboxgl.LngLatBounds();
                selectedFeature.forEach(feature => {
                    if (feature.geometry.type === "Point") {
                        const coordinates = feature.geometry.coordinates;

                        const pointBounds: [LngLatLike, LngLatLike] = [
                            [coordinates[0], coordinates[1]], // Southwest coordinates
                            [coordinates[0], coordinates[1]], // Northeast coordinates
                        ];
                        bounds.extend(pointBounds);
                    } else if (feature.geometry.type === "LineString") {
                        // extend bounds to fit linestring
                        const {coordinates} = feature.geometry;
                        const lineBounds = new mapboxgl.LngLatBounds(
                            [coordinates[0][0], coordinates[0][1]],
                            [coordinates[0][0], coordinates[0][1]],
                        );
                        for (const coord of coordinates) {
                            lineBounds.extend([coord[0], coord[1]]);
                        }
                        bounds.extend(lineBounds);
                    }
                });
                mapRef.current?.fitBounds(bounds as LngLatBoundsLike, {
                    padding: 50,
                });
            } else {
                if (selectedFeature.geometry.type === "Point") {
                    const coordinates = selectedFeature.geometry.coordinates;
                    const bounds: [LngLatLike, LngLatLike] = [
                        [coordinates[0], coordinates[1]], // Southwest coordinates
                        [coordinates[0], coordinates[1]], // Northeast coordinates
                    ];
                    mapRef.current?.fitBounds(bounds, {
                        padding: 50,
                    });
                } else if (selectedFeature.geometry.type === "LineString") {
                    // extend bounds to fit linestring
                    const {coordinates} = selectedFeature.geometry;
                    const lineBounds = new mapboxgl.LngLatBounds(
                        [coordinates[0][0], coordinates[0][1]],
                        [coordinates[0][0], coordinates[0][1]],
                    );
                    for (const coord of coordinates) {
                        lineBounds.extend([coord[0], coord[1]]);
                    }

                    mapRef.current?.fitBounds(lineBounds as LngLatBoundsLike, {
                        padding: 50,
                    });
                }
            }
        }
    }, [selectedFeature]);

    React.useEffect(() => {
        if (drawFeatures && drawRef.current) {
            drawRef.current.set({
                type: "FeatureCollection",
                features: drawFeatures.features.map(feature => ({
                    ...feature,
                    id: feature.id ?? feature.properties?.id ?? uuidv4(),
                })),
            });
        }
    }, [drawFeatures, drawRef]);

    React.useEffect(() => {
        if (drawRef.current && drawCurrentMode !== drawRef.current.getMode()) {
            switch (drawCurrentMode) {
                case "direct_select":
                    drawRef.current.changeMode(drawCurrentMode, {
                        featureId: drawSelectedFeatures?.[0] ?? "",
                    });
                    break;
                case "simple_select":
                    drawRef.current.changeMode(
                        drawCurrentMode,
                        drawSelectedFeatures
                            ? {
                                  featureIds: drawSelectedFeatures ?? [],
                              }
                            : undefined,
                    );
                    break;
                case "draw_line_string":
                    drawRef.current.changeMode(drawCurrentMode);
                    break;
                case "draw_polygon":
                    drawRef.current.changeMode(drawCurrentMode);
                    break;
                default:
                    drawRef.current.changeMode(drawCurrentMode);
                    break;
            }
        }
    }, [drawCurrentMode]);

    React.useEffect(() => {
        if (freeSelectRef.current) {
            if (freeSelectMode === "drawing") {
                freeSelectRef.current.changeMode("draw_polygon");
            } else if (freeSelectMode === "editing") {
                if (freeSelectRange?.length) {
                    if (freeSelectRef.current.getMode() !== "direct_select") {
                        freeSelectRef.current.changeMode("direct_select", {
                            featureId: String(freeSelectRange[0]?.id) ?? "",
                        });
                    }
                }
            } else {
                freeSelectRef.current.changeMode("simple_select", {
                    featureIds: [],
                });
            }
        }
    }, [freeSelectMode]);

    React.useEffect(() => {
        if (freeSelectRef.current) {
            if (freeSelectedShapes.length === 1) {
                freeSelectRef.current.changeMode("direct_select", {
                    featureId: String(freeSelectedShapes[0].id) ?? "",
                });
            } else {
                freeSelectRef.current.changeMode("simple_select", {
                    featureIds: freeSelectedShapes.map(shape => String(shape.id)) ?? [],
                });
            }
        }
    }, [freeSelectedShapes]);

    React.useEffect(() => {
        if (freeSelectRef.current && freeSelectRange) {
            freeSelectRef.current.set({type: "FeatureCollection", features: freeSelectRange});
        }
    }, [freeSelectRange]);

    const getDataFeatures = (key: ShapeType, features: GeoJSON.Feature[]): GeoJSON.Feature[] => {
        const filteredFeatures = features.filter(feature => {
            if (key === "Symbol") {
                return feature.properties?.symbol_id;
            } else if (key === "Point") {
                return feature?.geometry.type === key && !feature?.properties?.symbol_id;
            } else {
                return feature && feature.geometry.type === key;
            }
        });

        return filteredFeatures;
    };

    const sourceToCluster = data.features.filter(features => features.properties?.symbol_id).length
        ? "map_Symbol"
        : "map_Point";

    const ClusterGraphSegment = ({
        start,
        end,
        r,
        r0,
        color,
    }: {
        start: number;
        end: number;
        r: number;
        r0: number;
        color: string;
    }): React.ReactElement => {
        if (end - start === 1) {
            end -= 0.00001;
        }
        const a0 = 2 * Math.PI * (start - 0.25);
        const a1 = 2 * Math.PI * (end - 0.25);
        const x0 = Math.cos(a0);
        const y0 = Math.sin(a0);
        const x1 = Math.cos(a1);
        const y1 = Math.sin(a1);
        const largeArc = end - start > 0.5 ? 1 : 0;
        if (isNaN(x0) || isNaN(y0) || isNaN(x1) || isNaN(y1) || isNaN(largeArc)) {
            return <></>;
        }
        return (
            <path
                d={`M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0} A ${r} ${r} 0 ${largeArc} 1 ${
                    r + r * x1
                } ${r + r * y1} L ${r + r0 * x1} ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${
                    r + r0 * y0
                }`}
                fill={color}
            />
        );
    };

    const ClusterGraph = ({values}: {values: number[]}): React.ReactElement => {
        const offsets: number[] = [];
        let total = 0;
        for (const value of values) {
            offsets.push(total);
            if (value) {
                total += value;
            }
        }

        if (!total) {
            return <></>;
        }

        return (
            <div>
                <svg
                    width={80}
                    height={80}
                    viewBox="0 0 80 80"
                    textAnchor="middle"
                    style={{font: "22px sans-serif", display: "block"}}>
                    {values.map((value, index) => (
                        <ClusterGraphSegment
                            key={index}
                            start={offsets[index] / total}
                            end={(offsets[index] + value) / total}
                            r={40}
                            r0={Math.round(40 * 0.6)}
                            color={severityColors[index]}
                        />
                    ))}
                    <circle cx={40} cy={40} r={Math.round(40 * 0.6)} fill="white" />
                    <text dominantBaseline="central" transform={`translate(40, 40)`}>
                        {total.toLocaleString()}
                    </text>
                </svg>
            </div>
        );
    };

    return (
        <Box
            ref={mapContainerRef}
            width="100%"
            height="100%"
            position="absolute"
            borderRadius={borderRadius ? theme.shape.borderRadius : 0}
            overflow="hidden">
            {satelliteControl && (
                <ToggleSatelliteButton
                    size={satelliteControlSize}
                    showSatellite={currentStyle === "satellite"}
                    onClick={() => setCurrentStyle(prev => (prev === "street" ? "satellite" : "street"))}
                />
            )}
            {annotationControl && <AnnotationsToggle {...annotationProps} />}
            {freeSelectControl && <PolygonSelect {...{freeSelectMode, setFreeSelectMode}} />}
            {drawControl && (
                <DrawOptions
                    drawMode={drawCurrentMode}
                    changeDrawMode={setDrawCurrentMode}
                    drawer={drawRef.current}
                    drawSelectedFeatures={drawSelectedFeatures}
                    setDrawFeatures={setDrawFeatures}
                />
            )}
            <Map
                ref={mapRef}
                mapboxAccessToken={MAPBOX_TOKEN}
                mapStyle={MAP_STYLE[currentStyle]}
                doubleClickZoom={!drawFeatures}
                initialViewState={{
                    latitude: mapBounds.center.lat,
                    longitude: mapBounds.center.lng,
                    zoom: (data.features.length ?? drawFeatures?.features.length) ? 13 : 0,
                }}
                onRender={() => {
                    if (!mapRef.current) {
                        return;
                    }
                    const currentClusterFeatures = mapRef.current.querySourceFeatures(sourceToCluster);
                    const filteredClusterFeatures = currentClusterFeatures.filter(feature => {
                        if (feature.properties) {
                            if (!feature.properties.cluster) {
                                return false;
                            }
                            const values: number[] = Object.entries(feature.properties)
                                .filter(
                                    entry =>
                                        entry[0] !== "cluster_id" &&
                                        entry[0] !== "cluster" &&
                                        !entry[0].includes("point_count"),
                                )
                                .map(entry => entry[1]);
                            if (!Array.isArray(values)) {
                                return false;
                            }
                            let total = 0;
                            for (const value of values) {
                                if (value) {
                                    total += value;
                                }
                            }
                            if (total === 0) {
                                return false;
                            } else {
                                return true;
                            }
                        } else {
                            return false;
                        }
                    });
                    setClusterFeatures(filteredClusterFeatures);
                }}
                {...{dragPan, interactiveLayerIds, onLoad}}
                style={{
                    cursor: freeSelectMode === "drawing" ? "crosshair" : "default",
                }}>
                {clusterFeatures.length &&
                    cluster &&
                    clusterFeatures.map((feature, index) => {
                        if (feature.properties) {
                            return (
                                <Marker
                                    key={index}
                                    longitude={(feature.geometry as GeoJSON.Point).coordinates[0]}
                                    latitude={(feature.geometry as GeoJSON.Point).coordinates[1]}
                                    draggable={false}>
                                    <ClusterGraph
                                        values={Object.entries(feature.properties)
                                            .filter(
                                                entry =>
                                                    entry[0] !== "cluster_id" &&
                                                    entry[0] !== "cluster" &&
                                                    !entry[0].includes("point_count"),
                                            )
                                            .map(entry => entry[1])}
                                    />
                                </Marker>
                            );
                        } else {
                            return <></>;
                        }
                    })}
                {(Object.keys(SOURCE_IDS) as ShapeType[]).map((key: ShapeType) => (
                    <Source
                        key={SOURCE_IDS[key]}
                        type="geojson"
                        id={SOURCE_IDS[key]}
                        cluster={
                            cluster &&
                            (SOURCE_IDS[key] === "map_Symbol" ||
                                SOURCE_IDS[key] === "map_Point" ||
                                SOURCE_IDS[key] === "map_LineString")
                        }
                        clusterMaxZoom={17}
                        {...{clusterRadius, clusterProperties}}
                        data={{
                            ...data,
                            features: getDataFeatures(key, data.features),
                        }}>
                        {(Object.keys(LAYER_IDS[key]) as PaintStyle[])
                            .filter((paintStyle: PaintStyle) => LAYER_IDS[key][paintStyle] !== null)
                            .map((paintStyle: PaintStyle) => {
                                const layerId = LAYER_IDS[key][paintStyle];
                                return layerId !== null || (cluster && paintStyle === "cluster") ? (
                                    <>
                                        {cluster && (
                                            <Layer
                                                key={`cluster-${String(LAYER_IDS[key][paintStyle])}`}
                                                id={`cluster-${String(layerId)}`}
                                                type="circle"
                                                source={SOURCE_IDS[key] as any}
                                                filter={["all", ["has", "point_count"]]}
                                                paint={{
                                                    "circle-color": ["get", "color"],
                                                    "circle-radius": [
                                                        "step",
                                                        ["get", "point_count"],
                                                        20,
                                                        100,
                                                        30,
                                                        750,
                                                        40,
                                                    ],
                                                }}
                                            />
                                        )}
                                        <Layer
                                            key={LAYER_IDS[key][paintStyle]}
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            filter={["!=", "cluster", true]}
                                            source={SOURCE_IDS[key] as any}
                                            {...createLayerProps(
                                                layerId as string,
                                                sourceKeyToLayerShape(key),
                                                key,
                                                paintStyle,
                                                ["get", "symbol_id"],
                                                annotationControl ?? false,
                                                annotationOption,
                                                selectionFilterArray(cluster && paintStyle === "cluster"),
                                                filterArray(cluster && paintStyle === "cluster"),
                                                currentStyle,
                                                !!drawFeatures,
                                            )}
                                        />
                                    </>
                                ) : (
                                    <></>
                                );
                            })}
                    </Source>
                ))}
            </Map>
        </Box>
    );
}
