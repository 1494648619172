import {Box, Stack, type BoxProps, Tooltip, Grid, Typography} from "@mui/material";
import React from "react";

export interface ChartDataProps {
    key: string | number;
    label: string;
    value: number;
    color?: string;
    unit?: string;
}

export default function HorizontalBarChart({
    containerProps,
    chartHeight = 40,
    data,
}: {
    containerProps?: BoxProps;
    chartHeight?: number;
    data: ChartDataProps[];
}): React.ReactElement {
    const totalAmount = data.reduce((total, current) => total + current.value, 0);
    return (
        <Stack gap={2}>
            <Box id="bar-chart" {...containerProps}>
                <Stack direction="row" width="100%" height={chartHeight}>
                    {data.map(item => (
                        <Tooltip
                            key={item.key}
                            placement="top"
                            title={`${item.label}: ${item.value.toFixed(2)} ${item.unit ?? ""}`}>
                            <Box
                                width={`${(item.value / totalAmount) * 100}%`}
                                height="100%"
                                sx={{backgroundColor: item.color ?? "#000"}}
                            />
                        </Tooltip>
                    ))}
                </Stack>
            </Box>
            <Grid container rowSpacing={{xs: 1}} columnSpacing={{xs: 2}}>
                {data.map(item => (
                    <Grid item key={item.key}>
                        <Tooltip title={`${item.value} ${item.unit ?? ""}`}>
                            <Stack
                                direction="row"
                                gap={1}
                                width="100%"
                                alignItems="center"
                                sx={{
                                    backgroundColor: `${item.color ?? "#000"}33`,
                                    px: 1,
                                    py: 0.5,
                                    borderRadius: 50,
                                }}>
                                <Box
                                    width={12}
                                    height={12}
                                    borderRadius="50%"
                                    sx={{backgroundColor: item.color ?? "#000"}}
                                />
                                <Typography fontSize="0.8rem">{item.label}</Typography>
                            </Stack>
                        </Tooltip>
                    </Grid>
                ))}
            </Grid>
        </Stack>
    );
}
