/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React from "react";
import {
    Alert,
    type AlertColor,
    Box,
    Fab,
    Snackbar,
    Tooltip,
    Stack,
    Button,
    styled,
    Paper,
    FormGroup,
    FormControlLabel,
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Typography,
    RadioGroup,
    Radio,
    FormControl,
    FormLabel,
    DialogActions,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import DWMap from "components/Map/DWMap";
import {CheckCircleOutline, ErrorOutline, Save, Upload} from "@mui/icons-material";
import {apiRequest} from "react_ct/requests";
import {useProject} from "contexts/ProjectContext";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {createGeoJSONFeatureCollection} from "components/Map/helpers";
import {colors} from "react_ct/theme";
import LoadingScreen from "components/LoadingScreen";
import {ShiftControl} from "components/Map/MapComponents";
import LoadingError from "components/LoadingError";
import {isEqual} from "lodash";
import {useBlocker} from "react-router-dom";
import BeforeUnloadDialog from "components/BeforeUnloadDialog";
import {v4 as uuidv4} from "uuid";
import {ProjectPlanProperties} from "react_ct/types/projAndOrg";
import {length} from "@turf/length";
import {area} from "@turf/area";
import GeoJSON from "geojson";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

type PlanFeatureGeometry = GeoJSON.Point | GeoJSON.LineString | GeoJSON.MultiLineString | GeoJSON.Polygon;
type PlanFeature = GeoJSON.Feature<PlanFeatureGeometry, ProjectPlanProperties>;
type PlanFeatureCollection = GeoJSON.FeatureCollection<PlanFeatureGeometry, ProjectPlanProperties>;

function UploadGeoJSONModal({
    geojson,
    open,
    onClose,
    onUploadGeojson,
}: {
    geojson: GeoJSON.FeatureCollection | undefined;
    open: boolean;
    onClose: () => void;
    onUploadGeojson: (option: "add" | "overwrite", geojson: GeoJSON.FeatureCollection) => void;
}): React.ReactElement {
    const [selectedOption, setSelectedOption] = React.useState<"add" | "overwrite">("add");
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSelectedOption((event.target as HTMLInputElement).value as "add" | "overwrite");
    };
    return (
        <Dialog open={open && !!geojson} {...{onClose}} aria-labelledby="upload-geojson-title">
            <DialogTitle id="upload-geojson-title">Upload GeoJSON File</DialogTitle>
            <DialogContent>
                <Typography>View your uploaded GeoJSON file:</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    maxRows={8}
                    value={JSON.stringify(geojson ?? "").replaceAll('"', "'")}
                />
                <FormControl sx={{mt: 2}}>
                    <FormLabel>Upload Options</FormLabel>
                    <RadioGroup value={selectedOption} onChange={handleChange}>
                        <FormControlLabel label="Overwrite existing plan" value="overwrite" control={<Radio />} />
                        <FormControlLabel label="Add to existing plan" value="add" control={<Radio />} />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    variant="contained"
                    onClick={(): void => onUploadGeojson(selectedOption, geojson as GeoJSON.FeatureCollection)}>
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function Plan(): React.ReactElement {
    const {currentProject, projectScans: scans, projectScansError} = useProject();
    const queryClient = useQueryClient();

    const [drawFeatures, setDrawFeatures] = React.useState<GeoJSON.FeatureCollection>({
        type: "FeatureCollection",
        features: [],
    });
    const [isShiftPressed, setIsShiftPressed] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<{severity: AlertColor; message: string}>();
    const [showOriginalLines, setShowOriginalLines] = React.useState(true);
    const [openUploadModal, setOpenUploadModal] = React.useState(false);
    const [uploadedGeoJSON, setUploadedGeoJSON] = React.useState<GeoJSON.FeatureCollection>();
    const [isLoadingGeojson, setIsLoadingGeojson] = React.useState(false);
    const [noPlanFound, setNoPlanFound] = React.useState(false);

    const uploadGeojsonRef = React.useRef<HTMLInputElement>(null);

    const handleToggleSwitch = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setShowOriginalLines(event.target.checked);
    };

    const data = React.useMemo(() => {
        if (scans) {
            const collection = createGeoJSONFeatureCollection(scans, () => colors.gray);
            if (!collection) {
                return {
                    type: "FeatureCollection",
                    features: [],
                };
            }
            const filteredFeatures = collection.features.filter(feature => feature.geometry.type !== "Point");
            return {
                ...collection,
                features: filteredFeatures,
            };
        } else {
            return {
                type: "FeatureCollection",
                features: [],
            };
        }
    }, [scans]);

    const {data: savedPlan, error: savedPlanError} = useQuery({
        queryKey: ["getSavedFeatures", currentProject?.id],
        queryFn: async () => {
            if (currentProject) {
                const res = await apiRequest({
                    path: `project/plan/${currentProject.id}/me/cwa`,
                });

                return res.data as {planId: number; collection: PlanFeatureCollection};
            }
        },
        enabled: !!currentProject,
    });

    const savedFeatureCollection: PlanFeatureCollection = (savedPlan?.collection as PlanFeatureCollection) ?? {
        type: "FeatureCollection",
        features: [],
    };

    const savedFeatures: PlanFeature[] = savedFeatureCollection?.features ?? [];

    React.useEffect(() => {
        if (savedPlanError?.message.includes("404") && !noPlanFound) {
            setNoPlanFound(true);
        }
    }, [savedPlanError]);

    const formattedFeatures: GeoJSON.FeatureCollection = React.useMemo(() => {
        if (!savedFeatures?.length) {
            return {
                type: "FeatureCollection",
                features: [],
            };
        }

        return {
            type: "FeatureCollection",
            features: savedFeatures.map(feature => ({
                id: uuidv4(),
                type: "Feature",
                geometry: feature.geometry,
                properties: {
                    db_id: feature.id,
                },
            })),
        };
    }, [savedFeatures]);

    const openSnackbar = Boolean(snackbarMessage);

    const planFeaturesLength = React.useMemo(() => {
        const planLines = drawFeatures.features.filter(
            feature => feature.geometry.type === "LineString" || feature.geometry.type === "MultiLineString",
        );
        const total = planLines.reduce((total, current) => total + length(current, {units: "miles"}), 0);

        return total;
    }, [drawFeatures]);

    const planFeaturesArea = React.useMemo(() => {
        const planPolygons = drawFeatures.features.filter(
            feature => feature.geometry.type === "Polygon" || feature.geometry.type === "MultiPolygon",
        );
        const total = planPolygons.reduce((total, current) => total + area(current), 0);
        // it's in square meters, so convert to square miles
        const convertedTotal = total / 2590000;
        return convertedTotal;
    }, [drawFeatures]);

    const handleUploadGeoJSON = (event: React.ChangeEvent): void => {
        const file = (event.target as HTMLInputElement).files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = (event): void => {
                // process the geojson contents
                try {
                    setIsLoadingGeojson(false);
                    if (event.target?.result) {
                        let content = event.target?.result;
                        const decoder = new TextDecoder();
                        if (content instanceof ArrayBuffer) {
                            content = decoder.decode(content);
                        }
                        const geojson: GeoJSON.FeatureCollection = JSON.parse(content);
                        setUploadedGeoJSON(geojson);
                        setOpenUploadModal(true);
                    }
                } catch (error) {
                    console.error(error);
                    const e: Error = error as Error;
                    setSnackbarMessage({severity: "error", message: e.message});
                }
            };
            reader.onprogress = (): void => {
                setIsLoadingGeojson(true);
            };
            reader.onerror = (): void => {
                setIsLoadingGeojson(false);
                setSnackbarMessage({
                    severity: "error",
                    message: "Could not parse the given file.",
                });
            };
        }
    };

    const onUploadGeojson = (option: "add" | "overwrite", geojson: GeoJSON.FeatureCollection): void => {
        const formatGeojsonFeature = (feature: GeoJSON.Feature): GeoJSON.Feature => ({
            ...feature,
            id: uuidv4(),
            properties: {},
        });

        if (option === "add") {
            setDrawFeatures(prev => ({
                type: "FeatureCollection",
                features: [
                    ...prev.features,
                    ...geojson.features
                        .filter(
                            feature =>
                                feature.geometry &&
                                (feature.geometry.type === "Point" ||
                                    feature.geometry.type === "LineString" ||
                                    feature.geometry.type === "MultiLineString" ||
                                    feature.geometry.type === "Polygon"),
                        )
                        .map(formatGeojsonFeature),
                ],
            }));
        } else {
            setDrawFeatures({
                type: "FeatureCollection",
                features: geojson.features
                    .filter(
                        feature =>
                            feature.geometry &&
                            (feature.geometry.type === "Point" ||
                                feature.geometry.type === "LineString" ||
                                feature.geometry.type === "MultiLineString" ||
                                feature.geometry.type === "Polygon"),
                    )
                    .map(formatGeojsonFeature),
            });
        }
        setOpenUploadModal(false);
        setSnackbarMessage({
            severity: "success",
            message: "Successfully uploaded GeoJSON file.",
        });
        if (uploadGeojsonRef.current) {
            uploadGeojsonRef.current.value = "";
        }
    };

    const hasUnsavedChanges = React.useMemo(() => {
        if (!drawFeatures?.features) {
            return false;
        }
        if (drawFeatures.features.length !== formattedFeatures.features.length) {
            return true;
        }

        // Compare each element
        for (let i = 0; i < drawFeatures.features.length; i++) {
            if (drawFeatures.features[i].id !== formattedFeatures.features[i].id) {
                return true;
            } else if (!isEqual(drawFeatures.features[i].geometry, formattedFeatures.features[i].geometry)) {
                return true;
            }
        }

        return false;
    }, [savedFeatures, drawFeatures]);

    React.useEffect(() => {
        if (!openUploadModal) {
            setUploadedGeoJSON(undefined);
        }
    }, [openUploadModal]);

    React.useEffect(() => {
        if (formattedFeatures.features.length) {
            setDrawFeatures(formattedFeatures);
        }
    }, [formattedFeatures]);

    const saveChangesMutation = useMutation({
        mutationFn: async () => {
            if (!currentProject) {
                throw new Error("Could not retrieve current project");
            }
            if (!savedPlan) {
                return await apiRequest({
                    method: "post",
                    path: `project/plan/${currentProject.id}/me`,
                    data: {collection: drawFeatures},
                });
            } else {
                const uniqueFeatures = Object.entries(
                    drawFeatures.features.reduce(
                        (accumulated, shape) => {
                            const coords = (
                                (
                                    shape.geometry as
                                        | GeoJSON.Point
                                        | GeoJSON.MultiPoint
                                        | GeoJSON.LineString
                                        | GeoJSON.MultiLineString
                                        | GeoJSON.Polygon
                                        | GeoJSON.MultiPolygon
                                ).coordinates.flat(Infinity) as number[]
                            ).join(",");

                            if (!accumulated[coords]) {
                                accumulated[coords] = [];
                            }

                            (accumulated[coords] ?? []).push(shape);
                            return accumulated;
                        },
                        {} as Record<string, Array<GeoJSON.Feature>>,
                    ),
                ).map(([key, features]) => features?.[0]);

                const featuresToUpdate: GeoJSON.Feature[] = uniqueFeatures.map(feature => ({
                    ...feature,
                    id: feature.properties?.db_id,
                }));

                return await apiRequest({
                    method: "put",
                    path: `project/plan/${currentProject.id}`,
                    params: {
                        planId: savedPlan.planId,
                    },
                    data: {
                        collection: {
                            type: "FeatureCollection",
                            features: featuresToUpdate,
                        },
                    },
                });
            }
        },
        onSuccess: async () => {
            void queryClient.invalidateQueries({queryKey: ["getSavedFeatures", currentProject?.id]});
            setSnackbarMessage({
                severity: "success",
                message: "Changes saved successfully!",
            });
            if (blocker.state === "blocked" && blocker.proceed) {
                blocker.proceed();
            }
        },
        onError: async error => {
            setSnackbarMessage({
                severity: "error",
                message: `Could not save changes: ${error.message.includes("413") ? "" : error.message}`,
            });
            if (blocker.state === "blocked" && blocker.reset) {
                blocker.reset();
            }
        },
    });

    React.useEffect(() => {
        const onKeyDown = (e: KeyboardEvent): void => {
            if (e.key === "Shift") {
                setIsShiftPressed(true);
            }
        };

        const onKeyUp = (): void => {
            setIsShiftPressed(false);
        };

        document.addEventListener("keydown", onKeyDown);
        document.addEventListener("keyup", onKeyUp);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
            document.removeEventListener("keyup", onKeyUp);
            queryClient.setQueryData(["projectUsersData", currentProject?.id], null);
        };
    }, []);

    const blocker = useBlocker(
        ({currentLocation, nextLocation}) => currentLocation.pathname !== nextLocation.pathname && hasUnsavedChanges,
    );

    React.useEffect(() => {
        const onKeyDown = (e: KeyboardEvent): void => {
            if (e.key === "Shift") {
                setIsShiftPressed(true);
            }
        };

        const onKeyUp = (): void => {
            setIsShiftPressed(false);
        };

        document.addEventListener("keydown", onKeyDown);
        document.addEventListener("keyup", onKeyUp);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
            document.removeEventListener("keyup", onKeyUp);
            queryClient.setQueryData(["projectUsersData", currentProject?.id], null);
        };
    }, []);

    return (
        <Box id="page-container" width="100%" height="100vh" position="relative">
            {(projectScansError ?? (savedPlanError && !noPlanFound)) ? (
                <LoadingError errorMessage={projectScansError ?? savedPlanError?.message} />
            ) : ((scans && savedFeatures) ?? noPlanFound) ? (
                <>
                    <Backdrop sx={theme => ({color: "#fff", zIndex: theme.zIndex.drawer + 1})} open={isLoadingGeojson}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <BeforeUnloadDialog
                        {...{blocker}}
                        onSave={(): void => {
                            saveChangesMutation.mutate();
                        }}
                        onLeave={(): void => {
                            if (blocker.proceed) {
                                blocker.proceed();
                            }
                        }}
                    />
                    <UploadGeoJSONModal
                        geojson={uploadedGeoJSON}
                        open={openUploadModal}
                        onClose={(): void => {
                            setOpenUploadModal(false);
                            if (uploadGeojsonRef.current) {
                                uploadGeojsonRef.current.value = "";
                            }
                        }}
                        {...{onUploadGeojson}}
                    />
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={5000}
                        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                        onClose={(): void => {
                            setSnackbarMessage(undefined);
                        }}>
                        <Alert
                            severity={snackbarMessage?.severity}
                            icon={snackbarMessage?.severity === "success" ? <CheckCircleOutline /> : <ErrorOutline />}>
                            {snackbarMessage?.message}
                        </Alert>
                    </Snackbar>
                    <DWMap
                        drawControl
                        satelliteControl
                        multiSelect
                        onMultiSelect={(): void => {
                            // idk
                        }}
                        dataId="id"
                        selectedFeature={undefined}
                        zoomOnClick={false}
                        data={
                            showOriginalLines
                                ? (data as GeoJSON.FeatureCollection)
                                : {type: "FeatureCollection", features: []}
                        }
                        {...{drawFeatures, setDrawFeatures}}
                    />
                    <ShiftControl isShiftPressed={isShiftPressed} top={8} left={24} />
                    <Paper
                        elevation={3}
                        sx={{
                            position: "absolute",
                            top: 64,
                            left: 24,
                            zIndex: 20,
                            borderRadius: theme => theme.shape.borderRadius,
                            px: 2,
                        }}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={showOriginalLines} onChange={handleToggleSwitch} />}
                                label="Show original scan lines"
                            />
                        </FormGroup>
                    </Paper>
                    {(planFeaturesLength > 0 ?? planFeaturesArea > 0) && (
                        <Paper
                            elevation={3}
                            sx={{
                                position: "absolute",
                                top: 128,
                                left: 24,
                                zIndex: 20,
                                borderRadius: 50,
                                px: 2,
                                py: 1,
                            }}>
                            <Stack>
                                {planFeaturesLength > 0 && (
                                    <Typography>Plan length: {planFeaturesLength.toFixed(3)} miles</Typography>
                                )}
                                {planFeaturesArea > 0 && (
                                    <Typography>Plan area: {planFeaturesArea.toFixed(3)} square miles</Typography>
                                )}
                            </Stack>
                        </Paper>
                    )}
                    <Stack
                        gap={2}
                        alignItems="center"
                        sx={{
                            position: "absolute",
                            bottom: 32,
                            right: 32,
                        }}>
                        <Button variant="contained" color="secondary" component="label" startIcon={<Upload />}>
                            Upload GeoJSON File
                            <VisuallyHiddenInput
                                type="file"
                                accept="application/geo+json"
                                ref={uploadGeojsonRef}
                                onChange={handleUploadGeoJSON}
                            />
                        </Button>
                        <Tooltip title="Save plans">
                            <span>
                                <Fab
                                    color="primary"
                                    aria-label="save"
                                    sx={{
                                        p: 2,
                                        width: "auto",
                                        height: "auto",
                                    }}
                                    disabled={!hasUnsavedChanges}
                                    onClick={(): void => saveChangesMutation.mutate()}>
                                    <Save sx={{fontSize: "4rem"}} />
                                </Fab>
                            </span>
                        </Tooltip>
                    </Stack>
                </>
            ) : (
                <LoadingScreen />
            )}
        </Box>
    );
}
