import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    CircularProgress,
    InputAdornment,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useTheme,
} from "@mui/material";
import {type ReportType} from "react_ct/types";
import {InspectionTabs} from "./components/Details/InspectionTabs";
import {AccessScoreIndicator, dwStatusToColor, evalScoreToColor} from "./components/components";
import DWMap from "components/Map/DWMap";
import {useProject} from "contexts/ProjectContext";
import LoadingError from "components/LoadingError";
import {apiGetRotatedEvalData, apiRequest, downloadFromS3} from "react_ct/requests";
import {Error as ErrorIcon, FolderOpen, Search} from "@mui/icons-material";
import {useQuery} from "@tanstack/react-query";
import {geojsonKeys, getGeojsonByType, scanKeys} from "queries/queries";
import {ErrorBoundary} from "react-error-boundary";
import {ZoomCanvas} from "./components/Details/ZoomCanvas";
import GeoJSON from "geojson";
import {DataCategory} from "./types";

/**
 *
 * @returns The data page
 */
export const DataDetailsPage: React.FC = () => {
    const theme = useTheme();
    const projectContext = useProject();
    const {
        currentProject: project,
        projectList: projectsData,
        projectsError,
        projectScans,
        projectUserList,
    } = projectContext;

    const [projectDataError, setProjectDataError] = useState<string>();
    const [selectedScanId, setSelectedScanId] = useState<string>("");
    const [currentDataCategory, setCurrentDataCategory] = useState<DataCategory>("Inspection Summary");
    const [selectedMapView, setSelectedMapView] = useState<"accessibility" | "curb" | "dw">("accessibility");
    const [selectedFeature, setSelectedFeature] = useState<GeoJSON.Feature<GeoJSON.LineString, ReportType>>();
    const [selectedCanvasItemId, setSelectedCanvasItemId] = useState<string | null>(null);
    const [noScanFound, setNoScanFound] = useState(false);

    const {
        data: geojsonData,
        isPending: isFormattedDataPending,
        error: formattedDataError,
    } = useQuery({
        queryKey: geojsonKeys.featuresGeojson(project?.id),
        queryFn: async ({queryKey}) => await getGeojsonByType(...(queryKey as [number | undefined, string, string])),
        enabled: !!project,
    });

    const formattedData: GeoJSON.FeatureCollection<GeoJSON.LineString, ReportType & {color: string}> | undefined =
        React.useMemo(() => {
            if (!geojsonData?.geojsonData) {
                return undefined;
            }
            const data: GeoJSON.FeatureCollection<GeoJSON.LineString, ReportType & {color: string}> = {
                type: "FeatureCollection",
                features: geojsonData.geojsonData.features
                    .filter(feature =>
                        selectedMapView === "curb" ? (feature.properties as ReportType).ramp_check : true,
                    )
                    .map(feature => {
                        const featureId = feature.properties?.metadata.id;
                        return {
                            ...feature,
                            id: featureId,
                            geometry: feature.geometry as GeoJSON.LineString,
                            properties: {
                                ...(feature.properties as ReportType),
                                id: (feature.properties as ReportType).metadata?.id,
                                color:
                                    selectedMapView === "dw"
                                        ? dwStatusToColor(feature.properties as ReportType)
                                        : evalScoreToColor(feature.properties as ReportType),
                            },
                        };
                    }),
            };
            return data;
        }, [geojsonData, selectedMapView]);

    const formattedDataRef = useRef<GeoJSON.FeatureCollection<GeoJSON.LineString, ReportType & {color: string}>>(
        formattedData ?? {type: "FeatureCollection", features: []},
    );

    const selectDataPoint = (feature: GeoJSON.Feature): void => {
        const foundFeature = formattedDataRef.current.features.find(
            item => item.properties?.metadata.id === feature.id,
        );
        setSelectedFeature(foundFeature);
    };

    React.useEffect(() => {
        if (formattedData) {
            formattedDataRef.current = formattedData;
        }
    }, [formattedData]);

    const noDataAvailable = (!formattedData && !isFormattedDataPending) || formattedData?.features.length === 0;

    const {
        data: scanReportData,
        isPending: isScanReportDataPending,
        error: scanReportDataError,
    } = useQuery({
        queryKey: scanKeys.scanEvalUrl(parseInt(selectedScanId)),
        enabled: !!projectScans && selectedScanId !== undefined,
        queryFn: async () => {
            const scanId: number = parseInt(selectedScanId);
            if (scanId) {
                const res = await apiGetRotatedEvalData(scanId);
                if (res.rotatedEvalUrl) {
                    const evalData = await downloadFromS3(res.rotatedEvalUrl);
                    const evalDataGeojson: ReportType = await evalData.json();

                    if (evalDataGeojson) {
                        return evalDataGeojson;
                    }
                }
            }
        },
    });

    const {data: s3AutoreportUrl} = useQuery({
        queryKey: scanKeys.scanAutoreportUrl(parseInt(selectedScanId)),
        enabled: !!projectScans && selectedScanId !== undefined,
        queryFn: async ({queryKey}) => {
            const folderName = selectedFeature?.properties.metadata.folder;
            const scanId = queryKey[1];
            if (folderName) {
                const res = await apiRequest({
                    path: `scan/autoreport`,
                    params: {
                        folderName,
                        scanId,
                    },
                });

                if (res.data.autoreportUrl) {
                    return res.data.autoreportUrl;
                } else {
                    throw new Error("No autoreport url found");
                }
            }
        },
    });

    useEffect(() => {
        if (formattedData) {
            selectDataPoint(formattedData.features[0]);
        }
    }, [formattedData, selectedMapView]);

    useEffect(() => {
        if (projectsData?.length !== undefined && projectsData.length === 0) {
            setProjectDataError("No projects available");
        } else {
            setProjectDataError(undefined);
        }
    }, [projectsData]);

    useEffect(() => {
        setSelectedCanvasItemId(null);
        if (selectedFeature) {
            setSelectedScanId(String(selectedFeature.properties.metadata.id));
        }
    }, [selectedFeature]);

    const handleChange = (event: React.SyntheticEvent, newValue: DataCategory): void => {
        setCurrentDataCategory(newValue);
    };

    if (projectsError ?? projectDataError ?? formattedDataError) {
        return (
            <Box p={3} height="100vh" boxSizing="border-box">
                <LoadingError
                    errorMessage={String(formattedDataError?.message ?? projectsError ?? "No report data is available")}
                />
            </Box>
        );
    }

    if (noDataAvailable) {
        return (
            <Box p={3} height="100vh" boxSizing="border-box">
                <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
                    <Stack spacing={2} alignItems="center">
                        <FolderOpen
                            sx={theme => ({
                                color: theme.palette.midnightBlue.light,
                                fontSize: "5rem",
                            })}
                        />
                        <Typography variant="h3" color={theme => theme.palette.midnightBlue.light}>
                            No data available
                        </Typography>
                    </Stack>
                </Box>
            </Box>
        );
    }

    return (
        <Box p={3} height="100vh" boxSizing="border-box">
            <Stack sx={{height: "100%"}}>
                <Stack direction="row" gap={3} sx={{flexGrow: 1}}>
                    <Stack gap={1} flexBasis="55%">
                        <Stack direction="row" gap={5} justifyContent="space-between" alignItems="baseline">
                            <Stack direction="row" gap={0.5} alignItems="baseline">
                                <Typography
                                    component="h2"
                                    variant="body1"
                                    color={theme.palette.midnightBlue.light}
                                    fontWeight={700}
                                    fontSize="1.5rem">
                                    ID:
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    value={selectedScanId}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Search color="disabled" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                        setSelectedScanId(event.target.value);
                                        if (event.target.value.length === 6) {
                                            const matchingFeature = formattedData?.features.find(
                                                feature =>
                                                    feature.properties.metadata.id === parseInt(event.target.value),
                                            );
                                            if (!matchingFeature) {
                                                setNoScanFound(true);
                                            } else {
                                                setNoScanFound(false);
                                                setSelectedFeature(matchingFeature);
                                            }
                                        } else {
                                            if (noScanFound) {
                                                setNoScanFound(false);
                                            }
                                        }
                                    }}
                                    error={noScanFound}
                                    helperText="Could not find scan with given ID"
                                    sx={{
                                        p: 0,
                                        [`& .MuiInputBase-input`]: {
                                            fontFamily: "'Lato', sans-serif",
                                            fontWeight: 700,
                                            fontSize: "1.5rem",
                                            color: theme.palette.midnightBlue.light,
                                            background: "transparent",
                                            outline: "none",
                                            width: "6em",
                                            pl: 0.5,
                                            py: 3,
                                            boxSizing: "border-box",
                                        },
                                    }}
                                />
                            </Stack>
                            {scanReportData && <AccessScoreIndicator evalData={scanReportData} />}
                        </Stack>
                        {scanReportData?.media_links.raster_downsampled !== undefined ? (
                            <ZoomCanvas
                                autoreportUrl={s3AutoreportUrl}
                                rasterUrl={scanReportData.media_links.raster_downsampled}
                                evalData={scanReportData}
                                {...{selectedCanvasItemId, setSelectedCanvasItemId}}
                            />
                        ) : scanReportDataError ? (
                            <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                                <Stack alignItems="center" gap={2}>
                                    <ErrorIcon />
                                    <Typography variant="subtitle2">Could not retrieve scan image</Typography>
                                </Stack>
                            </Box>
                        ) : (
                            <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                                <Stack alignItems="center" gap={2}>
                                    <CircularProgress />
                                    <Typography fontSize="0.9rem" variant="subtitle2">
                                        Retrieving scan image...
                                    </Typography>
                                </Stack>
                            </Box>
                        )}
                    </Stack>
                    <Stack direction="column" flexBasis="45%" flexShrink={1} gap={3}>
                        <Box
                            sx={theme => ({
                                height: "40vh",
                                position: "relative",
                                boxShadow: theme.shadows[1],
                                borderRadius: theme.shape.borderRadius,
                                border: "1px solid #ffffffaa",
                                borderBottom: "none",
                            })}>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 5,
                                    left: 5,
                                    zIndex: 3,
                                    maxWidth: "95%",
                                }}>
                                <ToggleButtonGroup
                                    exclusive
                                    value={selectedMapView}
                                    aria-label="Select map view"
                                    onChange={(
                                        event: React.MouseEvent<HTMLElement>,
                                        newValue: "accessibility" | "curb" | "dw",
                                    ): void => {
                                        if (newValue !== null) {
                                            setSelectedMapView(newValue);
                                        }
                                    }}
                                    sx={theme => ({
                                        gap: 1,
                                        "& .MuiToggleButtonGroup-grouped": {
                                            padding: "2px 10px",
                                            borderRadius: "50px",
                                            backgroundColor: "#ffffffcc",
                                            backdropFilter: "blur(4px)",
                                            boxShadow: theme.shadows[3],

                                            "&.Mui-selected": {
                                                backgroundColor: theme.palette.deepWalkBlue.main,
                                                color: theme.palette.text.secondary,
                                                border: "none",
                                            },
                                        },
                                        "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
                                            borderRadius: "50px",
                                        },
                                        "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                                            borderRadius: "50px",
                                        },
                                    })}>
                                    <ToggleButton value="accessibility" aria-label="Accessibility score">
                                        Accessibility Score
                                    </ToggleButton>
                                    <ToggleButton value="curb" aria-label="Curb ramp">
                                        Curb Ramp
                                    </ToggleButton>
                                    <ToggleButton value="dw" aria-label="Detectable warning">
                                        Detectable Warning
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                            <DWMap
                                satelliteControl
                                satelliteControlSize="lg"
                                onMarkerClick={selectDataPoint}
                                data={formattedData ?? {type: "FeatureCollection", features: []}}
                                dataId="id"
                                selectedFeature={selectedFeature}
                                borderRadius
                            />
                        </Box>
                        {scanReportData && (
                            <ErrorBoundary
                                fallbackRender={({error}): React.ReactNode => (
                                    <Stack direction="row" gap={2}>
                                        <ErrorIcon color="error" />
                                        <Typography>Could not load inspection details: {error.message}</Typography>
                                    </Stack>
                                )}
                                onError={(error: Error): void => {
                                    console.error("an error occured in Inspection Tabs: ", error);
                                }}>
                                <InspectionTabs
                                    projectUser={projectUserList?.find(
                                        user => user.userId === scanReportData.metadata.collector,
                                    )}
                                    currentDataCategory={currentDataCategory}
                                    handleChange={handleChange}
                                    evalData={{...scanReportData, lastModified: geojsonData?.lastModified}}
                                />
                            </ErrorBoundary>
                        )}
                        {isScanReportDataPending && <CircularProgress />}
                        {scanReportDataError && <Typography>Could not retrieve inspection details</Typography>}
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
};
