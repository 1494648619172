import pointsWithinPolygon from "@turf/points-within-polygon";
import GeoJSON from "geojson";
import type {MapScan, CleanMapScan} from "react_ct/types";

type MapScanType_Deprecated = CleanMapScan | MapScan;
/**
 *
 * @param scan {MapScan} The scan to draw lines based on
 * @returns {GeoJSON.Feature | null} A GeoJSON feature for the scan, returns null if coordinates are not provided
 */
export const getLineFeature = (
    scan: MapScanType_Deprecated,
    getFeatureColor: (scan: MapScanType_Deprecated) => string,
    multilineCoordsId: "gpsMultiline" | "adjustedGpsMultiline",
): GeoJSON.Feature | null => {
    if (!scan.gpsMultiline) {
        return null;
    }

    const uniqueMultilineCoords = Array.from(new Set(scan.gpsMultiline));
    if (uniqueMultilineCoords.length === 1) {
        return null;
    }

    const uniqueAdjustedCoords = Array.from(new Set(scan.adjustedGpsMultiline));

    // scanLengthConfirmed will be undefined for MapScan type; cast to boolean
    const isLengthConfirmed = Boolean((scan as unknown as CleanMapScan).scanLengthConfirmed);

    return {
        type: "Feature",
        properties: {
            id: scan.id,
            scannerId: scan.scannerId,
            lat: scan.lat,
            lng: scan.lng,
            folderName: scan.folderName,
            createdAt: scan.createdAt,
            stage: scan.stage,
            scanLength: scan.scanLength,
            scanLengthConfirmed: isLengthConfirmed,
            gpsMultiline: uniqueMultilineCoords,
            adjustedGpsMultiline: uniqueAdjustedCoords,
            color: getFeatureColor(scan),
        },
        geometry: {
            type: "LineString",
            coordinates:
                multilineCoordsId === "adjustedGpsMultiline" &&
                scan[multilineCoordsId] !== undefined &&
                scan[multilineCoordsId] !== null &&
                scan[multilineCoordsId]?.length
                    ? uniqueAdjustedCoords
                    : uniqueMultilineCoords,
        },
    };
};

/**
 *
 * @param scan {MapScan} The scan to draw points based on
 * @returns {GeoJSON.Feature | null} A GeoJSON feature for the scan, returns null if coordinates are not provided
 */
export const getPointFeature = (
    scan: MapScanType_Deprecated,
    getFeatureColor: (scan: MapScanType_Deprecated) => string,
): GeoJSON.Feature | null => {
    if (!scan.lat || !scan.lng) {
        return null;
    }

    // scanLengthConfirmed will be undefined for MapScan type; cast to boolean
    const isLengthConfirmed = Boolean((scan as unknown as CleanMapScan).scanLengthConfirmed);

    return {
        type: "Feature",
        properties: {
            id: scan.id,
            scannerId: scan.scannerId,
            lat: scan.lat,
            lng: scan.lng,
            folderName: scan.folderName,
            createdAt: scan.createdAt,
            stage: scan.stage,
            scanLength: scan.scanLength,
            scanLengthConfirmed: isLengthConfirmed,
            gpsMultiline: scan.gpsMultiline,
            adjustedGpsMultiline: scan.adjustedGpsMultiline,
            color: getFeatureColor(scan),
        },
        geometry: {
            type: "Point",
            coordinates: [scan.lng, scan.lat],
        },
    };
};

export const createGeoJSONFeatureCollection = (
    scans: MapScanType_Deprecated[] | undefined,
    getFeatureColor: (scan: MapScanType_Deprecated) => string,
    multilineCoordsId: "gpsMultiline" | "adjustedGpsMultiline" = "gpsMultiline",
): GeoJSON.FeatureCollection | undefined => {
    if (scans) {
        return {
            type: "FeatureCollection",
            features: scans
                .map(scan => {
                    const multiline =
                        scan[multilineCoordsId] === undefined ? scan.gpsMultiline : scan[multilineCoordsId];
                    const uniqueMultilineCoords = Array.from(new Set(multiline));
                    return uniqueMultilineCoords.length > 1
                        ? (getLineFeature(
                              scan,
                              getFeatureColor,
                              scan[multilineCoordsId] === undefined ? "gpsMultiline" : multilineCoordsId,
                          ) as GeoJSON.Feature)
                        : (getPointFeature(scan, getFeatureColor) as GeoJSON.Feature);
                })
                .filter(feature => feature !== null),
        };
    } else {
        return undefined;
    }
};

function createPolygonDataForFreeSelect(
    freeSelectRange: GeoJSON.Feature[],
): GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon, GeoJSON.GeoJsonProperties> {
    return freeSelectRange.length > 1
        ? {
              type: "Feature",
              geometry: {
                  type: "MultiPolygon",
                  coordinates: freeSelectRange.map(range => (range.geometry as GeoJSON.Polygon).coordinates),
              },
              properties: {},
          }
        : (freeSelectRange[0] as GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>);
}

export function getOverlappingLinesInFreeSelect(
    freeSelectRange: GeoJSON.Feature[],
    data: GeoJSON.FeatureCollection<GeoJSON.LineString, GeoJSON.GeoJsonProperties> | undefined,
): Array<GeoJSON.Feature<GeoJSON.LineString, GeoJSON.GeoJsonProperties>> {
    if (!freeSelectRange.length) {
        return [];
    }
    const selectedFeatures: Array<GeoJSON.Feature<GeoJSON.LineString, GeoJSON.GeoJsonProperties>> = [];
    const polygonRange: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon, GeoJSON.GeoJsonProperties> =
        createPolygonDataForFreeSelect(freeSelectRange);
    data?.features.forEach(feature => {
        const multiPointFeature: GeoJSON.Feature<GeoJSON.MultiPoint, GeoJSON.GeoJsonProperties> = {
            type: "Feature",
            geometry: {
                type: "MultiPoint",
                coordinates: feature.geometry.coordinates,
            },
            properties: {},
        };

        const overlappingPoints = pointsWithinPolygon(multiPointFeature, polygonRange);
        if (overlappingPoints.features.length) {
            selectedFeatures.push(feature);
        }
    });
    return selectedFeatures;
}

export function getOverlappingPointsInFreeSelect(
    freeSelectRange: GeoJSON.Feature[],
    data: GeoJSON.FeatureCollection<GeoJSON.Point, GeoJSON.GeoJsonProperties> | undefined,
): Array<GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>> {
    if (!freeSelectRange.length) {
        return [];
    }
    const selectedFeatures: Array<GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>> = [];
    const polygonRange: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon, GeoJSON.GeoJsonProperties> =
        createPolygonDataForFreeSelect(freeSelectRange);
    data?.features.forEach(feature => {
        if (pointsWithinPolygon(feature, polygonRange).features.length) {
            selectedFeatures.push(feature);
        }
    });
    return selectedFeatures;
}

export function areCoordinatesEqual(pairA: GeoJSON.Position, pairB: GeoJSON.Position): boolean {
    const areXCoordsEqual = pairA[0] === pairB[0];
    const areYCoordsEqual = pairA[1] === pairB[1];
    return areXCoordsEqual && areYCoordsEqual;
}
