import React from "react";
import GeoJSON from "geojson";
import {Box, Collapse, IconButton, Menu, MenuItem, Tooltip, Typography} from "@mui/material";
import {FileUpload} from "@mui/icons-material";
import {DashboardView} from "pages/portal/Home";
import {colors} from "react_ct/theme";

export default function ChooseDashboardRegion({
    savedViews,
    freeSelectRange,
    setFreeSelectRange,
}: {
    savedViews: DashboardView[];
    freeSelectRange: GeoJSON.Feature[];
    setFreeSelectRange: React.Dispatch<React.SetStateAction<GeoJSON.Feature[]>>;
}): React.ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = !!anchorEl;
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };
    return (
        <Box position="absolute" right={0} top={80} zIndex={5} mr={2}>
            <Tooltip title="Import saved region" placement="left" arrow>
                <IconButton
                    id="selected-region-button"
                    aria-controls={open ? "selected-region-menu" : undefined}
                    aria-haspopup={true}
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    sx={theme => ({
                        backgroundColor: theme.palette.background.paper,
                        boxShadow: theme.shadows[3],
                        borderRadius: theme.shape.borderRadius / 2,
                    })}>
                    <FileUpload />
                </IconButton>
            </Tooltip>
            <Collapse in={open}>
                <Menu
                    id="selected-region-menu"
                    onClose={handleClose}
                    {...{anchorEl, open}}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    MenuListProps={{"aria-labelledby": "selected-region-button"}}>
                    <Typography color={colors.darkGray} fontSize="0.8rem" pl={2}>
                        Import Saved Region
                    </Typography>
                    {savedViews.map(view => (
                        <MenuItem
                            key={view.id}
                            onClick={() => {
                                if (view.polygonSelectCoordinates) {
                                    const maxPolygonId = Math.max(
                                        ...freeSelectRange.map(polygon => polygon.properties?.id ?? 0),
                                    );
                                    const polygonFeatures: GeoJSON.Feature[] = view.polygonSelectCoordinates.map(
                                        (coord, index) => ({
                                            type: "Feature",
                                            geometry: {
                                                type: "Polygon",
                                                coordinates: coord,
                                            },
                                            properties: {
                                                index: maxPolygonId + index + 1,
                                            },
                                        }),
                                    );
                                    setFreeSelectRange(prev => [...prev, ...polygonFeatures]);
                                }
                                setAnchorEl(null);
                            }}>
                            {view.name}
                        </MenuItem>
                    ))}
                </Menu>
            </Collapse>
        </Box>
    );
}
