import {Box} from "@mui/material";
import React from "react";

export default function CroppedImage({
    url,
    centroid,
    containerRef,
}: {
    url: string;
    centroid: number[];
    containerRef: React.MutableRefObject<HTMLDivElement | null>;
}): React.ReactElement {
    const centroidX = (containerRef.current?.offsetWidth ?? 0) / 2 - centroid[0];
    const centroidY = (containerRef.current?.offsetHeight ?? 0) / 2 - centroid[1];

    return (
        <Box
            width="100%"
            height="100%"
            sx={{
                backgroundImage: `url("${url}")`,
                backgroundColor: "black",
                backgroundPosition: `${Math.round(centroidX)}px ${Math.round(centroidY)}px`,
                backgroundRepeat: "no-repeat",
                marginTop: "0px !important",
            }}></Box>
    );
}
