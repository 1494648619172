import GeoJSON from "geojson";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    Button,
    Alert,
} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import type {ImplementProgram} from "react_ct/types";
import {useProject} from "contexts/ProjectContext";
import {apiRequest} from "react_ct/requests";
import type {ProgramOutput} from "pages/portal/implement/types";

interface ReviewProgramModalProps {
    selectedFeatures: GeoJSON.Feature[];
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
    programNameValue: string | undefined;
    setProgramName: (value: string | undefined) => void;
    programType: string;
    programId?: number;
}

export default function ReviewProgramModal({
    openModal,
    setOpenModal,
    selectedFeatures,
    programNameValue,
    setProgramName,
    programType,
    programId,
}: ReviewProgramModalProps): React.ReactElement {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {currentProject} = useProject();

    const programNameRef = React.useRef<HTMLInputElement>(null);

    const [modalAlert, setModalAlert] = React.useState<string>();

    const createProgramMutation = useMutation({
        mutationFn: async (newProgram: ProgramOutput) => {
            if (!newProgram.name?.length) {
                throw new Error("Please enter a name for the program.");
            }
            const path = `program${programId ? `/${programId}` : ""}`;
            const response = await apiRequest({
                path,
                method: programId ? "PUT" : "POST",
                data: newProgram,
                params: {isDraft: true, projectId: currentProject?.id},
            });
            return response.data;
        },
        onSuccess: async (data: ImplementProgram) => {
            void queryClient.invalidateQueries({
                queryKey: ["getPrograms", currentProject?.id],
            });
            if (data.id) {
                navigate(
                    `/portal/implement/create/${data.programType?.replaceAll("_", "") ?? "obstruction"}/review?id=${
                        data.id
                    }`,
                    {
                        state: {selectedFeatures},
                    },
                );
            } else {
                throw new Error("Could not retrieve new program ID");
            }
        },
        onError: (error: Error) => {
            setModalAlert(`Could not create program: ${error.message}`);
        },
    });

    function onSubmitModal(): void {
        setModalAlert(undefined);
        if (programNameRef.current) {
            const newProgram = {
                name: programNameRef.current.value,
                programType,
                features: selectedFeatures.map(feature => ({
                    featureType: feature.properties?.type as string,
                    gpsCoordinate: (feature.geometry as GeoJSON.Point).coordinates as number[],
                    severity: feature.properties?.max_severity ?? undefined,
                    scanId: feature.properties?.scan_id,
                    address: feature.properties?.address,
                    reviewed: false,
                    accepted: false,
                })),
            };
            createProgramMutation.mutate(newProgram);
        }
    }

    function onCancelModal(): void {
        setOpenModal(false);
        setModalAlert(undefined);
    }

    return (
        <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            PaperProps={{
                sx: {
                    width: "50vw",
                },
            }}>
            <DialogTitle component="h3" variant="h3" fontSize="2rem" p={2}>
                Review Program
            </DialogTitle>
            <DialogContent>
                <DialogContentText color="primary">Give a name for the new program.</DialogContentText>
                {modalAlert && (
                    <Alert
                        color="error"
                        severity="error"
                        sx={{
                            mt: 1,
                        }}>
                        {modalAlert}
                    </Alert>
                )}
                <TextField
                    inputRef={programNameRef}
                    value={programNameValue ?? ""}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Program Name"
                    type="text"
                    fullWidth
                    onChange={e => setProgramName(e.target.value)}
                    sx={{
                        mt: 2,
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" disableElevation onClick={onCancelModal}>
                    Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={onSubmitModal}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}
