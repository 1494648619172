import React from "react";
import type {
    IndividualFeatureProperties,
    MapScan,
    Material,
    PanelFeature,
    ReportType,
    ViolationCount,
} from "react_ct/types";
import {DashboardView, GridItem} from "../Home";
import {
    Box,
    Button,
    Chip,
    Grid,
    Paper,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    chipClasses,
    darken,
    rgbToHex,
} from "@mui/material";
import {colors} from "react_ct/theme";
import {Gauge, PieChart, gaugeClasses, useDrawingArea} from "@mui/x-charts";
import DWMap from "components/Map/DWMap";
import LoadingScreen from "components/LoadingScreen";
import {ArrowDownward, ArrowUpward, Close} from "@mui/icons-material";
import {scoreToColor} from "../data/components/components";
import HorizontalBarChart, {type ChartDataProps} from "components/util/HorizontalBarChart";
import {tableContents} from "../data/components/constants";
import {getOverlappingLinesInFreeSelect} from "components/Map/helpers";
import {capitalizeFirstLetterOfEachWord} from "helpers/utils";
import {useProject} from "contexts/ProjectContext";
import GeoJSON from "geojson";

const materialToColor: Record<Material | "Panel", string> = {
    Asphalt: colors.lightBlue,
    Brick: colors.deepWalkBlue,
    Concrete: colors.blue,
    Panel: colors.blue,
    Cracked: colors.orange,
    Deteriorated: colors.yellow,
    Driveway: colors.lightBlue,
    Gravel: rgbToHex(darken(colors.gray, 0.3)),
    Gutter: colors.purple,
    Obstructed: colors.red,
    Stairs: rgbToHex(darken(colors.lightGray, 0.2)),
};

function crossSlopeToColor(violationList: ViolationCount): string {
    if (violationList.extreme > 0) {
        return colors.black;
    } else if (violationList.severe > 0) {
        return colors.red;
    } else if (violationList.moderate > 0) {
        return colors.orange;
    } else if (violationList.minor > 0) {
        return colors.yellow;
    } else {
        return colors.green;
    }
}

function runSlopeToColor(rs: number): string {
    return rs < 5 ? colors.green : rs < 6 ? colors.yellow : rs < 7 ? colors.orange : rs < 8 ? colors.red : colors.black;
}

function clearWidthToColor(cw: number): string {
    return cw < 36 ? colors.black : cw < 44 ? colors.red : cw < 50 ? colors.blue : cw < 62 ? colors.green : colors.pink;
}

function accessibilityGradeToColor(grade: number): string {
    if (grade >= 0 && grade < 1) {
        return colors.black;
    } else if (grade >= 1 && grade < 2) {
        return colors.darkRed;
    } else if (grade >= 2 && grade < 3) {
        return colors.red;
    } else if (grade >= 3 && grade < 4) {
        return colors.orange;
    } else if (grade >= 4 && grade < 5) {
        return colors.yellow;
    } else {
        return colors.green;
    }
}

function deteriorationToColor(percentage: number | null): string {
    if (percentage === null) {
        return colors.gray;
    } else if (percentage === 0) {
        return colors.green;
    } else if (percentage > 0 && percentage <= 25) {
        return colors.yellow;
    } else if (percentage > 25 && percentage <= 50) {
        return colors.orange;
    } else if (percentage > 50 && percentage <= 75) {
        return colors.red;
    } else {
        return colors.black;
    }
}

function PieCenterLabel({children}: {children: React.ReactNode}): React.ReactElement {
    const {height, left, top} = useDrawingArea();
    return (
        <text x={50 + left / 2} y={top + height / 2} fontSize="2rem" fontWeight={600} fill={colors.darkBlue}>
            {children}
        </text>
    );
}

const mapColoringOptions = ["accessibility", "runSlope", "crossSlope", "clearWidth", "deterioration"];
type MapColoring = (typeof mapColoringOptions)[number];

const mapColoringNames: Record<MapColoring, string> = Object.freeze({
    accessibility: "Accessibility",
    runSlope: "Run Slope",
    crossSlope: "Cross Slope",
    clearWidth: "Clear Width",
    deterioration: "Deterioration",
});

export default function MileageDashboard({
    baseGeojson,
    obsGeojson,
    indvGeojson,
    filterOptions,
    baseDataError,
    projectScans,
    projectScansError,
    setCurrentMapFeatures,
    currentDashboardView,
    freeSelectRange,
    setFreeSelectRange,
}: {
    baseGeojson: GeoJSON.FeatureCollection<GeoJSON.LineString, ReportType> | undefined;
    obsGeojson: GeoJSON.FeatureCollection<GeoJSON.Point | GeoJSON.LineString, PanelFeature> | undefined;
    indvGeojson: GeoJSON.FeatureCollection<GeoJSON.Point, IndividualFeatureProperties> | undefined;
    filterOptions: "midblock" | "curb ramp";
    baseDataError: Error | null;
    projectScans: MapScan[] | undefined;
    projectScansError: string | undefined;
    setCurrentMapFeatures: React.Dispatch<React.SetStateAction<Array<GeoJSON.Feature<GeoJSON.LineString, ReportType>>>>;
    currentDashboardView: DashboardView | undefined;
    freeSelectRange: GeoJSON.Feature[];
    setFreeSelectRange: React.Dispatch<React.SetStateAction<GeoJSON.Feature[]>>;
}): React.ReactElement {
    const {projectManualTags} = useProject();

    const [currentMapColoring, setCurrentMapColoring] = React.useState<MapColoring>("accessibility");
    const [graphUnits, setGraphUnits] = React.useState<"feet" | "miles" | "sq feet">("feet");

    const geojsonWithColors = React.useMemo(() => {
        if (!baseGeojson) {
            return undefined;
        }
        return {
            ...baseGeojson,
            features: baseGeojson.features.map(feature => {
                const featureColor =
                    currentMapColoring === "accessibility"
                        ? scoreToColor(feature.properties)
                        : currentMapColoring === "crossSlope"
                          ? crossSlopeToColor(feature.properties.violations.cross_slope)
                          : currentMapColoring === "runSlope"
                            ? runSlopeToColor(feature.properties.measurement_averages.run_slope ?? 0)
                            : currentMapColoring === "clearWidth"
                              ? clearWidthToColor(feature.properties.measurement_averages.width ?? 0)
                              : deteriorationToColor(feature.properties.percent_deteriorated);
                return {
                    ...feature,
                    properties: {
                        ...feature.properties,
                        color: featureColor,
                    },
                };
            }),
        };
    }, [baseGeojson, currentMapColoring]);

    const freeSelectedFeatures: Array<GeoJSON.Feature<GeoJSON.LineString, ReportType>> = React.useMemo(
        () =>
            getOverlappingLinesInFreeSelect(freeSelectRange, geojsonWithColors) as Array<
                GeoJSON.Feature<GeoJSON.LineString, ReportType>
            >,
        [freeSelectRange],
    );

    React.useEffect(() => {
        setCurrentMapFeatures(freeSelectedFeatures);
    }, [freeSelectedFeatures]);

    const filteredGeojsonData: GeoJSON.FeatureCollection<GeoJSON.LineString, ReportType> | undefined =
        React.useMemo(() => {
            if (!geojsonWithColors) {
                return undefined;
            }
            return {
                ...geojsonWithColors,
                features: geojsonWithColors.features.filter(feature => {
                    const midblockEnum: Record<string, string> = {
                        "No DW Necessary": "midblock",
                        "Truncated Domes": "curb ramp",
                        "Missing Detectable Warning": "curb ramp",
                    };
                    return filterOptions === "midblock"
                        ? true
                        : filterOptions === midblockEnum[feature.properties.detectable_warning];
                }),
            };
        }, [geojsonWithColors, filterOptions]);

    const filteredObsData: GeoJSON.FeatureCollection<GeoJSON.Point | GeoJSON.LineString, PanelFeature> | undefined =
        React.useMemo(() => {
            if (!obsGeojson) {
                return undefined;
            }
            return {
                ...obsGeojson,
                features: obsGeojson.features.filter(
                    feature =>
                        freeSelectedFeatures?.some(f => f.properties?.metadata.id === feature.properties?.scan_id) ??
                        true,
                ),
            };
        }, [freeSelectedFeatures, obsGeojson]);

    const filteredManualTagData: GeoJSON.FeatureCollection<GeoJSON.Point, IndividualFeatureProperties> | undefined =
        React.useMemo(() => {
            if (!indvGeojson) {
                return undefined;
            }
            return {
                ...indvGeojson,
                features: indvGeojson.features
                    .filter(feature =>
                        freeSelectedFeatures?.length
                            ? freeSelectedFeatures?.some(f => f.properties.metadata.id === feature.properties.scan_id)
                            : true,
                    )
                    .filter(feature => {
                        const correspondingManualTag = projectManualTags?.find(
                            tag => tag.name.toLowerCase() === feature.properties.type,
                        );

                        if (!correspondingManualTag) {
                            return false;
                        }
                        return !correspondingManualTag.archived;
                    }),
            };
        }, [freeSelectedFeatures, indvGeojson]);

    const dataToUse: Array<GeoJSON.Feature<GeoJSON.LineString, ReportType>> = freeSelectedFeatures.length
        ? freeSelectedFeatures
        : filteredGeojsonData
          ? filteredGeojsonData.features
          : [];

    const panelFeaturesCount: Record<string, number> | null = React.useMemo(() => {
        const initial: Partial<Record<string, number>> = {};
        if (!filteredManualTagData) {
            return null;
        }

        return filteredManualTagData.features.reduce((total, current) => {
            if (!Object.keys(total).includes(current.properties.type)) {
                total[current.properties.type] = 1;
            } else {
                (total[current.properties.type] as number) += 1;
            }

            return total;
        }, initial) as Record<string, number>;
    }, [filteredManualTagData]);

    const totalMileage: number = React.useMemo(() => {
        if (!dataToUse.length) {
            return 0;
        }
        const totalFeet = dataToUse.reduce(
            (total, current) => Number(total) + Number(current.properties.metadata?.length),
            0,
        );
        return totalFeet / 5280;
    }, [dataToUse]);

    const validMaterials = [
        "Asphalt",
        "Brick",
        "Panel",
        "Cracked",
        "Deteriorated",
        "Driveway",
        "Gravel",
        "Gutter",
        "Stairs",
    ];
    type ValidMaterial = (typeof validMaterials)[number];

    const scanToArea = React.useMemo(() => {
        const combinedArea = dataToUse.map(data => {
            const areas = Object.entries(data.properties.areas)
                .filter(([material, area]) => validMaterials.includes(material))
                .reduce((acc, current) => acc + current[1], 0);

            return {
                ...data.properties,
                totalArea: areas,
            };
        }, 0);

        return combinedArea;
    }, [dataToUse]);

    const areaByMaterial: Record<Material, number> = React.useMemo(() => {
        const initial: Record<ValidMaterial, number> = {
            Asphalt: 0,
            Brick: 0,
            Panel: 0,
            Cracked: 0,
            Deteriorated: 0,
            Driveway: 0,
            Gravel: 0,
            Gutter: 0,
            Stairs: 0,
        };

        if (!dataToUse.length) {
            return initial;
        }
        return dataToUse.reduce((accumulated, current) => {
            Object.entries(current.properties.areas)
                .filter(([material]) => validMaterials.includes(material))
                .forEach(([material, value]) => {
                    accumulated[material] += Number(value);
                });
            return accumulated;
        }, initial);
    }, [dataToUse]);

    const totalAreaByMaterial: number = React.useMemo(
        () => Math.round(Object.values(areaByMaterial).reduce((total, current) => total + current, 0)),
        [areaByMaterial],
    );

    const mostPrevalentMaterial: Material = React.useMemo(() => {
        const largestAreaIndex = Object.values(areaByMaterial).indexOf(Math.max(...Object.values(areaByMaterial)));
        return Object.keys(areaByMaterial)[largestAreaIndex];
    }, [areaByMaterial]);

    const averageAccessibilityGrade = React.useMemo(() => {
        if (!dataToUse) {
            return 0;
        }
        const totalGrade = dataToUse.reduce((total, current) => total + current.properties.accessibility_grade, 0);

        return totalGrade / dataToUse.length;
    }, [dataToUse]);

    const countByAccessibilityGrade = React.useMemo(() => {
        if (!dataToUse) {
            return undefined;
        }
        const initial = [0, 0, 0, 0, 0, 0];
        const count = [0, 0, 0, 0, 0, 0];

        for (let i = 0; i < initial.length; i++) {
            const filteredData = dataToUse.filter(data => data.properties.accessibility_grade === i);
            const sum = filteredData.reduce((total, scan) => total + scan.properties.metadata.length, 0);
            count[i] = sum;
        }

        const area = [0, 0, 0, 0, 0];

        for (let i = 0; i < initial.length; i++) {
            const filteredData = dataToUse.filter(data => data.properties.accessibility_grade === i);
            const sum = scanToArea
                .filter(scan => !!filteredData.find(d => d.properties.metadata.id === scan.metadata.id))
                .reduce((total, scan) => total + scan.totalArea, 0);
            area[i] = sum;
        }

        const unitsLength =
            graphUnits === "miles"
                ? count.map(c => ((c / 5280) * 10) / 10)
                : graphUnits === "sq feet"
                  ? area.map(a => Math.round(a))
                  : count.map(c => Math.round(c));

        return unitsLength;
    }, [dataToUse, graphUnits]);

    const averageDeteriorationPercentage: number | null = React.useMemo(() => {
        if (!dataToUse) {
            return null;
        }
        const totalPercentage = dataToUse.reduce(
            (total, current) => total + current.properties.percent_deteriorated,
            0,
        );

        return totalPercentage / dataToUse.length;
    }, [filteredGeojsonData]);

    const averageRecommendedFixes: Array<{type: string; avg: number}> | null = React.useMemo(() => {
        if (!dataToUse) {
            return null;
        }
        const tripHazardFixesAvg =
            dataToUse
                .filter(feature => feature.properties.fixes?.vd_fix)
                .reduce((total, current) => Number(total) + Number(current.properties.fixes?.vd_fix), 0) /
            dataToUse.filter(feature => feature.properties.fixes?.vd_fix).length;
        const vegetationFixAvg =
            dataToUse
                .filter(feature => feature.properties.fixes?.width_fix)
                .reduce((total, current) => Number(total) + Number(current.properties.fixes?.width_fix), 0) /
            dataToUse.filter(feature => feature.properties.fixes?.width_fix).length;
        const dwFixAvg =
            dataToUse
                .filter(feature => feature.properties.fixes?.dw_fix)
                .reduce((total, current) => Number(total) + Number(current.properties.fixes?.dw_fix), 0) /
            dataToUse.filter(feature => feature.properties.fixes?.dw_fix).length;

        return [
            {
                type: "trip_hazard_fix",
                avg: tripHazardFixesAvg,
            },
            {
                type: "vegetation_fix",
                avg: vegetationFixAvg,
            },
            {
                type: "dw_fix",
                avg: dwFixAvg,
            },
        ].sort((a, b) => b.avg - a.avg);
    }, [dataToUse]);

    const recFixKeyToName = {
        trip_hazard_fix: "Trip Hazard Removal",
        vegetation_fix: "Vegetation Removal",
        dw_fix: "Detectable Warning Retrofit",
    };

    const detectableWarningData =
        dataToUse.filter(
            feature =>
                feature.properties.detectable_warning.toLowerCase() === "truncated domes" ||
                feature.properties.detectable_warning.toLowerCase() === "missing detectable warning",
        ) ?? [];

    const dwCountByAccessibilityGrade = React.useMemo(() => {
        if (!detectableWarningData) {
            return undefined;
        }
        const initial = [0, 0, 0, 0, 0, 0];
        return detectableWarningData.reduce((total, current) => {
            total[current.properties.accessibility_grade] += 1;
            return total;
        }, initial);
    }, [detectableWarningData]);

    const accessibilityGradeGraphData: ChartDataProps[] | undefined = React.useMemo(() => {
        if (!countByAccessibilityGrade) {
            return undefined;
        }
        return countByAccessibilityGrade.map((count, grade) => {
            const accessibilityDescription =
                tableContents.find(content => content.id === grade)?.description ?? "No description available";
            return {
                key: grade,
                label: accessibilityDescription,
                value: count,
                color: accessibilityGradeToColor(grade),
                unit: graphUnits,
            };
        });
    }, [countByAccessibilityGrade, graphUnits]);

    const dwAccessibilityGradeGraphData: ChartDataProps[] | undefined = React.useMemo(() => {
        if (!dwCountByAccessibilityGrade) {
            return undefined;
        }
        return dwCountByAccessibilityGrade.map((count, grade) => {
            const accessibilityDescription =
                tableContents.find(content => content.id === grade)?.description ?? "No description available";
            return {
                key: grade,
                label: accessibilityDescription,
                value: count,
                color: accessibilityGradeToColor(grade),
            };
        });
    }, [dwCountByAccessibilityGrade]);

    const missingDWAmount =
        dataToUse.filter(
            feature => feature.properties.detectable_warning.toLowerCase() === "missing detectable warning",
        ).length ?? 0;

    const truncatedDomesAmount = detectableWarningData.length - missingDWAmount;

    const missingRampAmount = dataToUse.filter(feature => feature.properties.no_ramp).length ?? 0;

    const isMidblockDashboard = filterOptions.includes("midblock");

    if (geojsonWithColors && filteredGeojsonData && baseGeojson && projectScans) {
        return (
            <Stack gap={2} px={6} py={currentDashboardView ? 2 : 0} width="100%">
                <Stack direction="row" justifyContent="space-between" gap={2} width="100%">
                    <Paper sx={{position: "relative", flexGrow: 1, borderRadius: theme => theme.shape.borderRadius}}>
                        <Stack
                            direction="row"
                            gap={1}
                            sx={{position: "absolute", top: 0, left: 0, mt: 1, ml: 1, zIndex: 5}}>
                            {mapColoringOptions.map(colorOption => (
                                <Chip
                                    key={colorOption}
                                    label={mapColoringNames[colorOption]}
                                    size="small"
                                    color={currentMapColoring === colorOption ? "primary" : "default"}
                                    onClick={(): void => setCurrentMapColoring(colorOption)}
                                    sx={{
                                        [`&.${chipClasses.root}`]: {
                                            boxShadow: theme => theme.shadows[2],
                                        },
                                        [`&.${chipClasses.root}.${chipClasses.filledPrimary}`]: {
                                            backgroundColor: colors.lightBlue,
                                        },
                                        [`&.${chipClasses.root}.${chipClasses.filled}:not(.${chipClasses.filledPrimary})`]:
                                            {
                                                backgroundColor: theme => theme.palette.background.paper,
                                            },
                                    }}
                                />
                            ))}
                        </Stack>
                        {freeSelectedFeatures.length > 0 && !currentDashboardView && (
                            <Box position="absolute" top={0} right={0} mt={2} mr={8} zIndex={5}>
                                <Button
                                    color="error"
                                    startIcon={<Close />}
                                    onClick={(): void => setFreeSelectRange([])}
                                    variant="contained">
                                    Clear selection ({freeSelectedFeatures.length} features)
                                </Button>
                            </Box>
                        )}
                        <DWMap
                            data={filteredGeojsonData}
                            dataId="id"
                            borderRadius
                            freeSelectControl
                            onFreeSelect={(
                                features: Array<GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>>,
                            ): void => {
                                setFreeSelectRange(prev => {
                                    const filteredFeatures = prev.filter(
                                        p => !features.find(feature => feature.properties?.id === p.properties?.id),
                                    );
                                    return [...filteredFeatures, ...features];
                                });
                            }}
                            {...{freeSelectRange}}
                        />
                    </Paper>
                    <Stack gap={3} flexBasis="25%">
                        <Paper sx={{p: 4}}>
                            <Typography mb={1}>Average Accessibility Score</Typography>
                            <Gauge
                                value={Math.floor(averageAccessibilityGrade * 10) / 10}
                                height={100}
                                valueMin={0}
                                valueMax={5}
                                startAngle={-90}
                                endAngle={90}
                                sx={{
                                    [`& .${gaugeClasses.valueText}`]: {
                                        fontSize: "2.125rem",
                                        fontWeight: 400,
                                        transform: "translate(0px, -20px)",
                                    },
                                    [`& .${gaugeClasses.valueArc}`]: {
                                        fill: accessibilityGradeToColor(averageAccessibilityGrade),
                                    },
                                }}
                            />
                        </Paper>
                        <Paper sx={{p: 4}}>
                            {isMidblockDashboard ? (
                                <>
                                    <Typography mb={1}>Average Deterioration Percentage</Typography>
                                    <Typography variant="h4">
                                        {averageDeteriorationPercentage !== null
                                            ? Math.round(averageDeteriorationPercentage * 10) / 10
                                            : "--"}
                                        %
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography mb={1}>Missing Detectable Warnings</Typography>
                                    <Typography variant="h4">{missingDWAmount} missing</Typography>
                                </>
                            )}
                        </Paper>
                    </Stack>
                </Stack>
                <Grid container columnSpacing={{xs: 2}} rowSpacing={{xs: 2}}>
                    <GridItem xs={isMidblockDashboard ? 6 : 9}>
                        <Stack direction="row" gap={2} justifyContent="space-between">
                            <Typography mb={1}>
                                {isMidblockDashboard
                                    ? `Sidewalk Grade Distribution (by ${
                                          graphUnits === "sq feet" ? "area" : "length"
                                      }, in ${graphUnits})`
                                    : "Ramp Grade Distribution"}
                            </Typography>
                            {isMidblockDashboard && (
                                <ToggleButtonGroup
                                    exclusive
                                    size="small"
                                    value={graphUnits}
                                    onChange={(event: React.MouseEvent<HTMLElement>, newVal: string) =>
                                        setGraphUnits(newVal as "feet" | "miles" | "sq feet")
                                    }>
                                    <ToggleButton value="feet" key="feet">
                                        Feet
                                    </ToggleButton>
                                    <ToggleButton value="miles" key="miles">
                                        Miles
                                    </ToggleButton>
                                    <ToggleButton value="sq feet" key="sq feet">
                                        Square Feet
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            )}
                        </Stack>
                        <Box width="100%">
                            {accessibilityGradeGraphData && dwAccessibilityGradeGraphData && (
                                <HorizontalBarChart
                                    data={
                                        isMidblockDashboard
                                            ? accessibilityGradeGraphData
                                            : dwAccessibilityGradeGraphData
                                    }
                                />
                            )}
                        </Box>
                    </GridItem>
                    {!isMidblockDashboard && (
                        <GridItem xs={3} title="Missing Ramps">
                            <Typography variant="h4">{missingRampAmount} missing</Typography>
                        </GridItem>
                    )}
                    {isMidblockDashboard && (
                        <GridItem xs={3} title="Total Mileage">
                            <Tooltip title={`${totalMileage} Miles`}>
                                <Typography variant="h4">
                                    {new Intl.NumberFormat().format(Math.floor(totalMileage * 10) / 10)} Miles
                                </Typography>
                            </Tooltip>
                        </GridItem>
                    )}
                    {isMidblockDashboard && panelFeaturesCount && (
                        <GridItem xs={3} title="Manual Tags">
                            <Stack gap={2}>
                                {Object.keys(panelFeaturesCount) ? (
                                    Object.entries(panelFeaturesCount).map(([feature, count]) => {
                                        const correspondingManualTag = projectManualTags?.find(
                                            mt => mt.name.toLowerCase() === feature,
                                        );
                                        return (
                                            <Stack
                                                key={feature}
                                                direction="row"
                                                gap="0.4rem"
                                                sx={{
                                                    backgroundColor:
                                                        String(correspondingManualTag?.color) + "33" ?? "#00000033",
                                                    px: 2,
                                                    py: 0.5,
                                                    borderRadius: "50px",
                                                }}>
                                                <Typography fontWeight="bold">
                                                    {new Intl.NumberFormat().format(count)}
                                                </Typography>
                                                <Typography>{feature}s</Typography>
                                            </Stack>
                                        );
                                    })
                                ) : (
                                    <Typography variant="h5">No panel features available</Typography>
                                )}
                            </Stack>
                        </GridItem>
                    )}
                    <GridItem
                        xs={2}
                        title={isMidblockDashboard ? "Total Area Processed" : "Total Number of Curb Ramps"}>
                        <Typography variant="h4">
                            {isMidblockDashboard
                                ? `${new Intl.NumberFormat().format(totalAreaByMaterial)} sf`
                                : `${detectableWarningData.length} Curb Ramps`}
                        </Typography>
                    </GridItem>
                    <GridItem
                        xs={4}
                        title={
                            isMidblockDashboard && mostPrevalentMaterial
                                ? `Area of ${capitalizeFirstLetterOfEachWord(
                                      mostPrevalentMaterial === "Panel" ? "Concrete" : (mostPrevalentMaterial ?? ""),
                                  )}`
                                : "Truncated Domes Amount"
                        }>
                        {mostPrevalentMaterial && (
                            <Stack direction="row" alignItems="center" gap={3}>
                                <Box flexGrow={1}>
                                    <Typography variant="h4">
                                        {isMidblockDashboard
                                            ? `${new Intl.NumberFormat().format(
                                                  Math.round(areaByMaterial[mostPrevalentMaterial]),
                                              )} sf`
                                            : `${truncatedDomesAmount} Detectable Warnings`}
                                    </Typography>
                                </Box>
                                <PieChart
                                    series={[
                                        {
                                            cx: 75,
                                            cy: 65,
                                            outerRadius: 70,
                                            innerRadius: 50,
                                            data: isMidblockDashboard
                                                ? [
                                                      {
                                                          id: mostPrevalentMaterial,
                                                          label:
                                                              mostPrevalentMaterial === "Panel"
                                                                  ? "Concrete"
                                                                  : mostPrevalentMaterial,
                                                          value: areaByMaterial[mostPrevalentMaterial],
                                                          color: materialToColor[mostPrevalentMaterial],
                                                      },
                                                      {
                                                          id: "others",
                                                          label: "Other",
                                                          value:
                                                              totalAreaByMaterial -
                                                              areaByMaterial[mostPrevalentMaterial] -
                                                              areaByMaterial.Gutter,
                                                          color: "transparent",
                                                      },
                                                  ]
                                                : [
                                                      {
                                                          id: "present",
                                                          label: "Truncated Domes",
                                                          value: truncatedDomesAmount,
                                                          color: colors.darkBlue,
                                                      },
                                                      {
                                                          id: "missing",
                                                          label: "Missing DWs",
                                                          value: missingDWAmount,
                                                          color: "transparent",
                                                      },
                                                  ],
                                        },
                                    ]}
                                    width={150}
                                    height={150}
                                    slotProps={{
                                        legend: {hidden: true},
                                    }}>
                                    <PieCenterLabel>
                                        {isMidblockDashboard
                                            ? new Intl.NumberFormat().format(
                                                  Math.floor(
                                                      (areaByMaterial[mostPrevalentMaterial] * 100) /
                                                          (totalAreaByMaterial - areaByMaterial.Gutter),
                                                  ),
                                              )
                                            : new Intl.NumberFormat().format(
                                                  Math.floor(
                                                      (truncatedDomesAmount * 100) / detectableWarningData.length,
                                                  ),
                                              )}
                                        %
                                    </PieCenterLabel>
                                </PieChart>
                            </Stack>
                        )}
                    </GridItem>
                    <GridItem
                        xs
                        title={isMidblockDashboard ? "Area of Other Materials" : "Detectable Warning Breakdown"}>
                        {isMidblockDashboard ? (
                            <Typography variant="h4">
                                {new Intl.NumberFormat().format(
                                    Math.round(
                                        totalAreaByMaterial -
                                            areaByMaterial[mostPrevalentMaterial] -
                                            areaByMaterial.Gutter,
                                    ),
                                )}{" "}
                                sf
                            </Typography>
                        ) : (
                            <></>
                        )}
                        <Box mt={2} width="100%">
                            <Stack direction="row" sx={{width: "100%", height: "40px"}}>
                                {isMidblockDashboard
                                    ? Object.keys(areaByMaterial)
                                          .filter(
                                              material =>
                                                  material !== mostPrevalentMaterial &&
                                                  material !== "obstructed" &&
                                                  material !== "gutter",
                                          )
                                          .sort((a, b) => areaByMaterial[b] - areaByMaterial[a])
                                          .map(material => {
                                              const formattedMaterialName =
                                                  material[0].toUpperCase() + material.slice(1);
                                              return (
                                                  <Tooltip
                                                      key={material}
                                                      placement="top"
                                                      title={`${formattedMaterialName}: ${new Intl.NumberFormat().format(
                                                          areaByMaterial[material],
                                                      )} sf`}>
                                                      <Box
                                                          width={`${
                                                              (areaByMaterial[material] * 100) /
                                                              (totalAreaByMaterial -
                                                                  areaByMaterial[mostPrevalentMaterial] -
                                                                  areaByMaterial.Gutter)
                                                          }%`}
                                                          height="100%"
                                                          sx={{backgroundColor: materialToColor[material]}}
                                                      />
                                                  </Tooltip>
                                              );
                                          })
                                    : ["Truncated Domes", "Missing Detectable Warning", "No DW Necessary"].map(
                                          dwStatus => {
                                              const value =
                                                  dwStatus === "Truncated Domes"
                                                      ? truncatedDomesAmount
                                                      : dwStatus === "Missing Detectable Warning"
                                                        ? missingDWAmount
                                                        : filteredGeojsonData.features.length -
                                                          detectableWarningData.length;

                                              return (
                                                  <Tooltip
                                                      key={dwStatus}
                                                      placement="top"
                                                      title={`${dwStatus}: ${value}`}>
                                                      <Box
                                                          width={`${
                                                              (value / filteredGeojsonData.features.length) * 100
                                                          }%`}
                                                          height="100%"
                                                          sx={{
                                                              backgroundColor:
                                                                  dwStatus === "Truncated Domes"
                                                                      ? colors.green
                                                                      : dwStatus === "Missing Detectable Warning"
                                                                        ? colors.red
                                                                        : colors.darkBlue,
                                                          }}
                                                      />
                                                  </Tooltip>
                                              );
                                          },
                                      )}
                            </Stack>
                            <Box mt={2}>
                                <Grid container rowSpacing={{xs: 1}} columnSpacing={{xs: 2}}>
                                    {isMidblockDashboard
                                        ? Object.keys(areaByMaterial)
                                              .filter(
                                                  material =>
                                                      material !== mostPrevalentMaterial &&
                                                      material !== "obstructed" &&
                                                      material !== "gutter" &&
                                                      areaByMaterial[material] > 0,
                                              )
                                              .sort((a, b) => areaByMaterial[b] - areaByMaterial[a])
                                              .map(material => {
                                                  const formattedMaterialName =
                                                      material[0].toUpperCase() + material.slice(1);
                                                  return (
                                                      <Grid item key={material}>
                                                          <Tooltip
                                                              placement="top"
                                                              title={`${new Intl.NumberFormat().format(
                                                                  areaByMaterial[material],
                                                              )} sf`}>
                                                              <Stack
                                                                  direction="row"
                                                                  gap={1}
                                                                  width="100%"
                                                                  alignItems="center"
                                                                  sx={{
                                                                      backgroundColor: materialToColor[material] + "33",
                                                                      color: materialToColor[material],
                                                                      px: 1,
                                                                      py: 0.5,
                                                                      borderRadius: 50,
                                                                  }}>
                                                                  <Box
                                                                      width={12}
                                                                      height={12}
                                                                      borderRadius="50%"
                                                                      sx={{backgroundColor: materialToColor[material]}}
                                                                  />
                                                                  <Typography fontSize="0.8rem">
                                                                      {formattedMaterialName}
                                                                  </Typography>
                                                              </Stack>
                                                          </Tooltip>
                                                      </Grid>
                                                  );
                                              })
                                        : ["Truncated Domes", "Missing Detectable Warning", "No DW Necessary"].map(
                                              dwStatus => {
                                                  const color =
                                                      dwStatus === "Truncated domes"
                                                          ? colors.green
                                                          : dwStatus === "Missing Detectable Warning"
                                                            ? colors.red
                                                            : colors.blue;
                                                  const value =
                                                      dwStatus === "Truncated domes"
                                                          ? truncatedDomesAmount
                                                          : dwStatus === "Missing Detectable Warning"
                                                            ? missingDWAmount
                                                            : filteredGeojsonData.features.length -
                                                              detectableWarningData.length;
                                                  return (
                                                      <Grid item key={dwStatus}>
                                                          <Tooltip
                                                              placement="top"
                                                              title={`${new Intl.NumberFormat().format(value)}`}>
                                                              <Stack
                                                                  direction="row"
                                                                  gap={1}
                                                                  width="100%"
                                                                  alignItems="center"
                                                                  sx={{
                                                                      backgroundColor: color + "33",
                                                                      color,
                                                                      px: 1,
                                                                      py: 0.5,
                                                                      borderRadius: 50,
                                                                  }}>
                                                                  <Box
                                                                      width={12}
                                                                      height={12}
                                                                      borderRadius="50%"
                                                                      sx={{backgroundColor: color}}
                                                                  />
                                                                  <Typography fontSize="0.8rem">{dwStatus}</Typography>
                                                              </Stack>
                                                          </Tooltip>
                                                      </Grid>
                                                  );
                                              },
                                          )}
                                </Grid>
                            </Box>
                        </Box>
                    </GridItem>
                </Grid>
                {averageRecommendedFixes && (
                    <Paper sx={{p: 2}}>
                        <Typography mb={1}>Recommended Programs</Typography>
                        <Stack gap={1}>
                            {averageRecommendedFixes.map(fix => (
                                <Stack key={fix.type} direction="row" gap={2}>
                                    <Typography fontWeight="bold" variant="h5">
                                        {recFixKeyToName[fix.type as keyof typeof recFixKeyToName]}
                                    </Typography>
                                    <Stack direction="row" gap={1}>
                                        <Typography variant="h5">
                                            {new Intl.NumberFormat().format(Math.floor(fix.avg * 10) / 10)}
                                        </Typography>
                                        <Stack direction="row" alignItems="flex-start">
                                            {fix.avg - averageAccessibilityGrade !== 0 &&
                                                (fix.avg - averageAccessibilityGrade >= 0 ? (
                                                    <ArrowUpward color="success" fontSize="small" />
                                                ) : (
                                                    <ArrowDownward color="error" fontSize="small" />
                                                ))}
                                            <Typography
                                                color={
                                                    fix.avg - averageAccessibilityGrade > 0
                                                        ? colors.green
                                                        : fix.avg - averageAccessibilityGrade < 0
                                                          ? colors.red
                                                          : colors.darkGray
                                                }>
                                                {new Intl.NumberFormat().format(fix.avg - averageAccessibilityGrade)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    </Paper>
                )}
            </Stack>
        );
    } else if (baseDataError ?? projectScansError) {
        return (
            <Stack height="100%" alignItems="center" justifyContent="center">
                <Typography variant="h5" color="#00000088">
                    No processed data is available for this project
                </Typography>
            </Stack>
        );
    } else {
        return <LoadingScreen />;
    }
}
