import {Box, CircularProgress} from "@mui/material";
import React from "react";

const LoadingScreen: React.FC = () => (
    <Box height="100%" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
    </Box>
);

export default LoadingScreen;
