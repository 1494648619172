import React from "react";
import GeoJSON from "geojson";
import {Box, Button, Chip, Grid, Paper, Stack, Tooltip, Typography} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import {useProject} from "contexts/ProjectContext";
import {geojsonKeys, getGeojsonByType, projectKeys} from "queries/queries";
import {useParams} from "react-router-dom";
import {apiRequest, apiReverseGeocode} from "react_ct/requests";
import type {ImplementProgram, ImplementProgramFeature, ViolationSeverity} from "react_ct/types";
import LoadingScreen from "components/LoadingScreen";
import DWMap from "components/Map/DWMap";
import {FeatureImageContainer} from "../components";
import {colors} from "react_ct/theme";
import {capitalizeFirstLetterOfEachWord, toProperCase} from "helpers/utils";
import {CSVLink} from "react-csv";
import {areCoordinatesEqual} from "components/Map/helpers";

export default function ProgramDashboard(): React.ReactElement {
    const {currentProject, projectManualTags} = useProject();
    const {programId} = useParams();

    const imageContainerRef = React.useRef<HTMLDivElement>(null);

    const {data: programData, isPending: isProgramDataPending} = useQuery({
        queryKey: projectKeys.projectProgram(currentProject?.id, parseInt(programId as string)),
        queryFn: async () => {
            const response = await apiRequest({
                path: `program/${programId ?? ""}`,
                params: {projectId: currentProject?.id},
            });
            const program: ImplementProgram = response.data;
            for (const feature of program.features) {
                const address = await apiReverseGeocode({x: feature.gpsCoordinate[0], y: feature.gpsCoordinate[1]});
                feature.address = address ?? undefined;
            }
            return program;
        },
        enabled: !!programId && !!currentProject?.id,
        refetchOnWindowFocus: false,
    });

    const {data: rawFeaturesData} = useQuery({
        queryKey: [
            ...(programData?.programType === "obstruction" || programData?.programType === "vegetation"
                ? geojsonKeys.obstructionsGeojson(currentProject?.id)
                : geojsonKeys.individualFeaturesGeojson(currentProject?.id)),
            programData?.programType,
            programData?.id,
        ],
        enabled: !!programData,
        queryFn: async ({queryKey}) => await getGeojsonByType(...(queryKey as [number | undefined, string, string])),
    });

    const acceptedProgramFeatures = React.useMemo(
        () => programData?.features.filter(feature => feature.accepted),
        [programData],
    );

    const colorBySeverity = (severity: ViolationSeverity): string =>
        severity === "moderate"
            ? colors.orange
            : severity === "severe"
              ? colors.darkRed
              : severity === "extreme"
                ? colors.black
                : colors.yellow;

    const rawFeatures: Array<GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>> | undefined =
        React.useMemo(() => {
            if (!rawFeaturesData) {
                return undefined;
            }
            return (
                rawFeaturesData.geojsonData as GeoJSON.FeatureCollection<GeoJSON.Point, GeoJSON.GeoJsonProperties>
            ).features.filter(
                feature =>
                    !!acceptedProgramFeatures?.find(
                        f =>
                            f.gpsCoordinate[0] === feature.geometry.coordinates[0] &&
                            f.gpsCoordinate[1] === feature.geometry.coordinates[1],
                    ),
            );
        }, [rawFeaturesData, acceptedProgramFeatures]);

    const csvData = React.useMemo(() => {
        if (!acceptedProgramFeatures) {
            return [];
        }
        const acceptedProgramFeaturesWithImages = acceptedProgramFeatures.map(feature => {
            const newFeature = feature;
            feature.Lat = feature.gpsCoordinate[1];
            feature.Long = feature.gpsCoordinate[0];
            const associatedRawFeature = rawFeatures?.find(f =>
                areCoordinatesEqual(f.geometry.coordinates, feature.gpsCoordinate),
            );
            if (associatedRawFeature?.properties?.fpv_media) {
                newFeature.fpv_media = associatedRawFeature?.properties?.fpv_media;
            }
            if (associatedRawFeature?.properties?.labelled_img_media) {
                newFeature.labelled_img_media = associatedRawFeature?.properties?.labelled_img_media;
            }
            return newFeature;
        });
        return acceptedProgramFeaturesWithImages;
    }, [acceptedProgramFeatures, rawFeatures]);

    const mapData: GeoJSON.FeatureCollection = React.useMemo(() => {
        if (!acceptedProgramFeatures) {
            return {
                type: "FeatureCollection",
                features: [],
            };
        }

        return {
            type: "FeatureCollection",
            features: csvData.map(feature => ({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: feature.gpsCoordinate,
                },
                properties: {
                    ...feature,
                    symbol_id: feature.featureType === "Obstruction" ? `clear_width_${feature.severity}` : undefined,
                    color: feature.severity
                        ? colorBySeverity(feature.severity as ViolationSeverity)
                        : feature.featureType
                          ? projectManualTags?.find(tag => tag.name.toLowerCase() === feature.featureType.toLowerCase())
                                ?.color
                          : "#000",
                },
            })),
        };
    }, [acceptedProgramFeatures, csvData]);

    const countBySeverity: Partial<Record<ViolationSeverity, ImplementProgramFeature[]>> | undefined =
        React.useMemo(() => {
            const isFeatureSeverityNonexistent =
                acceptedProgramFeatures?.find(feature => feature.severity) === undefined;
            if (!acceptedProgramFeatures || isFeatureSeverityNonexistent) {
                return undefined;
            }
            const initial: Partial<Record<ViolationSeverity, ImplementProgramFeature[]>> = {};
            return acceptedProgramFeatures?.reduce((accumulated, current) => {
                if (accumulated[current.severity as ViolationSeverity]) {
                    accumulated[current.severity as ViolationSeverity]?.push(current);
                } else {
                    accumulated[current.severity as ViolationSeverity] = [current];
                }

                return accumulated;
            }, initial);
        }, [acceptedProgramFeatures]);

    const countByTag: Partial<Record<string, ImplementProgramFeature[]>> | undefined = React.useMemo(() => {
        if (!acceptedProgramFeatures || acceptedProgramFeatures?.find(feature => feature.severity) !== undefined) {
            return undefined;
        }
        const initial: Partial<Record<string, ImplementProgramFeature[]>> = {};
        return acceptedProgramFeatures?.reduce((accumulated, current) => {
            if (accumulated[current.featureType]) {
                (accumulated[current.featureType] as ImplementProgramFeature[]).push(current);
            } else {
                accumulated[current.featureType] = [current];
            }

            return accumulated;
        }, initial);
    }, [acceptedProgramFeatures]);

    const [selectedFeature, setSelectedFeature] = React.useState<GeoJSON.Feature | undefined>(mapData.features[0]);

    React.useEffect(() => {
        if (!selectedFeature && mapData?.features) {
            setSelectedFeature(mapData.features[0]);
        }
    }, [mapData]);

    if (isProgramDataPending) {
        return (
            <Box id="page-container" width="100%" height="100vh">
                <Stack width="100%" height="100%" alignItems="center" justifyContent="center">
                    <LoadingScreen />
                </Stack>
            </Box>
        );
    }

    if (programData) {
        return (
            <Box id="page-container" width="100%" height="100vh">
                <Stack height="100%" px={4} py={2}>
                    <Stack
                        direction="row"
                        p={2}
                        alignItems="baseline"
                        justifyContent="space-between"
                        boxSizing="border-box">
                        <Typography variant="h2" component="h2">
                            {programData.name}
                        </Typography>
                        <Chip label={capitalizeFirstLetterOfEachWord(programData.programType.replace("_", " "))} />
                    </Stack>
                    <Grid container spacing={4} sx={{flexGrow: 1, height: "100%"}}>
                        <Grid item xs={8}>
                            <Paper
                                elevation={1}
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    position: "relative",
                                    borderRadius: theme => theme.shape.borderRadius,
                                }}>
                                <DWMap
                                    data={mapData}
                                    dataId="id"
                                    borderRadius
                                    onMarkerClick={(feature: GeoJSON.Feature) => {
                                        setSelectedFeature(feature);
                                    }}
                                    {...{selectedFeature}}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs>
                            <Stack gap={2} height="100%">
                                <Typography variant="h4">
                                    {acceptedProgramFeatures?.length ?? 0}{" "}
                                    {programData.programType === "obstruction" ? "Clear Width Violations" : "Features"}
                                </Typography>
                                {countBySeverity && (
                                    <Paper elevation={1} sx={{p: 2, boxSizing: "border-box", flexGrow: 1}}>
                                        <Stack gap={2}>
                                            <Typography>Severity Breakdown</Typography>
                                            <Stack direction="row" height="50px">
                                                {(Object.keys(countBySeverity) as ViolationSeverity[]).map(
                                                    (severity: ViolationSeverity) => {
                                                        const featuresWithSeverity = countBySeverity[severity];
                                                        const featureCount = featuresWithSeverity?.length ?? 0;
                                                        const percentageOfFeatures =
                                                            Math.round(
                                                                (featureCount * 10) /
                                                                    (acceptedProgramFeatures?.length ?? 1),
                                                            ) * 10;

                                                        const tooltipTitle = `${capitalizeFirstLetterOfEachWord(
                                                            severity,
                                                        )}: ${featureCount}`;

                                                        return (
                                                            <Tooltip key={severity} title={tooltipTitle}>
                                                                <Box
                                                                    p={2}
                                                                    width={`${percentageOfFeatures}%`}
                                                                    height="100%"
                                                                    sx={{
                                                                        boxSizing: "border-box",
                                                                        background: colorBySeverity(severity),
                                                                    }}>
                                                                    {percentageOfFeatures >= 50 && (
                                                                        <Typography color="white">
                                                                            {tooltipTitle}
                                                                        </Typography>
                                                                    )}
                                                                </Box>
                                                            </Tooltip>
                                                        );
                                                    },
                                                )}
                                            </Stack>
                                            <Stack direction="row" gap={2}>
                                                {(Object.keys(countBySeverity) as ViolationSeverity[]).map(
                                                    (severity: ViolationSeverity) => (
                                                        <Tooltip
                                                            key={severity}
                                                            title={`${
                                                                countBySeverity[severity]?.length ?? 0
                                                            } features`}>
                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                gap={1}
                                                                px={1}
                                                                py={0.5}
                                                                sx={{
                                                                    backgroundColor: colorBySeverity(severity) + "44",
                                                                    borderRadius: "50px",
                                                                }}>
                                                                <Box
                                                                    width="15px"
                                                                    height="15px"
                                                                    borderRadius="50%"
                                                                    sx={{
                                                                        backgroundColor:
                                                                            severity === "moderate"
                                                                                ? colors.orange
                                                                                : severity === "severe"
                                                                                  ? colors.darkRed
                                                                                  : severity === "extreme"
                                                                                    ? colors.black
                                                                                    : colors.yellow,
                                                                    }}
                                                                />
                                                                <Typography fontSize="0.8rem">
                                                                    {toProperCase(severity)}
                                                                </Typography>
                                                            </Stack>
                                                        </Tooltip>
                                                    ),
                                                )}
                                            </Stack>
                                        </Stack>
                                    </Paper>
                                )}
                                {countByTag && (
                                    <Paper elevation={1} sx={{p: 2, boxSizing: "border-box"}}>
                                        <Stack gap={2}>
                                            <Typography>Manual Tag Breakdown</Typography>
                                            <Stack direction="row" height="50px">
                                                {Object.keys(countByTag).map((tag: string) => {
                                                    const featuresWithTag = countByTag[tag];
                                                    const numberOfFeatures = featuresWithTag?.length ?? 0;
                                                    const featurePercentage =
                                                        Math.round(
                                                            (numberOfFeatures * 10) /
                                                                (acceptedProgramFeatures?.length ?? 1),
                                                        ) * 10;
                                                    const associatedManualTag = projectManualTags?.find(
                                                        manualTag => manualTag.name.toLowerCase() === tag.toLowerCase(),
                                                    );
                                                    return (
                                                        <Tooltip
                                                            key={tag}
                                                            title={`${countByTag[tag]?.length ?? 0} features`}>
                                                            <Box
                                                                p={2}
                                                                width={`${featurePercentage}%`}
                                                                boxSizing="border-box"
                                                                sx={{
                                                                    backgroundColor:
                                                                        associatedManualTag?.color ?? "#000",
                                                                }}>
                                                                {featurePercentage > 50 && (
                                                                    <Typography color="white">
                                                                        {capitalizeFirstLetterOfEachWord(tag)}:{" "}
                                                                        {numberOfFeatures}
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                        </Tooltip>
                                                    );
                                                })}
                                            </Stack>
                                            <Stack direction="row" gap={2}>
                                                {Object.keys(countByTag).map(tag => {
                                                    const tagColor =
                                                        projectManualTags?.find(
                                                            manualTag =>
                                                                manualTag.name.toLowerCase() === tag.toLowerCase(),
                                                        )?.color ?? "#000";
                                                    return (
                                                        <Tooltip
                                                            key={tag}
                                                            title={`${countByTag[tag]?.length ?? 0} features`}>
                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                gap={1}
                                                                px={1}
                                                                py={0.5}
                                                                sx={{
                                                                    backgroundColor: tagColor + "44",
                                                                    borderRadius: "50px",
                                                                }}>
                                                                <Box
                                                                    width="15px"
                                                                    height="15px"
                                                                    borderRadius="50%"
                                                                    sx={{
                                                                        backgroundColor: tagColor,
                                                                    }}
                                                                />
                                                                <Typography fontSize="0.8rem">
                                                                    {capitalizeFirstLetterOfEachWord(tag)}
                                                                </Typography>
                                                            </Stack>
                                                        </Tooltip>
                                                    );
                                                })}
                                            </Stack>
                                        </Stack>
                                    </Paper>
                                )}
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper elevation={1} sx={{p: 2, boxSizing: "border-box"}}>
                                {selectedFeature?.properties ? (
                                    <Stack gap={1}>
                                        <Typography>
                                            <strong>Type:</strong>{" "}
                                            {capitalizeFirstLetterOfEachWord(selectedFeature?.properties.featureType)}
                                        </Typography>
                                        <Typography>
                                            <strong>Address:</strong> {selectedFeature.properties.address}
                                        </Typography>
                                        <Typography>
                                            <strong>GPS Coordinate:</strong> (
                                            {(selectedFeature.geometry as GeoJSON.Point).coordinates[0]},{" "}
                                            {(selectedFeature.geometry as GeoJSON.Point).coordinates[1]})
                                        </Typography>
                                    </Stack>
                                ) : (
                                    <Typography>No features selected</Typography>
                                )}
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            {selectedFeature && (
                                <Paper
                                    elevation={1}
                                    ref={imageContainerRef}
                                    sx={{
                                        height: "100%",
                                        borderRadius: theme => theme.shape.borderRadius,
                                        overflow: "clip",
                                    }}>
                                    <FeatureImageContainer feature={selectedFeature} {...{imageContainerRef}} />
                                </Paper>
                            )}
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid item xs={4}>
                                    {currentProject && acceptedProgramFeatures && (
                                        <CSVLink
                                            data={csvData}
                                            filename={`${currentProject?.name.replaceAll(" ", "_")}-program${
                                                programData.id
                                            }.csv`}>
                                            <Button variant="contained" sx={{width: "100%"}}>
                                                CSV
                                            </Button>
                                        </CSVLink>
                                    )}
                                </Grid>
                                <Grid item xs={4}>
                                    {mapData && currentProject && (
                                        <Button
                                            variant="contained"
                                            sx={{width: "100%"}}
                                            href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                                JSON.stringify(mapData),
                                            )}`}
                                            download={`${currentProject.name.replaceAll(" ", "_")}-program${
                                                programData.id
                                            }.geojson`}>
                                            GeoJSON
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Stack>
            </Box>
        );
    } else {
        return (
            <Box id="page-container" width="100%" height="100vh">
                <Stack width="100%" height="100%" alignItems="center" justifyContent="center">
                    <Typography>No program data found</Typography>
                </Stack>
            </Box>
        );
    }
}
