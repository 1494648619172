import GeoJSON from "geojson";
import {Close, Panorama, Error as ErrorIcon} from "@mui/icons-material";
import {
    Stack,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Typography,
    TableBody,
    Link,
    IconButton,
    Box,
    Button,
    darken,
} from "@mui/material";
import _, {isEqual} from "lodash";
import React, {useRef} from "react";
import CroppedImage from "./CroppedImage";
import {capitalizeFirstLetterOfEachWord} from "helpers/utils";

interface AddressTableProps {
    selectedFeatures: GeoJSON.Feature[];
    setSelectedFeatures: (feature: GeoJSON.Feature) => void;
    selectedListedFeature: GeoJSON.Feature | undefined;
    setSelectedListedFeature: (feature: GeoJSON.Feature | undefined) => void;
}

function AddressTable({
    selectedFeatures,
    setSelectedFeatures,
    selectedListedFeature,
    setSelectedListedFeature,
}: AddressTableProps): React.ReactElement {
    return (
        <TableContainer
            sx={{
                width: "100%",
                flexGrow: 1,
                overflowY: "auto",
            }}>
            <Table>
                <TableHead
                    sx={{
                        width: "100%",
                        position: "sticky",
                        top: 0,
                        backgroundColor: theme => theme.palette.background.paper,
                        boxShadow: "0px 1px 4px #00000022",
                        zIndex: 99,
                    }}>
                    <TableRow>
                        <TableCell>
                            <Typography fontWeight={700}>Type</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={700}>Address</Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody
                    sx={{
                        width: "100%",
                    }}>
                    {selectedFeatures.map((feature: GeoJSON.Feature, index: number) => {
                        const isSelectedScan =
                            feature.properties?.scan_id === selectedListedFeature?.properties?.scan_id &&
                            feature.properties?.feature_name === selectedListedFeature?.properties?.feature_name;
                        return (
                            <TableRow
                                key={`${Number(feature.properties?.scan_id)}_${String(
                                    feature.properties?.feature_name,
                                )}_${index}`}
                                onClick={() => {
                                    if (isSelectedScan) {
                                        setSelectedListedFeature(undefined);
                                    } else {
                                        setSelectedListedFeature(feature);
                                    }
                                }}
                                sx={{
                                    cursor: "pointer",
                                    width: "100%",
                                    backgroundColor: theme =>
                                        isSelectedScan ? theme.palette.deepWalkBlue.light + "ef" : "transparent",
                                    boxShadow: isSelectedScan ? "0px 1px 4px #00000022" : "none",
                                    transition: "backgroundColor 0.2s ease-in-out",

                                    "&:hover": {
                                        backgroundColor: theme =>
                                            isSelectedScan
                                                ? theme.palette.deepWalkBlue.light + "ef"
                                                : theme.palette.deepWalkBlue.light + "22",
                                    },
                                }}>
                                <TableCell>
                                    <Typography
                                        component={"div"}
                                        sx={{
                                            textAlign: "center",
                                            borderRadius: 50,
                                            backgroundColor: (feature.properties?.color as string) + "44",
                                            color: isSelectedScan
                                                ? "#ffffffbb"
                                                : darken(feature.properties?.color, 0.3),
                                            px: 1,
                                            py: 0.5,
                                            lineHeight: 1,
                                        }}>
                                        {capitalizeFirstLetterOfEachWord(feature.properties?.type)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Link href={feature.properties?.google_maps} target="_blank">
                                        {feature.properties?.address ??
                                            `(${(feature.geometry as GeoJSON.Point).coordinates[0]}, ${
                                                (feature.geometry as GeoJSON.Point).coordinates[1]
                                            })`}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        aria-label="Remove feature"
                                        size="small"
                                        color={isSelectedScan ? "secondary" : "default"}
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation();
                                            const index = selectedFeatures.findIndex(f => _.isEqual(f, feature));
                                            if (isEqual(selectedListedFeature, feature)) {
                                                setSelectedListedFeature(undefined);
                                            }
                                            if (index > -1) {
                                                setSelectedFeatures(feature);
                                            }
                                        }}>
                                        <Close />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

interface ImplementSidebarProps {
    selectedFeatures: GeoJSON.Feature[];
    setSelectedFeatures: (feature: GeoJSON.Feature) => void;
    setOpenModal: (open: boolean) => void;
}

export default function ImplementSidebar({
    selectedFeatures,
    setSelectedFeatures,
    setOpenModal,
}: ImplementSidebarProps): React.ReactElement {
    const [selectedListedFeature, setSelectedListedFeature] = React.useState<GeoJSON.Feature>();

    const imageContainerRef = useRef<HTMLDivElement | null>(null);

    const showImageBox = (feature: GeoJSON.Feature | undefined): React.ReactElement => {
        if (feature?.properties?.fpv_media) {
            return <img src={feature.properties.fpv_media} alt={feature.properties.address} style={{width: "100%"}} />;
        } else if (feature?.properties?.centroid) {
            return (
                <CroppedImage
                    url={feature.properties.labelled_media_link}
                    centroid={feature.properties.centroid}
                    containerRef={imageContainerRef}
                />
            );
        } else if (!feature?.properties?.centroid && feature?.properties?.cropped_media) {
            return <img src={feature.properties.cropped_media} alt={feature.properties.address} />;
        } else if (feature && !feature.properties?.cropped_media && !feature.properties?.centroid) {
            return (
                <>
                    {" "}
                    <ErrorIcon
                        sx={{
                            fontSize: 70,
                        }}
                    />
                    <Typography
                        sx={{
                            color: theme => theme.palette.grey[500],
                        }}>
                        No image found
                    </Typography>
                </>
            );
        }

        return (
            <>
                {" "}
                <Panorama
                    sx={{
                        fontSize: 80,
                    }}
                />
                <Typography
                    sx={{
                        color: theme => theme.palette.grey[500],
                    }}>
                    Click a feature above to view an image of it
                </Typography>
            </>
        );
    };

    return (
        <Stack
            direction="column"
            sx={theme => ({width: "33%", height: "100%", boxShadow: theme.shadows[3], zIndex: 2})}>
            <AddressTable
                selectedFeatures={selectedFeatures}
                selectedListedFeature={selectedListedFeature}
                setSelectedListedFeature={setSelectedListedFeature}
                setSelectedFeatures={setSelectedFeatures}
            />
            <Box
                width="100%"
                sx={{
                    flexBasis: "33vh",
                    minHeight: "33vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                }}>
                <Stack
                    ref={imageContainerRef}
                    width="96%"
                    margin="auto"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                    sx={{
                        overflow: "clip",
                        aspectRatio: 16 / 9,
                        boxShadow: `inset 0px 2px 4px #00000022`,
                        borderRadius: theme => theme.shape.borderRadius,
                        background: theme => theme.palette.grey[200],
                        color: theme => theme.palette.grey[500],
                    }}>
                    {showImageBox(selectedListedFeature)}
                </Stack>
            </Box>
            <Button
                variant="contained"
                disabled={selectedFeatures.length === 0}
                onClick={() => setOpenModal(true)}
                sx={{width: "100%", borderRadius: 0, fontSize: "1.2rem"}}>
                Review Program
            </Button>
        </Stack>
    );
}
