import {colors} from "react_ct/theme";

export type GeoJSONDataType = "geojson" | "indvData" | "obsData" | "communityData";
export type GeoJSONDataUrlType = `${GeoJSONDataType}Url`;
export type DownloadURL = Record<
    GeoJSONDataType,
    {
        fileName: string | undefined;
        url: string | undefined;
    }
>;

export const includedCensusKeys = [
    "B08301_019E",
    "B08301_010E",
    "B22010_006E",
    "B22010_003E",
    "B14002_001E",
    "B01003_001E",
    "B11016_001E",
    "B25044_003E",
    "B25044_010E",
    "AREAWATER",
    "AREALAND",
    "CENTLAT",
    "CENTLON",
    "COUNTY",
    "GEOID",
];

export const getCensusKeyName = (key: string): string => {
    switch (key) {
        case "B01003_001E":
            return "Total Population";
        case "B11016_001E":
            return "Total Households";
        case "B08301_019E":
            return "Population 16+ Walking to Work";
        case "B08301_010E":
            return "Population 16+ Riding Public Transit to Work";
        case "B22010_006E":
            return "Households with a Person with a Disability (A)";
        case "B22010_003E":
            return "Households with a Person with a Disability (B)";
        case "B14002_001E":
            return "Total School Enrollment";
        case "B25044_003E":
            return "Households with No Vehicles (Owner)";
        case "B25044_010E":
            return "Households with No Vehicles (Renter)";
        case "AREAWATER":
            return "Water Area";
        case "AREALAND":
            return "Land Area";
        case "CENTLAT":
            return "Latitude";
        case "CENTLON":
            return "Longitude";
        case "COUNTY":
            return "County";
        default:
            return key;
    }
};

export const scoreColors: Array<{color: string; width?: number}> = [
    {
        color: colors.black,
        width: 100 / 6,
    },
    {
        color: colors.darkRed,
        width: 100 / 6,
    },
    {
        color: colors.red,
        width: 100 / 6,
    },
    {
        color: colors.orange,
        width: 100 / 6,
    },
    {
        color: colors.yellow,
        width: 100 / 6,
    },
    {
        color: colors.green,
    },
];

export const clearWidthProps = {
    layerKey: "clear-width",
    name: "Average Clear Width",
    values: [36, 44, 50, 62],
    unit: '"',
    gradient: [
        {
            color: colors.black,
            width: 15,
        },
        {
            color: colors.red,
            width: 20,
        },
        {
            color: colors.blue,
            width: 20,
        },
        {
            color: colors.green,
            width: 20,
        },
        {
            color: colors.pink,
        },
    ],
};

export const runSlopeProps = {
    layerKey: "run-slope",
    name: "Average Run Slope",
    values: [5, 6, 7, 8],
    unit: "%",
    gradient: [
        {
            color: colors.green,
            width: 15,
        },
        {
            color: colors.yellow,
            width: 20,
        },
        {
            color: colors.orange,
            width: 20,
        },
        {
            color: colors.red,
            width: 20,
        },
        {
            color: colors.black,
        },
    ],
};

export const accessibilityProps = {
    layerKey: "sidewalk",
    name: "Sidewalk Accessibility",
    values: [0, 1, 2, 3, 4, 5],
    gradient: scoreColors,
};

export const crossSlopeProps = {
    layerKey: "cross-slope",
    name: "Cross Slope Score",
    values: [5, 25, 50, 100],
    unit: "%",
    gradient: [
        {
            color: colors.green,
            width: 5,
        },
        {
            color: colors.yellow,
            width: 20,
        },
        {
            color: colors.orange,
            width: 25,
        },
        {
            color: colors.red,
            width: 25,
        },
        {
            color: colors.black,
        },
    ],
};

export const deteriorationProps = {
    layerKey: "deterioration-layer",
    name: "Percent Deteriorated",
    values: [0, 10, 25, 50],
    unit: "%",
    gradient: [
        {
            color: colors.green,
            width: 5,
        },
        {
            color: colors.yellow,
            width: 10,
        },
        {
            color: colors.orange,
            width: 15,
        },
        {
            color: colors.red,
            width: 20,
        },
        {
            color: colors.black,
        },
    ],
};

export const censusDataProps = {
    layerKey: "census",
    name: "Total population",
    values: [800, 1500, 2000],
    gradient: [
        {
            color: "#8defff",
        },
        {
            color: "#13aeff",
        },
        {
            color: "#0042c5",
        },
        {
            color: "#00066d",
        },
    ],
};

export const obstructionDataProps = {
    layerKey: "obstructions",
    name: "Obstructions",
    values: [24, 36, 42, 46, 47],
    unit: "in",
    gradient: [
        {
            color: colors.darkBlue,
            width: 25,
        },
        {
            color: colors.darkRed,
            width: 25,
        },
        {
            color: colors.orange,
            width: 25,
        },
        {
            color: colors.yellow,
            width: 25,
        },
    ],
};

export const crossSlopePanelProps = {
    layerKey: "cross-slope-panel",
    name: "Cross Slope Panel",
    values: [6, 4, 3, 2.5, 0],
    unit: "%",
    gradient: [
        {
            color: colors.darkBlue,
            width: 25,
        },
        {
            color: colors.darkRed,
            width: 25,
        },
        {
            color: colors.orange,
            width: 25,
        },
        {
            color: colors.yellow,
            width: 25,
        },
    ],
};

export const legendItems = [
    censusDataProps,
    deteriorationProps,
    crossSlopeProps,
    clearWidthProps,
    runSlopeProps,
    accessibilityProps,
    obstructionDataProps,
    crossSlopePanelProps,
];
